import { takeLatest, put, all, call } from "redux-saga/effects";
// apis
import {
  getInventoryBasicDetails,
  createManufacturer,
  getAllManufacturers,
  updateManufacturerById,
  createBrand,
  updateBrandById,
  createAssetGroup,
  updateAssetGroupById,
  createVendor,
  updateVendor,
  getVendors,
  createInventoryItem,
  getInventoryItems,
  updateInventoryItem,
  createInventoryAccount,
  getAllInventoryAccounts,
  updateInventoryAccountById,
  getPurchaseOrderFormInputs,
  createPurchaseOrder,
  getPurchaseOrders,
  updatePurchaseOrder,
} from "APIs/inventory/index";
// utils
import { fetch, create, update } from "redux/app/app.utils";
// actions
import {
  fetchBasicInventoryDataSuccess,
  addManufacturerSuccess,
  fetchManufacturersSuccess,
  updateManufacturerSuccess,
  addBrandSuccess,
  updateBrandSuccess,
  addAssetGroupSuccess,
  updateAssetGroupSuccess,
  addVendorSuccess,
  updateVendorSuccess,
  getVendorsSuccess,
  addInventoryItemSuccess,
  fetchInventoryItemsSuccess,
  updateInventoryItemSuccess,
  fetchInventoryAccountsSuccess,
  addInventoryAccountSuccess,
  updateInventoryAccountSuccess,
  fetchPurchaseOrderFormInputsSuccess,
  setInventoryLoading,
  addPurchaseOrderSuccess,
  updatePurchaseOrderSuccess,
  fetchPurchaseOrderSuccess,
} from "./slice";

export function* fetchInventoryBasicDataAsync() {
  yield fetch({
    callName: "fetch inventory basic",
    query: {},
    api: getInventoryBasicDetails,
    *handleRes(details) {
      yield put(fetchBasicInventoryDataSuccess(details));
    },
    showFeedback: true,
    readLength: false,
    targetPath: "result",
    initial: true,
  });
}

export function* fetchManufacturersAsync() {
  yield fetch({
    callName: "fetch manufacturers",
    query: {},
    api: getAllManufacturers,
    *handleRes(items) {
      yield put(fetchManufacturersSuccess(items));
    },
    showFeedback: true,
    targetPath: "result",
  });
}

export function* createManufacturerAsync({ payload }) {
  yield create({
    callName: "create manufacturer",
    inputs: payload,
    api: createManufacturer,
    *handleRes(item) {
      yield put(addManufacturerSuccess(item));
    },
    resName: "result",
  });
}

export function* updateManufacturerAsync({ payload }) {
  yield update({
    callName: "update manufacturer",
    updates: payload,
    api: updateManufacturerById,
    *handleRes(item) {
      yield put(updateManufacturerSuccess(item));
    },
    updatedName: "result",
    feedbackType: "ERROR",
  });
}

export function* createBrandAsync({ payload }) {
  yield create({
    callName: "create brand",
    inputs: payload,
    api: createBrand,
    *handleRes(item) {
      yield put(addBrandSuccess(item));
    },
    resName: "result",
  });
}

export function* updateBrandAsync({ payload }) {
  yield update({
    callName: "update brand",
    updates: payload,
    api: updateBrandById,
    *handleRes(item) {
      yield put(updateBrandSuccess(item));
    },
    updatedName: "result",
    feedbackType: "ERROR",
  });
}

export function* createAssetGroupAsync({ payload }) {
  yield create({
    callName: "create asset group",
    inputs: payload,
    api: createAssetGroup,
    *handleRes(item) {
      yield put(addAssetGroupSuccess(item));
    },
    resName: "result",
  });
}

export function* updateAssetGroupAsync({ payload }) {
  yield update({
    callName: "update asset group",
    updates: payload,
    api: updateAssetGroupById,
    *handleRes(item) {
      yield put(updateAssetGroupSuccess(item));
    },
    updatedName: "result",
    feedbackType: "ERROR",
  });
}

export function* createVendorAsync({ payload }) {
  yield create({
    callName: "create vendor",
    inputs: payload,
    api: createVendor,
    *handleRes(item) {
      yield put(addVendorSuccess(item));
    },
    resName: "data",
  });
}

export function* updateVendorAsync({ payload }) {
  yield update({
    callName: "update vendor",
    updates: payload,
    api: updateVendor,
    *handleRes(item) {
      yield put(updateVendorSuccess(item));
    },
    updatedName: "data",
  });
}

export function* fetchVendorsAsync() {
  yield fetch({
    callName: "fetch vendors",
    query: {},
    api: getVendors,
    *handleRes(items) {
      yield put(getVendorsSuccess(items));
    },
    showFeedback: true,
    targetPath: "data",
  });
}

export function* createInventoryItemAsync({ payload }) {
  yield create({
    callName: "create inventory item",
    inputs: payload,
    api: createInventoryItem,
    *handleRes(item) {
      yield put(addInventoryItemSuccess(item));
    },
    resName: "result",
  });
}

export function* fetchInventoryItemsAsync() {
  yield fetch({
    callName: "fetch inventory items",
    query: {},
    api: getInventoryItems,
    *handleRes(items) {
      yield put(fetchInventoryItemsSuccess(items));
    },
    showFeedback: true,
    targetPath: "result",
  });
}

export function* updateInventoryItemAsync({ payload }) {
  yield update({
    callName: "update inventory item",
    updates: payload,
    api: updateInventoryItem,
    *handleRes(item) {
      yield put(updateInventoryItemSuccess(item));
    },
    updatedName: "result",
  });
}

export function* fetchInventoryAccountsAsync({ payload }) {
  yield fetch({
    callName: "fetch inventory accounts",
    query: payload,
    api: getAllInventoryAccounts,
    *handleRes(accounts) {
      yield put(fetchInventoryAccountsSuccess(accounts));
    },
    showFeedback: true,
    targetPath: "result",
  });
}

export function* createInventoryAccountAsync({ payload }) {
  yield create({
    callName: "create inventory account",
    inputs: payload,
    api: createInventoryAccount,
    *handleRes(account) {
      yield put(addInventoryAccountSuccess(account));
    },
    resName: "result",
  });
}

export function* updateInventoryAccountAsync({ payload }) {
  yield update({
    callName: "update inventory account",
    updates: payload,
    api: updateInventoryAccountById,
    *handleRes(account) {
      yield put(updateInventoryAccountSuccess(account));
    },
    updatedName: "result",
  });
}

export function* fetchPurchaseOrderFormInputsAsync() {
  yield fetch({
    callName: "fetch purchase order form inputs",
    query: {},
    api: getPurchaseOrderFormInputs,
    *handleRes(inputs) {
      yield put(fetchPurchaseOrderFormInputsSuccess(inputs));
    },
    showFeedback: false,
    targetPath: "result",
    *handleLoading(flag = false) {
      yield put(setInventoryLoading(flag));
    },
  });
}

export function* createPurchaseOrderAsync({ payload }) {
  yield create({
    callName: "create purchase order",
    inputs: payload,
    api: createPurchaseOrder,
    *handleRes(order) {
      yield put(addPurchaseOrderSuccess(order));
    },
    resName: "result",
    *handleLoading(flag = false) {
      yield put(setInventoryLoading(flag));
    },
  });
}

export function* fetchPurchaseOrdersAsync({ payload }) {
  yield fetch({
    callName: "fetch purchase orders",
    query: payload,
    api: getPurchaseOrders,
    *handleRes(orders) {
      yield put(fetchPurchaseOrderSuccess(orders));
    },
    showFeedback: true,
    targetPath: "result",
  });
}

export function* updatePurchaseOrderAsync({ payload }) {
  yield update({
    callName: "update purchase order",
    updates: payload,
    api: updatePurchaseOrder,
    *handleRes(order) {
      yield put(updatePurchaseOrderSuccess(order));
    },
    updatedName: "result",
  });
}

export function* onFetchInventoryBasicDataStart() {
  yield takeLatest(
    "inventory/fetchBasicInventoryDataStart",
    fetchInventoryBasicDataAsync
  );
}

export function* onFetchManufacturersStart() {
  yield takeLatest(
    "inventory/fetchManufacturersStart",
    fetchManufacturersAsync
  );
}

export function* onCreateManufacturerStart() {
  yield takeLatest("inventory/addManufacturerStart", createManufacturerAsync);
}

export function* onUpdateManufacturerStart() {
  yield takeLatest(
    "inventory/updateManufacturerStart",
    updateManufacturerAsync
  );
}

export function* onCreateBrandStart() {
  yield takeLatest("inventory/addBrandStart", createBrandAsync);
}

export function* onUpdateBrandStart() {
  yield takeLatest("inventory/updateBrandStart", updateBrandAsync);
}

export function* onCreateAssetGroupStart() {
  yield takeLatest("inventory/addAssetGroupStart", createAssetGroupAsync);
}

export function* onUpdateAssetGroupStart() {
  yield takeLatest("inventory/updateAssetGroupStart", updateAssetGroupAsync);
}

export function* onCreateVendorStart() {
  yield takeLatest("inventory/addVendorStart", createVendorAsync);
}

export function* onUpdateVendorStart() {
  yield takeLatest("inventory/updateVendorStart", updateVendorAsync);
}

export function* onFetchVendorsStart() {
  yield takeLatest("inventory/getVendorsStart", fetchVendorsAsync);
}

export function* onCreateInventoryItemStart() {
  yield takeLatest("inventory/addInventoryItemStart", createInventoryItemAsync);
}

export function* onFetchInventoryItemsStart() {
  yield takeLatest(
    "inventory/fetchInventoryItemsStart",
    fetchInventoryItemsAsync
  );
}

export function* onUpdateInventoryItemStart() {
  yield takeLatest(
    "inventory/updateInventoryItemStart",
    updateInventoryItemAsync
  );
}

export function* onFetchInventoryAccountsStart() {
  yield takeLatest(
    "inventory/fetchInventoryAccountsStart",
    fetchInventoryAccountsAsync
  );
}

export function* onCreateInventoryAccountStart() {
  yield takeLatest(
    "inventory/addInventoryAccountStart",
    createInventoryAccountAsync
  );
}

export function* onUpdateInventoryAccountStart() {
  yield takeLatest(
    "inventory/updateInventoryAccountStart",
    updateInventoryAccountAsync
  );
}

export function* onFetchPurchaseOrderFormInputsStart() {
  yield takeLatest(
    "inventory/fetchPurchaseOrderFormInputsStart",
    fetchPurchaseOrderFormInputsAsync
  );
}

export function* onCreatePurchaseOrderStart() {
  yield takeLatest("inventory/addPurchaseOrderStart", createPurchaseOrderAsync);
}

export function* onFetchPurchaseOrdersStart() {
  yield takeLatest(
    "inventory/fetchPurchaseOrderStart",
    fetchPurchaseOrdersAsync
  );
}

export function* onUpdatePurchaseOrderStart() {
  yield takeLatest(
    "inventory/updatePurchaseOrderStart",
    updatePurchaseOrderAsync
  );
}

export default function* inventorySaga() {
  yield all([
    call(onFetchInventoryBasicDataStart),
    call(onFetchManufacturersStart),
    call(onCreateManufacturerStart),
    call(onUpdateManufacturerStart),
    call(onCreateBrandStart),
    call(onUpdateBrandStart),
    call(onCreateAssetGroupStart),
    call(onUpdateAssetGroupStart),
    call(onCreateVendorStart),
    call(onUpdateVendorStart),
    call(onFetchVendorsStart),
    call(onCreateInventoryItemStart),
    call(onFetchInventoryItemsStart),
    call(onUpdateInventoryItemStart),
    call(onFetchInventoryAccountsStart),
    call(onCreateInventoryAccountStart),
    call(onUpdateInventoryAccountStart),
    call(onFetchPurchaseOrderFormInputsStart),
    call(onCreatePurchaseOrderStart),
    call(onFetchPurchaseOrdersStart),
    call(onUpdatePurchaseOrderStart),
  ]);
}
