import baseAxios from "APIs/baseAxios";

const COMMON = "/inventory/general";

export async function getInventoryBasicDetails() {
  try {
    return (await baseAxios.get(`${COMMON}/basic-details`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Brand APIs
export async function createBrand(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/brands`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllBrands() {
  try {
    return (await baseAxios.get(`${COMMON}/brands`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBrandById(id) {
  try {
    return (await baseAxios.get(`${COMMON}/brands/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateBrandById(body) {
  const { id, ...obj } = body;
  try {
    return (await baseAxios.put(`${COMMON}/brands/${id}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteBrandById(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/brands/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Category APIs
export async function createCategory(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/categories`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllCategories() {
  try {
    return (await baseAxios.get(`${COMMON}/categories`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCategoryById(id) {
  try {
    return (await baseAxios.get(`${COMMON}/categories/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateCategoryById(id, obj) {
  try {
    return (await baseAxios.put(`${COMMON}/categories/${id}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteCategoryById(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/categories/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Manufacturer APIs
export async function createManufacturer(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/manufacturers`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllManufacturers() {
  try {
    return (await baseAxios.get(`${COMMON}/manufacturers`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getManufacturerById(id) {
  try {
    return (await baseAxios.get(`${COMMON}/manufacturers/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateManufacturerById(body) {
  const { id, ...obj } = body;
  try {
    return (await baseAxios.put(`${COMMON}/manufacturers/${id}`, { ...obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteManufacturerById(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/manufacturers/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Unit APIs
export async function createUnit(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/units`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllUnits() {
  try {
    return (await baseAxios.get(`${COMMON}/units`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUnitById(id) {
  try {
    return (await baseAxios.get(`${COMMON}/units/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUnitById(id, obj) {
  try {
    return (await baseAxios.put(`${COMMON}/units/${id}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteUnitById(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/units/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// AssetGroup APIs
export async function createAssetGroup(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/asset-groups`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllAssetGroups() {
  try {
    return (await baseAxios.get(`${COMMON}/asset-groups`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAssetGroupById(id) {
  try {
    return (await baseAxios.get(`${COMMON}/asset-groups/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateAssetGroupById(body) {
  const { id, ...obj } = body;
  try {
    return (await baseAxios.put(`${COMMON}/asset-groups/${id}`, { ...obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteAssetGroupById(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/asset-groups/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Inventory Account APIs
export async function createInventoryAccount(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/inventory-accounts`, { ...obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllInventoryAccounts(params) {
  try {
    return (await baseAxios.get(`${COMMON}/inventory-accounts`, { params }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getInventoryAccountById(id) {
  try {
    return (await baseAxios.get(`${COMMON}/inventory-accounts/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateInventoryAccountById(data) {
  const { id, ...obj } = data;
  try {
    return (
      await baseAxios.put(`${COMMON}/inventory-accounts/${id}`, { ...obj })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteInventoryAccountById(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/inventory-accounts/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Tax Item APIs
export async function createInvTaxItem(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/tax-items`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getInvTaxItems() {
  try {
    return (await baseAxios.get(`${COMMON}/tax-items`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getInvTaxItemById(id) {
  try {
    return (await baseAxios.get(`${COMMON}/tax-items/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateInvTaxItem(id, obj) {
  try {
    return (await baseAxios.put(`${COMMON}/tax-items/${id}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteInvTaxItem(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/tax-items/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

const COMMON_VENDORS = "/inventory/vendors";
// Vendor APIs
export async function createVendor(obj) {
  try {
    return (await baseAxios.post(`${COMMON_VENDORS}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getVendors() {
  try {
    return (await baseAxios.get(`${COMMON_VENDORS}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getVendorById(id) {
  try {
    return (await baseAxios.get(`${COMMON_VENDORS}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateVendor(body) {
  const { id, ...obj } = body;
  try {
    return (await baseAxios.put(`${COMMON_VENDORS}/${id}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteVendor(id) {
  try {
    return (await baseAxios.delete(`${COMMON_VENDORS}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

const COMMON_INV_ITEMS = "/inventory/items";

// Inventory Item APIs
export async function createInventoryItem(obj) {
  try {
    return (await baseAxios.post(`${COMMON_INV_ITEMS}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getInventoryItems() {
  try {
    return (await baseAxios.get(`${COMMON_INV_ITEMS}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getInventoryItemById(id) {
  try {
    return (await baseAxios.get(`${COMMON_INV_ITEMS}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateInventoryItem(obj) {
  const { id, ...rest } = obj;
  try {
    return (await baseAxios.put(`${COMMON_INV_ITEMS}/${id}`, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteInventoryItem(id) {
  try {
    return (await baseAxios.delete(`${COMMON_INV_ITEMS}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

const COMMON_PURCHASE_ORDERS = "/inventory/purchase-orders";

// Purchase Order APIs
export async function createPurchaseOrder(obj) {
  try {
    return (await baseAxios.post(`${COMMON_PURCHASE_ORDERS}`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPurchaseOrderFormInputs() {
  try {
    return (await baseAxios.get(`${COMMON_PURCHASE_ORDERS}/init`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPurchaseOrders(params) {
  try {
    return (await baseAxios.get(`${COMMON_PURCHASE_ORDERS}`, { params })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPurchaseOrderById(id) {
  try {
    return (await baseAxios.get(`${COMMON_PURCHASE_ORDERS}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updatePurchaseOrder(obj) {
  const { id, ...rest } = obj;
  try {
    return (await baseAxios.put(`${COMMON_PURCHASE_ORDERS}/${id}`, { ...rest }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletePurchaseOrder(id) {
  try {
    return (await baseAxios.delete(`${COMMON_PURCHASE_ORDERS}/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
