// eslint-disable-next-line import/prefer-default-export
export class SessionManager {
  constructor(timeout, logoutCallback) {
    this.timeout = timeout;
    this.logoutCallback = logoutCallback;
    this.timer = null;
    this.active = true;
    this.eventListeners = new Map();
  }

  start() {
    if (!this.active) {
      this.active = true;
    }
    this.init();
    this.resetTimer();
  }

  init() {
    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
      "click",
    ];
    this.cleanup();
    events.forEach((event) => {
      const listener = () => this.resetTimer();
      this.eventListeners.set(event, listener);
      document.addEventListener(event, listener);
    });
  }

  resetTimer() {
    if (!this.active) return;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.logout(), this.timeout);
  }

  logout() {
    if (!this.active) return;
    this.active = false;
    this.cleanup();
    this.logoutCallback();
  }

  cleanup() {
    clearTimeout(this.timer);
    this.eventListeners.forEach((listener, event) => {
      document.removeEventListener(event, listener);
    });
    this.eventListeners.clear();
  }

  destroy() {
    this.active = false;
    this.cleanup();
  }
}
