import features from "config/features";
import flatten from "lodash/flatten";

export default {
  "/finance": [
    "Finance_tab",
    "view_camapiagn_collections",
    "view_collections_other_than_campaign",
    ...features.finance.concession_approvals,
    ...features.finance.revertTxn_approvals,
    ...features.finance.updateTxn_approvals,
    ...features.finance.custom_fee_approvals,
  ],
  "/finance/collections": [
    "view_collections_other_than_campaign",
    "view_campaign_collections",
  ],
  "/finance/collections/:zoneCode": ["view_collections_other_than_campaign"],
  "/finance/dues": ["view_due_reports"],
  "/finance/dues/:zoneCode": ["view_due_reports"],
  "/finance/dues/:zoneCode/:branchCode": ["view_due_reports"],
  "/finance/dues/head": ["view_due_reports"],
  "/finance/campaign-collections/camapign": ["view_campaign_collections"],
  "/finance/campaign-collections/camapign/txns": ["view_campaign_collections"],
  "/finance/collections/:zoneCode/:branchCode": [
    "view_collections_other_than_campaign",
  ],
  "/finance/campaign-collections": ["view_campaign_collections"],
  "/finance/collections/head": ["view_collections_other_than_campaign"],
  "/finance/make-payment": ["make_payment"], // Updated to match query parameter paths
  // "/finance/payments": ["make_payment", "reservation_fee_payment"],

  "/admissions": ["admission_tab", "followups", "view-students"],
  "/admissions/branches-strength": ["view_students"],
  "/admissions/branches-strength/class-summary": ["view_students"],
  "/admissions/campaigns": ["view_students"],
  "/admissions/campaigns/zone-campaign-admissions": ["view_students"],
  "/admissions/campaigns/zone-campaign-admissions/batch-campaign-admissions": [
    "view_students",
  ],
  "/admissions/branches-strength/class-summary/students-list": [
    "view_students",
  ],
  "/admissions/campaigns/zone-campaign-admissions/batch-campaign-admissions/students-list":
    ["view_students"],
  "/attendance": [
    "view_att_reports",
    "give_daily_attendance",
    "view_staff_attendance",
  ],
  "/analysis": flatten(Object.values(features.analysis)),
  "/communications": flatten(Object.values(features.communications)),
  "/academics": flatten(Object.values(features.academics)),
  "/questions": flatten(Object.values(features.questions)),
  "/live-classes": flatten(Object.values(features["live-classes"])),
  "/offline-utilities": flatten(Object.values(features?.["offline-utilities"])),
  "/settings": flatten(Object.values(features.settings)),
  // "/visitors": flatten(Object.values(features.visitors)),
};
