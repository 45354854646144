import flatten from "lodash/flatten";
// icon
import PaymentsRoundedIcon from "@mui/icons-material/PaymentsRounded";
// Helpers
import lazy from "helpers/LazyImport";
// body layout
import Container from "layout/simpleContainer";
// features
import features from "config/features";
// modules
export const FeePlanSettings = lazy(() =>
  import("../../views/Finance/FeePlan")
);

export const CreateFeePlan = lazy(() =>
  import("../../views/Finance/FeePlan/CreateFeePlan")
);

export const EditFeePlan = lazy(() =>
  import("../../views/Finance/FeePlan/UpdateFeePlan")
);

export const AssignInterface = lazy(() =>
  import("../../views/Finance/FeePlan/AssignFeePlan")
);

export const FeeCollectionReports = lazy(() =>
  import("../../views/Finance/FeeCollectionReports")
);
export const ConcessionApprovals = lazy(() =>
  import("../../views/Finance/Approvals")
);

export const PaymentPage = lazy(() => import("../../views/Finance/Payment"));
export const FeeStructure = lazy(() =>
  import("../../views/Finance/FeeManager/FeeStructure")
);
// modules

// export const MakePayment = lazy(() =>
//   import("../../views/Finance/MakePayment")
// );

export const MakePayment = lazy(() =>
  import("../../views/Finance/MakePayment")
);

export const Wallet = lazy(() =>
  import("../../views/Finance/MakePayment/feesAndPayment/wallet")
);
export const FeeReports = lazy(() =>
  import("../../views/Finance/FeePlan/Reports")
);
export const DueReports = lazy(() =>
  import("../../views/Finance/FeePlan/Reports/DueReports")
);
export const CollectionReports = lazy(() =>
  import("../../views/Finance/FeePlan/Reports/CollectionReports")
);
export const ReservationFeeMgnt = lazy(() =>
  import("../../views/Finance/FeePlan/ReservatonFee")
);

export const ApplicationNoRanges = lazy(() =>
  import("../../views/Finance/FeePlan/ApplicationNoRanges")
);

export const TransactionRevert = lazy(() =>
  import("../../views/Finance/TransactionRevert")
);
export const TransactionUpdate = lazy(() =>
  import("../../views/Finance/TransactionUpdate")
);
// export const AssignAdditionalFees = lazy(() =>
//   import("../../views/Finance/AssignAdditionalFees")
// );

// export const PaidAndDueReports = lazy(() =>
//   import("../../views/Finance/FeePlan/Reports/DueReports/PaidAndDueReports")
// );

export const TuitionFeeDueReports = lazy(() =>
  import("../../views/Finance/FeePlan/Reports/DueReports/TutionFeeDueReport")
);
export const TransportFeeDueReports = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/DueReports/TransportationDueReport"
  )
);
export const DueReportsBatchWise = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/DueReports/PaidAndDueReportBatchWise"
  )
);

export const FeeCollectionsReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/FeeCollectionsReport"
  )
);

export const CampaignCollectionsReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/CampaignCollectionsReport"
  )
);
export const IncentiveReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/IncentiveReport"
  )
);
export const CollectionsAndExensesReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/CollectionsAndExpenses"
  )
);

export const BranchFeeLedgerReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/BranchFeeLedgerReport"
  )
);

export const ConcessionReports = lazy(() =>
  import("../../views/Finance/FeePlan/Reports/ConcessionReports")
);
export const BranchWiseSummaryReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/BranchWiseSummaryReport"
  )
);
export const MiscColectionReports = lazy(() =>
  import("../../views/Finance/FeePlan/Reports/MiscFeeReprts")
);
export const CusotmFeeApprovals = lazy(() =>
  import("../../views/Finance/CustomFeeApprovals")
);

export const ConcessionApprovalHistory = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/ConcessionReports/ConcessionHistory"
  )
);
export const DaySheetWithConcession = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/DaySheetWithConcession"
  )
);
export const GSTCollectionReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/GSTCollectionReport"
  )
);
export const InstallmentWiseReport = lazy(() =>
  import("../../views/Finance/FeePlan/Reports/DueReports/InstallmentWiseReport")
);
export const TransportRoutes = lazy(() =>
  import("../../views/Finance/FeePlan/TransportRoutes")
);
export const AllowanceLedger = lazy(() =>
  import("../../views/Finance/AllowanceLedger")
);
export const MiscFees = lazy(() => import("../../views/Finance/MiscFees"));
export const FeeManager = lazy(() => import("../../views/Finance/FeeManager"));
export const CollectionAccountManager = lazy(() =>
  import("../../views/Finance/CollectionAccounts")
);

export const ViewCollectionAccount = lazy(() =>
  import("../../views/Finance/CollectionAccounts/ViewCollectionAccount")
);
export const SummaryReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/ZoneWiseSummaryReport"
  )
);

const MiscFeeApprovals = lazy(() =>
  import("../../views/Finance/MiscFeeApprovals")
);

export const PaidAndDueReportV2 = lazy(() =>
  import("../../views/Finance/FeePlan/Reports/DueReports/PaidAndDueReportV2")
);
export const TransactionReport = lazy(() =>
  import(
    "../../views/Finance/FeePlan/Reports/CollectionReports/TransactionReport"
  )
);

export default {
  component: <Container />,
  title: "Finance",
  basePath: "app",
  path: "finance",
  icon: <PaymentsRoundedIcon />,
  features: [
    ...flatten(Object.values(features.finance)),
    "reservation_fee_payment",
  ],
  children: [
    {
      component: <TransportRoutes />,
      title: "Create Fee Plan",
      basePath: "finance",
      path: "create-transport-routes",
      features: ["fee_structures_write", "fee_structures_read"],
      noUiLink: true,
    },
    {
      component: <Container />,
      title: "Collection Accounts",
      basePath: "finance",
      path: "collection-accounts",
      features: ["collection_accounts_read", "collection_accounts_write"],
      index: <CollectionAccountManager />,
      children: [
        {
          component: <ViewCollectionAccount />,
          title: "View Collection Account",
          basePath: "collection-accounts",
          path: ":collectionAccountRef",
          features: ["collection_accounts_read"],
        },
      ],
    },
    {
      component: <FeeManager />,
      title: "Fee Management",
      basePath: "finance",
      path: "fee-manager",
      features: features.finance.fee_management,
    },
    {
      component: <FeeStructure />,
      title: "Fee Structure",
      basePath: "finance",
      path: "fee-strucutre",
      features: features.finance.fee_reports,
      noUiLink: true,
    },
    {
      component: <MiscFees />,
      title: "Miscellaneous Fees Management",
      basePath: "finance",
      path: "misc-fees",
      features: [
        "misc_fee",
        "misc_fee_add",
        "misc_fee_edit",
        "misc_feeTypes_view",
        "misc_fee_assign",
        "misc_fee_unassign",
        "transport_fee_assign",
      ],
    },
    {
      component: <Container />,
      index: <FeeReports />,
      title: "Fee Reports",
      basePath: "finance",
      path: "fee-reports",
      features: [
        ...features.finance.fee_reports,
        ...features.finance.collection_reports,
      ],
      children: [
        {
          component: <DueReports />,
          title: "Due Reports",
          basePath: "fee-reports",
          path: "due-reports",
          features: features.finance.fee_reports,
        },
        {
          component: <CollectionReports />,
          title: "Collection Reports",
          basePath: "fee-reports",
          path: "collection-reports",
          features: features.finance.fee_reports,
        },
        {
          component: <GSTCollectionReport />,
          title: " GST Collection Reports",
          basePath: "fee-reports",
          path: "GST-collection-report",
          features: ["view_GST_report"],
        },
        {
          component: <TransactionReport />,
          title: "Transaction Report",
          basePath: "fee-reports",
          path: "txn-summary",
          features: ["view_collections_other_than_campaign"],
        },
        // {
        //   component: <PaidAndDueReports />,
        //   title: "Total Paid And Due Reports",
        //   basePath: "fee-reports",
        //   path: "paid-and-due-reports",
        //   features: features.finance.fee_reports,
        // },
        {
          component: <PaidAndDueReportV2 />,
          title: "Total Paid And Due Reports",
          basePath: "fee-reports",
          path: "paid-and-due-reports",
          features: features.finance.fee_reports,
        },
        {
          component: <InstallmentWiseReport />,
          title: "Installment wise Reports",
          basePath: "fee-reports",
          path: "installment-wise-report",
          features: features.finance.fee_reports,
        },
        {
          component: <BranchWiseSummaryReport />,
          title: "Branch Summary Reports",
          basePath: "fee-reports",
          path: "branch-summary-report",
          features: ["view_branch_summary_report"],
        },

        {
          component: <DueReportsBatchWise />,
          title: "Batch Wise Due Reports",
          basePath: "fee-reports",
          path: "batch-wise-due-reports",
          features: features.finance.fee_reports,
        },

        {
          component: <TransportFeeDueReports />,
          title: "Batch Wise Due Reports",
          basePath: "fee-reports",
          path: "transport-fee-due-reports",
          features: features.finance.fee_reports,
        },

        {
          component: <TuitionFeeDueReports />,
          title: "Batch Wise Due Reports",
          basePath: "fee-reports",
          path: "tution-fee-due-reports",
          features: features.finance.fee_reports,
        },

        {
          component: <FeeCollectionsReport />,
          title: "Fee Collections Report",
          basePath: "fee-reports",
          path: "fee-collections-report",
          features: ["view_collections_other_than_campaign"],
        },
        {
          component: <CampaignCollectionsReport />,
          title: "Campaign Collections Report",
          basePath: "fee-reports",
          path: "campaign-collections-report",
          features: ["view_campaign_collections"],
        },
        {
          component: <IncentiveReport />,
          title: "Incentive Report",
          basePath: "fee-reports",
          path: "incentive-report",
          features: ["incentive_collection_report"],
        },
        {
          component: <CollectionsAndExensesReport />,
          title: "Branch Collections And Expenses",
          basePath: "fee-reports",
          path: "collections-and-expenses-report",
          features: ["view_collections_and_expenses"],
        },
        {
          component: <MiscColectionReports />,
          title: "Collections Report",
          basePath: "fee-reports",
          path: "misc-collections-report",
          features: ["view_misc_collections"],
        },

        {
          component: <BranchFeeLedgerReport />,
          title: "Branch Fee Ledger Report",
          basePath: "fee-reports",
          path: "branch-fee-ledger-report",
          features: [
            "view_branch_fee_ledger_report",
            "view_campaign_fee_ledger_report",
          ],
        },
        {
          component: <ConcessionReports />,
          title: "Concession Reports",
          basePath: "fee-reports",
          path: "concession-reports",
          features: ["view_concession_report"],
        },
        {
          component: <ConcessionApprovalHistory />,
          title: "Concession Approval History",
          basePath: "fee-reports",
          path: "concession-approval-history",
          features: ["view_concession_report"],
        },
        {
          component: <DaySheetWithConcession />,
          title: "Day Sheet With Concession",
          basePath: "fee-reports",
          path: "day-sheet-with-concession",
          features: ["view_collections_other_than_campaign"],
        },
        {
          component: <SummaryReport />,
          title: "Zone Wise Summary Report",
          basePath: "fee-reports",
          path: "zone-wise-summary-report",
          features: ["zone_wise_Summary_Report"],
        },
      ],
    },

    {
      component: <MakePayment />,
      title: "Payments",
      basePath: "finance",
      path: "make-payment",
      features: [
        "view_student_txn_history",
        "make_payment",
        "branch_transfer_history",
        "caution_deposit_withdraw",
        "student_ledger",
        "Proceed_to_pay_button_access",
        "view_raise_concession_requests",
        "student_allowance",
        "student_allowance_credit",
        "student_allowance_debit",
        "student_books_from_inventory",
        "misc_fee_payment",
        "additional_concession_access",
        "custom_fee_adjustment",
      ],
    },
    {
      component: <PaymentPage />,
      title: "Payment",
      basePath: "finance",
      path: "payment",
      features: ["make_payment", "reservation_fee_payment"],
      noUiLink: true,
    },
    {
      component: <Wallet />,
      title: "pocket-money-wallet",
      basePath: "finance",
      path: "wallet",
      features: ["make_payment"],
      noUiLink: true,
    },
    {
      component: <ConcessionApprovals />,
      title: "Concession Approvals",
      basePath: "finance",
      path: "concession-approvals",
      features: features.finance.concession_approvals,
    },
    {
      component: <MiscFeeApprovals />,
      title: "Misc Fee Approvals",
      basePath: "finance",
      path: "misc-fee-approvals",
      features: features.finance.misc_fee_approvals,
    },
    {
      component: <TransactionRevert />,
      title: "Tnx. Revert Approvals",
      basePath: "finance",
      path: "revertTxn-approvals",
      features: features.finance.revertTxn_approvals,
    },
    {
      component: <TransactionUpdate />,
      title: "Tnx. Upadate Approvals",
      basePath: "finance",
      path: "updateTxn-approvals",
      // noUiLink: true,
      features: features.finance.updateTxn_approvals,
    },

    {
      component: <CusotmFeeApprovals />,
      title: "Custom Fee Approvals",
      basePath: "finance",
      path: "custom-fee-approvals",
      features: features.finance.custom_fee_approvals,
    },
    // {
    //   component: <AssignAdditionalFees />,
    //   title: "Assign Fee Amounts",
    //   basePath: "finance",
    //   path: "assign-fee-amounts",
    //   features: [],
    // },
    {
      component: <AllowanceLedger />,
      title: "Allowance Ledger",
      basePath: "finance",
      path: "allowance-ledger",
      features: [
        "allowance_ledger",
        "allowance_ledger_download",
        "allowance_credit",
        "allowance_debit",
      ],
    },
  ],
};
