import { useCallback } from "react";
import { load } from "@cashfreepayments/cashfree-js";

const useCashfree = (pgs) => {
  let cashfree;

  const initializeSDK = async () => {
    cashfree = await load({
      mode: "production", // "sandbox" or "production"
    });
  };

  if (pgs && pgs.length > 0 && pgs.includes("cashfree")) {
    initializeSDK();
  }

  const initiateCashfreePayment = useCallback(
    async (paymentSessionId) => {
      const checkoutOptions = {
        paymentSessionId,
        redirectTarget: "_self",
      };
      cashfree.checkout(checkoutOptions);
    },
    [cashfree]
  );

  return initiateCashfreePayment;
};

export default useCashfree;
