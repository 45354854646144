import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import store from "store";
// myComponents
import SoftBox from "myComponents/SoftBox";
// ui context
import { useSoftUIController } from "context";
// anime
import JSONAnimation from "animations";
// selectors
import { selectBranchesMapByBranchCode } from "redux/organization/branches/branches.selectors";
// hooks
import useAsyncEvent from "layout/primeLayout/useAsyncEvents";
import useRoutesConfig from "hooks/useRoutesConfig";
// selectors
import {
  selectIsAppInitialized,
  selectLayoutType,
  selectLoginUser,
} from "redux/app/app.selectors";
// actions
import { closeAppDialog } from "redux/app/app.slice";
// SUB
import { subscribe } from "helpers/pubsub/utils";
import MakeApiCall from "helpers/pubsub/events/makeApiCall";
// helpers
import MobileDynamicHeader from "components/MobileDynamicHeader";
import AppDialogs from "helpers/AppDialogs";
// components
import Topbar from "./Topbar";
import Sidenav from "./Sidenav";
import SettingsBar from "./SettingsBar";
import logoMap from "./logoURLMap";
import Offline from "./Offline";
import { Main } from "./styles";
import Loading from "./Loading";
import BottomNavigationBar from "./BottomNavigation";

const PrimeLayout = () => {
  const dispatch = useDispatch();
  const { routes, screen } = useRoutesConfig();
  const [controller] = useSoftUIController();
  const { sidenavColor, miniSidenav } = controller;
  const [offLine, setIfOffLine] = useState(false);
  const [callHandler] = useAsyncEvent();
  // selectors
  const branchesMap = useSelector(selectBranchesMapByBranchCode);
  const isAppInitialized = useSelector(selectIsAppInitialized);
  const layoutType = useSelector(selectLayoutType);
  const { _id = "" } = store.get("user") || {};
  const loginUser = useSelector(selectLoginUser);
  const { userId, designation } = loginUser || { userId: "" };

  const noDefaultLayout = ["timetable/create", "manage-roles&privileges"];

  const mainLayout = (() => {
    const t1 = layoutType === "main";
    const t3 = noDefaultLayout.every(
      (path) => !window.location.pathname.includes(path)
    );
    return t1 && t3;
  })();

  const instituteId = store.get("instituteId");

  useEffect(() => {
    dispatch(closeAppDialog());
  }, []);

  useEffect(() => {
    const handle = subscribe(MakeApiCall, (args) => {
      callHandler(args);
    });

    return function cleanup() {
      handle.unsubscribe();
    };
  });

  useEffect(() => {
    setIfOffLine(!window.navigator.onLine);
  }, [window.navigator.onLine]);

  useEffect(() => {
    if (
      navigator.userAgent.includes("Android") &&
      window.Android &&
      userId &&
      window.Android.postFCMToken
    ) {
      window.Android?.postFCMToken(_id);
    }
  }, [userId]);

  if (!isAppInitialized && offLine) return <Offline fullScreen />;

  if (!isAppInitialized)
    return (
      <SoftBox
        width="100vw"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <JSONAnimation type="loading" />
      </SoftBox>
    );

  const getValues = () => {
    if (store.get("instituteId") === "65772522ee64cfa8dc9851f2") {
      if (Object.values(branchesMap)?.length > 1) {
        return logoMap[instituteId]?.url;
      }
      if (Object.values(branchesMap)?.[0]?.branchName?.includes("Prime")) {
        return logoMap[`${instituteId}_prime`].url;
      }
      if (Object.values(branchesMap)?.[0]?.branchName?.includes("Digi")) {
        return logoMap[`${instituteId}_digi`].url;
      }
    }

    if (
      Object.values(branchesMap)?.[0]?.images?.branchLogo &&
      designation !== "super_admin" &&
      (store.get("instituteId") === "5e7370083d8520a40c4ceaaa" ||
        store.get("instituteId") === "5fd25b12ed14200ff702f738" ||
        store.get("instituteId") === "66c430d428cbcc7c97d22a27")
    ) {
      return Object.values(branchesMap)?.[0]?.images?.branchLogo;
    }

    return logoMap[instituteId]?.url;
  };

  if (screen === "MOBILE") {
    return (
      <>
        <Main open={false} mobile>
          {offLine ? (
            <Offline />
          ) : (
            <MobileDynamicHeader>
              <Outlet />
            </MobileDynamicHeader>
          )}
        </Main>
        <BottomNavigationBar />
        <Loading />
      </>
    );
  }

  return (
    <>
      {mainLayout ? (
        <>
          <Topbar light />
          <Sidenav
            color={sidenavColor}
            brand={getValues()}
            InsTitle={logoMap[instituteId]?.title}
            routes={routes}
            branchId={logoMap[instituteId]?.branchId}
            subTitle={logoMap[instituteId]?.subTitle}
          />
          <Main open={miniSidenav}>
            <SoftBox height="40px" />
            {offLine ? <Offline /> : <Outlet />}
          </Main>
        </>
      ) : offLine ? (
        <Offline />
      ) : (
        <Outlet />
      )}
      <AppDialogs />
      <SettingsBar />
      <Loading />
    </>
  );
};

export default PrimeLayout;
