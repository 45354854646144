import { useEffect } from "react";
import store from "store";
import startCase from "lodash/startCase";
import { useSelector } from "react-redux";
import { Field, useFormikContext } from "formik";
// mui
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { DatePicker } from "@mui/x-date-pickers";
import { selectBranchesList } from "redux/organization/branches/branches.selectors";
// components
import FormFieldError from "components/FormFieldError";
// types
import { FIELD_TYPES } from "config/globalConsts";
// hooks
import useIsMount from "hooks/useIsMount";
// soft ui
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
// utils
import { formInterDependents } from "utils/Fields/fieldsMap";
import isDate from "utils/isDate";
// styles
import useStyles from "components/FormikFieldArrayComponents/styles";
// formik utils
import {
  FieldDropdown,
  InputTextFiled,
  FieldSwitch,
  TimePickerField,
  FieldCheckBox,
  FieldMultiDropdown,
  FieldRadioButtons,
} from "../FormikFieldArrayComponents";

const RequiredLabel = ({ required, label }) => (
  <Stack direction="row">
    <SoftTypography variant="caption" fontWeight="bold">
      {required
        ? startCase(label)
        : label === "fullName"
        ? store.get("instituteId") === "645b4332b98a5dc4ce54256d"
          ? "SurName"
          : "First Name"
        : startCase(label)}
    </SoftTypography>
    <SoftTypography variant="caption" color="error" fontWeight="bold">
      {required ? "*" : null}
    </SoftTypography>
  </Stack>
);
const FormField = ({
  required,
  details,
  options,
  textType,
  helperText,
  rows,
  multiline,
  readOnly = false,
  disabled,
  textFieldControl,
  placeholder,
  validationName,
  size,
  myLabel,
  min,
  max,
  heading,
  topSpace = true,
  virtualList = false,
  getLabelInVirtualList,
  applicationNoExists,
  getComponent,
  designationType = false,
  simpleOptions,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const branchList = useSelector(selectBranchesList);

  const {
    type,
    name,
    idName,
    phase,
    key,
    dependsOn,
    getList = () => [],
    ...rest
  } = details;
  const isSRApplication =
    store.get("instituteId") === "65772522ee64cfa8dc9851f2" &&
    ["application", "admission"].includes(phase);

  const haveDependentFields = formInterDependents[name];
  const isMount = useIsMount(formInterDependents[name]);
  const returnNUll = ["Campus walk-in", "Others", "Website"].includes(
    values?.source?.label
  );

  useEffect(() => {
    if (values?.[name] && haveDependentFields && !isMount) {
      formInterDependents?.[name].forEach((d) => setFieldValue(d, null));
    }
  }, [values?.[name]]);

  const filters = (dependsOn || details?.filters)?.reduce((a, c) => {
    a[c] = values[c];
    return a;
  }, {});

  useEffect(() => {
    if (
      name?.toLowerCase().includes("date") &&
      !name?.toLowerCase().includes("birth") &&
      !name?.toLowerCase().includes("due") &&
      !values[name]
    ) {
      setFieldValue(name, new Date());
    }
    if (
      name?.toLowerCase().includes("branch") &&
      !values[name] &&
      branchList.length === 1
    ) {
      setFieldValue(name, branchList[0]);
    }
    if (name?.toLowerCase().includes("designation") && !designationType) {
      setFieldValue(name, options?.[0]);
    }
  }, []);
  // useEffect(() => {
  //   if (
  //     values?.branch &&
  //     typeof values.branch === "object" &&
  //     values.branch?.branchType
  //   ) {
  //     if (values?.branch?.branchType[0]?.includes("Day")) {
  //       setFieldValue("admissionType", {
  //         label: "Day scholar",
  //         value: "Day scholars",
  //       });
  //     } else if (values?.branch?.branchType[0]?.includes("Residential")) {
  //       setFieldValue("admissionType", {
  //         label: "Residential",
  //         value: "Residential",
  //       });
  //     }
  //   }
  // }, [values?.branch]);

  useEffect(() => {
    if (name.includes("applicationStatus") && !applicationNoExists) {
      setFieldValue(name, options?.[0]);
    }
    // else {
    //   setFieldValue(name, options?.[1]);
    // }
  }, [name, applicationNoExists]);

  const l = name.replace(/([A-Z])/g, " $1");

  const labelToUpperCase = l.charAt(0).toUpperCase() + l.slice(1);
  const labelToUpperCase1 = labelToUpperCase.split(".").pop(""); // for field array purpose
  const label =
    myLabel || labelToUpperCase1[0].toUpperCase() + labelToUpperCase1.slice(1);

  const textControl = textFieldControl ? textFieldControl(values) : {};
  if (!name || !type) return null;

  if (
    ["designation"].includes(name) &&
    !designationType &&
    returnNUll &&
    isSRApplication &&
    ["application", "admission"].includes(phase)
  ) {
    return null;
  }
  if (type === FIELD_TYPES.TEXT_FIELD) {
    // console.log(name, textControl?.helperText);
    return (
      <Grid item sm={size || 2} xs={12} sx={{ maxWidth: 400 }}>
        <Field
          name={name || ""}
          component={InputTextFiled}
          label={label}
          required={required}
          textType={textType}
          helperText={helperText}
          rows={rows}
          multiline={multiline}
          readOnly={readOnly}
          placeholder={placeholder}
          disabled={!!disabled}
          validationName={validationName}
          min={min}
          max={max}
        />

        {textControl?.error ? (
          <FormFieldError error={textControl?.helperText} />
        ) : null}
      </Grid>
    );
  }
  if (type === FIELD_TYPES.DATE) {
    return (
      <Grid item sm={size || 2} xs={12}>
        <SoftBox>
          {label || name ? (
            <SoftBox ml={0.5} mb={0.5}>
              <RequiredLabel name={name} label={label} required={required} />
            </SoftBox>
          ) : null}
          <Field name={name}>
            {({ field, form }) => {
              const val =
                field.value && isDate(new Date(field.value))
                  ? new Date(field.value)
                  : null;
              return (
                <DatePicker
                  {...field}
                  value={val}
                  format="dd-MM-yyyy"
                  maxDate={max}
                  minDate={min}
                  disabled={!!disabled}
                  placeholder={label}
                  onChange={(newValue) =>
                    form.setFieldValue(field.name, newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      className={useStyles().textFieldStyles}
                    />
                  )}
                />
              );
            }}
          </Field>
        </SoftBox>
      </Grid>
    );
  }

  if (
    type === FIELD_TYPES.DROPDOWN ||
    type === FIELD_TYPES.DROPDOWN_SINGLE ||
    type === FIELD_TYPES.DROPDOWN_FIELD
  ) {
    return (
      <Grid item sm={size || 2} xs={12}>
        {/* {getComponent && getComponent()} */}
        <Field
          name={name}
          label={label}
          // options={options || getList({ ...values, ...filters }) || []}
          options={options || getList({ ...values, ...filters }) || []}
          component={FieldDropdown}
          required={required}
          optionLabelPropName={details.key}
          optionValuePropName={details.idName}
          readOnly={readOnly}
          helperText={helperText}
          virtualList={virtualList}
          getLabelInVirtualList={getLabelInVirtualList}
          returnNUll={returnNUll && isSRApplication}
          getComponent={getComponent}
          disabled={disabled}
          {...rest}
          simpleOptions={simpleOptions}
        />
      </Grid>
    );
  }
  if (type === FIELD_TYPES.TIME) {
    return (
      <Grid item xs={2}>
        <TimePickerField
          name={name || ""}
          label={label}
          required={required}
          readOnly={readOnly}
        />
      </Grid>
    );
  }

  if (type === FIELD_TYPES.CHECKBOX) {
    return (
      <Grid item xs={2} sm={size || 2} md={size || 2}>
        <Field
          name={name}
          component={FieldCheckBox}
          label={label}
          readOnly={readOnly}
          heading={heading}
          topSpace={topSpace}
        />
      </Grid>
    );
  }
  if (type === FIELD_TYPES.RADIO) {
    return (
      <Grid item xs={size || 2} sm={size || 2} md={size || 2}>
        <Field
          name={name}
          component={FieldRadioButtons}
          label={label}
          readOnly={readOnly}
          heading={heading}
          topSpace={topSpace}
          options={options}
        />
      </Grid>
    );
  }
  if (type === FIELD_TYPES.SWITCH) {
    return (
      <Grid item xs={2} sm={2} md={2}>
        <Field
          name={name}
          component={FieldSwitch}
          label={label}
          readOnly={readOnly}
        />
      </Grid>
    );
  }

  if (
    type === FIELD_TYPES.DROPDOWN_MULTI ||
    type === FIELD_TYPES.DROPDOWN_FIELD
  ) {
    return (
      <Grid item sm={size || 2} xs={12}>
        <Field
          name={name}
          label={details.label2}
          required={required}
          options={options || getList(values) || []}
          component={FieldMultiDropdown}
          optionLabelPropName={key}
          optionValuePropName={idName}
          helperText={helperText}
          {...rest}
        />
      </Grid>
    );
  }

  // if (type === "file") {
  //   return (
  //     <Field
  //       name={name}
  //       component={File}
  //       formik={formik}
  //       label={details.label2}
  //     />
  //   );
  // }

  return null;
};

export default FormField;
