import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// mui
import { ThemeProvider } from "@mui/material";
// notistack
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import store from "store";
// theme
import theme from "assets/theme";
// myComponents
import SoftSnackBar from "myComponents/SoftSnackBar";
// routes
import { LicenseInfo } from "@mui/x-license-pro";
import { redirectToMpin } from "APIs/baseAxios";
import ErrorFallback from "views/ErrorFallback";
import { SessionManager } from "./utils/SessionManager";
import AppRoutes from "./routes/Routes";
// redux store
import storeRedux, { persistor } from "./redux/store";

const EXCLUDED_PATHS = ["/sign-in", "/mpin", "/forgot-password"];
const INACTIVITY_TIMEOUT = 900000; // 15 minutes in milliseconds

const App = () => {
  const user = store.get("user");
  const location = useLocation();
  const sessionManagerRef = useRef(null);

  useEffect(() => {
    const isExcludedPath = EXCLUDED_PATHS.some((path) =>
      location.pathname.startsWith(path)
    );

    if (!sessionManagerRef.current && user && !isExcludedPath) {
      sessionManagerRef.current = new SessionManager(INACTIVITY_TIMEOUT, () => {
        redirectToMpin();
        console.log("User session expired due to inactivity");
      });
    }

    if (sessionManagerRef.current) {
      if (user && !isExcludedPath) {
        sessionManagerRef.current.start();
      } else {
        sessionManagerRef.current.destroy();
        sessionManagerRef.current = null;
      }
    }

    return () => {
      if (sessionManagerRef.current) {
        sessionManagerRef.current.destroy();
      }
    };
  }, [user, location.pathname]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      LicenseInfo.setLicenseKey(
        "1258635abc81da59f5d9ac4cf79f4811Tz04MTAwMyxFPTE3MzQ4NTE3MjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
      );
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Provider store={storeRedux}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <PersistGate persistor={persistor}>
              <SnackbarProvider
                Components={{
                  success: SoftSnackBar,
                  error: SoftSnackBar,
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                maxSnack={5}
              >
                <AppRoutes />
              </SnackbarProvider>
            </PersistGate>
          </LocalizationProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
