import { takeLatest, put, call, all } from "redux-saga/effects";

import { fetchMulti } from "redux/app/app.utils";
import { setBusesList, setRoutesList, setTranInit } from "./transport.slice";
import { getBuses, getRoutes } from "../../APIs/transports";

export function* fetchBasicTransportDetailsAsync({ payload }) {
  const { page, status } = payload;
  console.log(" im fired");

  yield fetchMulti({
    callName: "fetch transport buses",
    des: "buses and routes information",
    showFeedback: false,
    query1: {
      query: {
        page,
        status,
      },
      api: getBuses,
      targetPath: "",
    },
    query2: {
      query: {},
      api: getRoutes,
      targetPath: "routes",
    },
    *handleRes(r) {
      const haveBuses = Boolean(r.query1);
      const haveRoutes = Boolean(r.query2);
      console.log(" have buses", r.query1);
      console.log("have routes", r.query2);
      if (!haveBuses || !haveRoutes) {
        throw new Error("Failed to fetch transport data");
      }
      yield put(setBusesList(r.query1));
      yield put(setRoutesList(r.query2));
      yield put(setTranInit(true));
      return { success: true };
    },
  });
}
export function* onfetchBasicTransportDetailsStart() {
  yield takeLatest(
    "TRANSPORT/fetchBasicTransportDetails",
    fetchBasicTransportDetailsAsync
  );
}

export default function* transportSaga() {
  yield all([call(onfetchBasicTransportDetailsStart)]);
}
