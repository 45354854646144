const ENV = process.env.NODE_ENV || "development";

const host = `http://${window.location.hostname}:` || "http://localhost:";
const adminServer = "https://admin.onesaz.com";

export function getAPIUrlConstants(server) {
  const obj = {
    ERP: {
      production: {
        BASE_URL: `${window.location.origin}/api`,
        // BASE_URL: `${host}7077`,
      },
      development: {
        BASE_URL: `${host}7077`,
        // BASE_URL: "192.168.0.18:4040",
      },
    },
    ADMIN: {
      production: {
        BASE_URL: `${window.location.origin}/api`,
        EXAM_SERVER_URL: "http://13.235.2.132/api",
      },
      development: {
        BASE_URL: `${host}7077`,
        EXAM_SERVER_URL: `${host}8912`,
      },
    },
  };
  return obj[server || "ERP"][ENV];
}

// for fetching micro frontend
export const bundleUrl = (() => {
  const obj = {
    development: `${host}5000`,
    production: adminServer,
  };

  return obj[ENV];
})();

export const adminURL = (() => {
  const obj = {
    development: `${host}3000`,
    production: adminServer,
  };

  return obj[ENV];
})();

export default getAPIUrlConstants();
