import axios from "axios";
import store from "store";
import constants, { getAPIUrlConstants } from "./constants";
import { logout } from "./UserServices";

const baseAxios = axios.create({
  baseURL: constants.BASE_URL,
  headers: { "Content-type": "application/json" },
  withCredentials: true,
});
// get refreshed token
// function refreshToken() {
//   return baseAxios.get("/employee/refresh-token");
// }

function download(data, name, isBase64 = false) {
  let url;
  if (isBase64) {
    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i += 1) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    const blob = new Blob([bytes]);
    url = window.URL.createObjectURL(blob);
  } else {
    url = window.URL.createObjectURL(new Blob([data]));
  }
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// redirect user to sign in on session expire
export function redirectUser() {
  const userFromStore = store.get("user");
  const user = { ...userFromStore };
  store.set("sessionExpired", true);
  store.remove("user");
  logout(user.userId);
  store.remove("academicYear");
  store.remove("accessToken");
  store.remove("instituteId");
  store.remove("privilegeConfig");
  const redirectUrl = window.location.origin;
  window.location.replace(redirectUrl);
}

// redirect user to sign in on session expire
export function redirectToMpin(session = false) {
  const userFromStore = store.get("user");
  const instConfig = store.get("privilegeConfig");
  const user = { ...userFromStore };
  if (session) {
    store.set("sessionExpired", true);
  }
  store.remove("user");
  logout(user.userId);
  store.remove("academicYear");
  store.remove("accessToken");
  store.remove("instituteId");
  store.remove("privilegeConfig");
  if (
    user.mPin &&
    instConfig?.privileges?.enable_MPIN &&
    instConfig?.privileges?.enableMPIN?.includes(user.designation)
  ) {
    store.set("employeeId", user.userId);
    store.set("instituteId", user.instituteId);
  }
  const redirectUrl = window.location.origin;
  window.location.replace(redirectUrl);
}

function abbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => {
    abortController.abort();
  }, timeoutMs || 0);
  return abortController.signal;
}

// attach token to every request
baseAxios.interceptors.request.use(
  (config) => {
    const token = store.get("accessToken");
    const academicYear = store.get("academicYear") || "0-0";
    const ay = store.get("ay");
    if (token) {
      config.headers["x-access-token"] = token;
      config.headers["x-academic-year"] = academicYear;
      config.headers["x-ay"] = ay;
    }
    config.signal = abbortSignal(600000);
    return config;
  },
  (error) => Promise.reject(error)
);

// response
baseAxios.interceptors.response.use(
  async (res) => {
    if (
      res.headers?.["content-type"]?.includes(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      const paths = [
        "admissions/student",
        "finance/assign-fee",
        "download-paid-and-due-report",
        "download-fee-dues",
      ];
      const url = res?.config?.url;
      const isBase64 = paths.some((path) => url.includes(path));
      download(res.data, res.headers?.["x-file-name"], isBase64);
    }

    if (res.headers?.["x-bulk-summary"]) {
      const summary = JSON.parse(res.headers?.["x-bulk-summary"]);

      res.data = {
        message: "bulk action is successfully",
        result: summary,
      };
    }
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    // Handle timeout error (for requests where timeout is set)
    if (err.code === "ECONNABORTED" && err.message.includes("timeout")) {
      const error = new Error("Request timed out. Please try again later.");
      error.status = 408;
      return Promise.reject(error);
    }
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig.retry) {
        redirectToMpin(true);
        return Promise.reject(err.response.data);
        // originalConfig.retry = true;
        // try {
        //   const rs = await refreshToken();
        //   const { accessToken } = rs.data;
        //   store.set("accessToken", accessToken);
        //   baseAxios.defaults.headers.common["x-access-token"] = accessToken;
        //   return baseAxios(originalConfig);
        // } catch (_error) {
        //   if (_error.response && _error.response.data) {
        //     return Promise.reject(_error.response.data);
        //   }
        //   return Promise.reject(_error);
        // }
      }
      if (err.response.status === 440 && err.response.data) {
        // session expired - logout
        redirectToMpin(true);
        return Promise.reject(err.response.data);
      }
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  }
);
export default baseAxios;

export const adminAxios = axios.create({
  baseURL: getAPIUrlConstants("ADMIN").BASE_URL,
  headers: { "Content-type": "application/json" },
  withCredentials: true,
});

export const examAxios = axios.create({
  baseURL: getAPIUrlConstants("ADMIN").EXAM_SERVER_URL,
  headers: { "Content-type": "application/json" },
  withCredentials: true,
});

// attach token to every request
adminAxios.interceptors.request.use(
  (config) => {
    const token = store.get("accessToken");
    if (token) {
      config.headers["x-access-token"] = token;
      config.headers["x-request-origin"] = "erp";
    }
    return config;
  },
  (error) => Promise.reject(error)
);
