import startCase from "lodash/startCase";
import Stack from "@mui/material/Stack";
// ui components
import SoftBox from "myComponents/SoftBox";
import SoftInput from "myComponents/SoftInput";
import SoftTypography from "myComponents/SoftTypography";

// error formik.touched.employeeId && Boolean(formik.errors.employeeId)
const RequiredLabel = ({ required, label, noHeaderLabel }) => (
  <Stack direction="row">
    {!noHeaderLabel && (
      <SoftTypography variant="caption" fontWeight="bold">
        {required ? startCase(label) : startCase(label)}
      </SoftTypography>
    )}
    <SoftTypography variant="caption" color="error" fontWeight="bold">
      {required ? "*" : null}
    </SoftTypography>
  </Stack>
);

const SoftTextField = ({
  name,
  type,
  label,
  value,
  onChange,
  error = false,
  helperText,
  small,
  containerProps,
  required,
  multiline,
  noHeaderLabel,
  rows,
  InputProps,
  min,
  max,
  ...rest
}) => (
  <SoftBox {...containerProps}>
    {label || name ? (
      <SoftBox mb={0.5}>
        <RequiredLabel
          name={name}
          label={label}
          required={required}
          noHeaderLabel={noHeaderLabel}
        />
      </SoftBox>
    ) : null}
    <SoftInput
      size={small ? "small" : "medium"}
      name={name}
      type={type || "text"}
      placeholder={startCase(label)}
      value={value}
      error={error}
      onChange={onChange}
      required={required}
      max={max}
      min={min}
      multiline={multiline}
      helperText={helperText}
      rows={rows}
      {...rest}
    />
    {error || helperText ? (
      <SoftTypography variant="caption" color="error">
        {helperText}
      </SoftTypography>
    ) : null}
  </SoftBox>
);

export default SoftTextField;
