import { styled } from "@mui/material/styles";

export default styled("div")(({ theme, ownerState }) => {
  const { palette, functions } = theme;
  const { size, clickable = false, isDropdown } = ownerState;

  const { dark, grey } = palette;
  const { pxToRem } = functions;

  const dropdownStyles = {};

  if (isDropdown) {
    dropdownStyles.width = "auto";
    dropdownStyles.display = "flex";
    dropdownStyles.alignItems = "center";
    dropdownStyles.justifyContent = "space-between";
    dropdownStyles.borderBottomRightRadius = pxToRem(16);
    dropdownStyles.borderTopRightRadius = pxToRem(16);
    dropdownStyles.backgroundColor = grey["100"];
    dropdownStyles.borderLeft = `1px solid ${grey[400]}`;
  }

  return {
    lineHeight: 0,
    padding:
      size === "small"
        ? `${pxToRem(4)} ${pxToRem(10)}`
        : `${pxToRem(8)} ${pxToRem(10)}`,
    ...(!isDropdown && { width: pxToRem(39) }),
    height: "100%",
    color: dark.main,
    ...((clickable || isDropdown) && { cursor: "pointer" }),
    ...dropdownStyles,
  };
});
