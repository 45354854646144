import store from "store";
import baseAxios from "../baseAxios";

export async function registerEmployee(body) {
  try {
    const { data } = await baseAxios.post("employee/register", body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function sendOtpEmail(body) {
  try {
    const { data } = await baseAxios.post("employee/sendOtpEmail", body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function registerEmployeeForInstituteSetUp(body) {
  try {
    const { data } = await baseAxios.post(
      "employee/registerEmployeeForInstituteSetUp",
      body
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createEmployeeActivity(body) {
  try {
    const { data } = await baseAxios.post(
      "employee/createEmployeeActivity",
      body
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function verifyOTP(body) {
  try {
    const { data } = await baseAxios.post("employee/verifyOTP", body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function forgotPassword(body) {
  try {
    const { data } = await baseAxios.post("employee/forgot-password", body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateEmployee({ id, headers, formData, ...rest }) {
  try {
    const { data } = await baseAxios.patch(
      `employee/${id}`,
      formData || { ...rest },
      { headers }
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// export async function getEmployees(params) {
//   try {
//     const { data } = await baseAxios.get("employee/get-users", {
//       params,
//     });
//     return data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// }
export async function getEmployees(obj) {
  const { id, ...rest } = obj;
  const path = id ? `employee/${id}` : "employee";
  try {
    const { data } = await baseAxios.get(path, {
      params: { ...rest, instituteId: store.get("instituteId") },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getEmpsByCreator(obj) {
  const path = "employee/fetchByCreator";
  try {
    const { data } = await baseAxios.post(path, {
      ...obj,
      instituteId: store.get("instituteId"),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getTeachers(obj) {
  const path = "employee/getTeachers";
  try {
    const { data } = await baseAxios.post(path, {
      ...obj,
      instituteId: store.get("instituteId"),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function clearFCM(id) {
  try {
    const { data } = await baseAxios.post(`employee/clearFCM/${id}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function userActivityList(params) {
  try {
    const { data } = await baseAxios.get(`employee/getEmployeeActivityList`, {
      params,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function empWildQuery(searchTerm) {
  try {
    const { data } = await baseAxios.get(`employee/wild/${searchTerm}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBasicDetialsOfAllEmployees() {
  try {
    const { data } = await baseAxios.get(`employee/details`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateLoginRestrictionTime(payload) {
  try {
    const { data } = await baseAxios.post(
      `employee/updateLoginRestrictionTime`,
      payload
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function passwordRotation(payload) {
  try {
    const { data } = await baseAxios.post(`employee/passwordRotation`, payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function passwordReset(payload) {
  try {
    const { data } = await baseAxios.post(`employee/passwordReset`, payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchRotatedPasswords(obj) {
  const path = "employee/fetchRotatedPasswords";
  try {
    const { data } = await baseAxios.post(path, {
      ...obj,
      instituteId: store.get("instituteId"),
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchCampaignEmployees() {
  try {
    const { data } = await baseAxios.post(`employee/campaign-emps`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchCashierEmployees(obj) {
  try {
    const { data } = await baseAxios.post(`employee/br-cashiers`, obj);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function pushNotificationForEmployee(body) {
  console.log("body", body);
  try {
    return (await baseAxios.post(`employee/push-notification`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function createPasswordResetOtp(body) {
  try {
    const { data } = await baseAxios.post(
      "employee/createPasswordResetOtp",
      body
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function verifySentSmsOtp(body) {
  try {
    const { data } = await baseAxios.post("employee/verifySentSmsOtp", body);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchCampaigns() {
  try {
    const { data } = await baseAxios.post(`employee/getCampaigns`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
