import { createSlice } from "@reduxjs/toolkit";

// Initial state for the inventory slice
const initialState = {
  inventoryItems: [],
  purchaseOrders: [],
  purchaseReceives: [],
  vendorPayments: [],
  // Reusable data arrays
  taxItems: [],
  unitsOfMeasurement: [],
  assetGroups: [],
  categories: [],
  inventoryAccounts: [],
  manufacturers: [],
  brands: [],
  vendors: [],
  // Status flags
  initialized: false,
  loading: false,
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    fetchBasicInventoryDataStart(state) {
      state.initialized = false;
    },
    fetchBasicInventoryDataSuccess(state, action = {}) {
      const {
        taxItems = [],
        assetGroups = [],
        categories = [],
        inventoryAccounts = [],
        manufacturers = [],
        brands = [],
        vendors = [],
        units = [],
      } = action.payload || {};
      state.taxItems = taxItems;
      state.unitsOfMeasurement = units;
      state.assetGroups = assetGroups;
      state.categories = categories;
      state.inventoryAccounts = inventoryAccounts;
      state.manufacturers = manufacturers;
      state.brands = brands;
      state.vendors = vendors;
      state.initialized = true;
    },
    fetchBasicInventoryDataError() {},
    // CRUD operations for inventoryItems
    fetchInventoryItemsStart() {},
    fetchInventoryItemsSuccess(state, action) {
      state.inventoryItems = action.payload;
    },
    addInventoryItemStart() {},
    addInventoryItemSuccess(state, action) {
      state.inventoryItems.push(action.payload);
    },
    updateInventoryItemStart() {},
    updateInventoryItemSuccess(state, action) {
      const index = state.inventoryItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index !== -1) {
        state.inventoryItems[index] = action.payload;
      }
    },
    deleteInventoryItemStart() {},
    deleteInventoryItemSuccess(state, action) {
      state.inventoryItems = state.inventoryItems.filter(
        (item) => item.id !== action.payload.id
      );
    },
    // CRUD operations for purchaseOrders
    fetchPurchaseOrderStart() {},
    fetchPurchaseOrderSuccess(state, action) {
      state.purchaseOrders = action.payload;
    },
    addPurchaseOrderStart() {},
    addPurchaseOrderSuccess(state, action) {
      state.purchaseOrders.push(action.payload);
    },
    updatePurchaseOrderStart() {},
    updatePurchaseOrderSuccess(state, action) {
      const index = state.purchaseOrders.findIndex(
        (order) => order.id === action.payload.id
      );
      if (index !== -1) {
        state.purchaseOrders[index] = action.payload;
      }
    },
    deletePurchaseOrderStart() {},
    deletePurchaseOrderSuccess(state, action) {
      state.purchaseOrders = state.purchaseOrders.filter(
        (order) => order.id !== action.payload.id
      );
    },
    // CRUD operations for purchaseReceives
    addPurchaseReceiveStart() {},
    addPurchaseReceiveSuccess(state, action) {
      state.purchaseReceives.push(action.payload);
    },
    updatePurchaseReceiveStart() {},
    updatePurchaseReceiveSuccess(state, action) {
      const index = state.purchaseReceives.findIndex(
        (receive) => receive.id === action.payload.id
      );
      if (index !== -1) {
        state.purchaseReceives[index] = action.payload;
      }
    },
    deletePurchaseReceiveStart() {},
    deletePurchaseReceiveSuccess(state, action) {
      state.purchaseReceives = state.purchaseReceives.filter(
        (receive) => receive.id !== action.payload.id
      );
    },
    // CRUD operations for vendorPayments
    addVendorPaymentStart() {},
    addVendorPaymentSuccess(state, action) {
      state.vendorPayments.push(action.payload);
    },
    updateVendorPaymentStart() {},
    updateVendorPaymentSuccess(state, action) {
      const index = state.vendorPayments.findIndex(
        (payment) => payment.id === action.payload.id
      );
      if (index !== -1) {
        state.vendorPayments[index] = action.payload;
      }
    },
    deleteVendorPaymentStart() {},
    deleteVendorPaymentSuccess(state, action) {
      state.vendorPayments = state.vendorPayments.filter(
        (payment) => payment.id !== action.payload.id
      );
    },
    // CRUD operations for manufacturers
    fetchManufacturersStart() {},
    fetchManufacturersSuccess(state, action) {
      state.manufacturers = action.payload;
    },
    addManufacturerStart() {},
    addManufacturerSuccess(state, action) {
      state.manufacturers.push(action.payload);
    },
    updateManufacturerStart() {},
    updateManufacturerSuccess(state, action) {
      const index = state.manufacturers.findIndex(
        (manufacturer) => manufacturer._id === action.payload._id
      );
      if (index !== -1) {
        state.manufacturers[index] = action.payload;
      }
    },
    deleteManufacturerStart() {},
    deleteManufacturerSuccess(state, action) {
      state.manufacturers = state.manufacturers.filter(
        (manufacturer) => manufacturer.id !== action.payload.id
      );
    },
    // CRUD operations for brands
    addBrandStart() {},
    addBrandSuccess(state, action) {
      state.brands.push(action.payload);
    },
    updateBrandStart() {},
    updateBrandSuccess(state, action) {
      const index = state.brands.findIndex(
        (brand) => brand._id === action.payload._id
      );
      if (index !== -1) {
        state.brands[index] = action.payload;
      }
    },
    // CU operations for assetGroups
    addAssetGroupStart() {},
    addAssetGroupSuccess(state, action) {
      state.assetGroups.push(action.payload);
    },
    updateAssetGroupStart() {},
    updateAssetGroupSuccess(state, action) {
      const index = state.assetGroups.findIndex(
        (group) => group.id === action.payload.id
      );
      if (index !== -1) {
        state.assetGroups[index] = action.payload;
      }
    },
    addVendorStart() {},
    addVendorSuccess(state, action) {
      state.vendors.push(action.payload);
    },
    updateVendorStart() {},
    updateVendorSuccess(state, action) {
      const index = state.vendors.findIndex(
        (vendor) => vendor._id === action.payload.id
      );
      if (index !== -1) {
        state.vendors[index] = action.payload;
      }
    },
    getVendorsStart() {},
    getVendorsSuccess(state, action) {
      state.vendors = action.payload;
    },
    addInventoryAccountStart() {},
    addInventoryAccountSuccess(state, action) {
      state.inventoryAccounts.push(action.payload);
    },
    updateInventoryAccountStart() {},
    updateInventoryAccountSuccess(state, action) {
      const index = state.inventoryAccounts.findIndex(
        (account) => account.id === action.payload.id
      );
      if (index !== -1) {
        state.inventoryAccounts[index] = action.payload;
      }
    },
    fetchInventoryAccountsStart() {},
    fetchInventoryAccountsSuccess(state, action) {
      state.inventoryAccounts = action.payload;
    },
    fetchPurchaseOrderFormInputsStart() {},
    fetchPurchaseOrderFormInputsSuccess(state, action) {
      const {
        vendors = [],
        items = [],
        taxes = [],
        accounts = [],
      } = action.payload || {};
      state.vendors = vendors;
      state.inventoryItems = items;
      state.taxItems = taxes;
      state.inventoryAccounts = accounts;
    },
    setInventoryLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {
  fetchBasicInventoryDataStart,
  fetchBasicInventoryDataSuccess,
  fetchBasicInventoryDataError,
  addInventoryItemStart,
  addInventoryItemSuccess,
  updateInventoryItemStart,
  updateInventoryItemSuccess,
  deleteInventoryItemStart,
  deleteInventoryItemSuccess,
  addPurchaseOrderStart,
  addPurchaseOrderSuccess,
  updatePurchaseOrderStart,
  updatePurchaseOrderSuccess,
  deletePurchaseOrderStart,
  deletePurchaseOrderSuccess,
  addPurchaseReceiveStart,
  addPurchaseReceiveSuccess,
  updatePurchaseReceiveStart,
  updatePurchaseReceiveSuccess,
  deletePurchaseReceiveStart,
  deletePurchaseReceiveSuccess,
  addVendorPaymentStart,
  addVendorPaymentSuccess,
  updateVendorPaymentStart,
  updateVendorPaymentSuccess,
  deleteVendorPaymentStart,
  deleteVendorPaymentSuccess,
  fetchManufacturersStart,
  fetchManufacturersSuccess,
  addManufacturerStart,
  addManufacturerSuccess,
  updateManufacturerStart,
  updateManufacturerSuccess,
  deleteManufacturerStart,
  deleteManufacturerSuccess,
  addBrandStart,
  addBrandSuccess,
  updateBrandStart,
  updateBrandSuccess,
  addAssetGroupStart,
  addAssetGroupSuccess,
  updateAssetGroupStart,
  updateAssetGroupSuccess,
  addVendorStart,
  addVendorSuccess,
  updateVendorStart,
  updateVendorSuccess,
  getVendorsStart,
  getVendorsSuccess,
  fetchInventoryItemsStart,
  fetchInventoryItemsSuccess,
  addInventoryAccountStart,
  addInventoryAccountSuccess,
  updateInventoryAccountStart,
  updateInventoryAccountSuccess,
  fetchInventoryAccountsStart,
  fetchInventoryAccountsSuccess,
  fetchPurchaseOrderFormInputsStart,
  fetchPurchaseOrderFormInputsSuccess,
  setInventoryLoading,
  fetchPurchaseOrderStart,
  fetchPurchaseOrderSuccess,
} = inventorySlice.actions;

export default inventorySlice.reducer;
