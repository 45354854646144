// import { lazy } from "react";
// icons
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
// Helpers
import lazy from "helpers/LazyImport";
import flatten from "lodash/flatten";
// body layout
import features from "config/features";
import Container from "../../layout/simpleContainer";
// roles
// import roles from "../roles";

export const Inventory = lazy(() => import("../../views/Inventory"));
export const Reports = lazy(() => import("../../views/Inventory/Reports"));
export const InventoryReport = lazy(() =>
  import("../../views/Inventory/Reports/InventoryReport")
);
export const StudentsBalanceReport = lazy(() =>
  import("../../views/Inventory/Reports/StudentsBalanceReport")
);
export const InventoryItems = lazy(() => import("../../views/Inventory/Items"));
export const CreateInventoryItem = lazy(() =>
  import("../../views/Inventory/Items/NewItem")
);
export const AssetGroups = lazy(() =>
  import("../../views/Inventory/Items/AssetGroups")
);
export const Vendors = lazy(() => import("../../views/Inventory/Vendor"));
export const NewVendor = lazy(() =>
  import("../../views/Inventory/Vendor/NewVendor")
);
export const InventoryAccounts = lazy(() =>
  import("../../views/Inventory/Accounts")
);
export const PurchaseOrders = lazy(() =>
  import("../../views/Inventory/PurchaseOrders")
);
export const NewPurchaseOrder = lazy(() =>
  import("../../views/Inventory/PurchaseOrders/NewPurchaseOrder")
);

export default {
  component: <Container />,
  title: "Inventory",
  basePath: "app",
  path: "inventory-management",
  icon: <Inventory2RoundedIcon />,
  features: [...flatten(Object.values(features.inventory))],
  children: [
    {
      component: <Inventory />,
      title: "Inventory",
      basePath: "inventory-management",
      path: "inventory",
      features: [...flatten(Object.values(features.inventory))],
    },
    {
      component: <Container />,
      index: <Reports />,
      title: "Reports",
      basePath: "inventory-management",
      path: "reports",
      features: features.inventory?.inventory_report,
      children: [
        {
          component: <InventoryReport />,
          title: "Ledger Report",
          basePath: "reports",
          path: "inventory-report",
          features: features.inventory?.student_ledger_report,
        },
        {
          component: <StudentsBalanceReport />,
          title: "Allowance Report",
          basePath: "reports",
          path: "allowance-report",
          features: features.inventory?.student_allowance_report,
        },
      ],
    },
    {
      component: <Container />,
      title: "Items",
      basePath: "inventory-management",
      path: "items",
      features: [],
      index: <InventoryItems />,
      children: [
        {
          component: <CreateInventoryItem />,
          title: "New",
          basePath: "items",
          path: "new",
          features: [],
        },
        {
          component: <CreateInventoryItem />,
          title: "Edit",
          basePath: "items",
          path: "edit",
          features: [],
        },
      ],
    },
    {
      component: <AssetGroups />,
      title: "Asset Groups",
      basePath: "inventory-management",
      path: "asset-groups",
      features: [],
    },
    {
      component: <Container />,
      title: "Vendors",
      basePath: "inventory-management",
      path: "vendors",
      features: [],
      index: <Vendors />,
      children: [
        {
          component: <NewVendor />,
          title: "New",
          basePath: "vendors",
          path: "new",
          features: [],
        },
        {
          component: <NewVendor />,
          title: "edit",
          basePath: "vendors",
          path: "edit",
          features: [],
        },
      ],
    },
    {
      component: <InventoryAccounts />,
      title: "Accounts",
      basePath: "inventory-management",
      path: "accounts",
      features: [],
    },
    {
      component: <Container />,
      title: "Purchase Orders",
      basePath: "inventory-management",
      path: "purchase-orders",
      index: <PurchaseOrders />,
      features: [],
      children: [
        {
          component: <NewPurchaseOrder />,
          title: "New",
          basePath: "purchase-orders",
          path: "new",
          features: [],
        },
      ],
    },
  ],
};
