// MUI
import store from "store";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
// MUI
import Grid from "@mui/material/Grid";
// myComponents
import SoftTypography from "myComponents/SoftTypography";
import SoftInput from "myComponents/SoftInput";
// hooks
import useMyPrivileges from "hooks/useMyPrivileges";
// myComponents
import SoftBox from "myComponents/SoftBox";

const CustomForm = ({ values, setValues }) => {
  const { allow_past_transaction_entry } = useMyPrivileges([
    "allow_past_transaction_entry",
  ]);
  const today = dayjs();
  const endOfToday = today.endOf("day").format("YYYY-MM-DD");
  const startOfToday = today.startOf("day").format("YYYY-MM-DD");
  const dateInputRef = useRef(null);

  useEffect(() => {
    if (dateInputRef.current) {
      dateInputRef.current.setAttribute("max", endOfToday);
      if (!allow_past_transaction_entry) {
        dateInputRef.current.setAttribute("min", startOfToday);
      }
    }
  }, [allow_past_transaction_entry, endOfToday, startOfToday]);

  const handleChange = (field) => (event) => {
    setValues((prev) => ({ ...prev, [field]: event.target.value }));
  };

  return (
    <SoftBox p={1} ml={3} pb={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <SoftBox maxWidth={300}>
            <SoftTypography
              variant="caption"
              fontWeight="bold"
              color="secondary"
              display="block"
              ml={1}
              mb={0.3}
            >
              Ref No
            </SoftTypography>
            <SoftInput
              id="RefNo"
              name="RefNo"
              placeholder="eg :123445"
              required={store.get("instituteId") === "646634c8a400ecffc1306d0d"}
              value={values.RefNo}
              onChange={handleChange("RefNo")}
              size="small"
              fullWidth
            />
          </SoftBox>
        </Grid>
        {values.mode === "SWIPE" ||
        values.mode === "account_transfer" ||
        values.mode === "NB" ? (
          <>
            <Grid item xs={12} md={6}>
              <SoftBox maxWidth={300}>
                <SoftTypography
                  variant="caption"
                  fontWeight="bold"
                  color="secondary"
                  display="block"
                  ml={1}
                  mb={0.3}
                >
                  Bank Name
                </SoftTypography>
                <SoftInput
                  id="bankName"
                  name="bankName"
                  placeholder="eg :123445"
                  required={
                    store.get("instituteId") === "646634c8a400ecffc1306d0d"
                  }
                  value={values.bankName}
                  onChange={handleChange("bankName")}
                  size="small"
                  fullWidth
                />
              </SoftBox>
            </Grid>

            {values.mode === "account_transfer" ||
            values.mode === "NB" ? null : (
              <Grid item xs={12} md={6}>
                <SoftBox maxWidth={300}>
                  <SoftTypography
                    variant="caption"
                    fontWeight="bold"
                    color="secondary"
                    display="block"
                    ml={1}
                    mb={0.3}
                  >
                    Card Last 4 digits
                  </SoftTypography>
                  <SoftInput
                    id="cardLast4Digits"
                    name="cardLast4Digits"
                    placeholder="eg :123445"
                    required={
                      store.get("instituteId") === "646634c8a400ecffc1306d0d"
                    }
                    value={values.cardLast4Digits}
                    onChange={handleChange("cardLast4Digits")}
                    size="small"
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                  />
                </SoftBox>
              </Grid>
            )}
          </>
        ) : null}
        {allow_past_transaction_entry ? (
          <Grid item xs={12} md={6}>
            <SoftBox maxWidth={300}>
              <SoftTypography
                variant="caption"
                fontWeight="bold"
                color="secondary"
                display="block"
                ml={1}
                mb={0.3}
              >
                Date
              </SoftTypography>
              <SoftInput
                id="chequeDate"
                name="chequeDate"
                type="date"
                value={values.chequeDate}
                onChange={handleChange("chequeDate")}
                size="small"
                fullWidth
                inputRef={dateInputRef}
                disabled={!allow_past_transaction_entry}
              />
            </SoftBox>
          </Grid>
        ) : null}
      </Grid>
    </SoftBox>
  );
};
export default CustomForm;
