// Helpers
import features from "config/features";
import lazyWithRetry from "helpers/LazyImport";
import Container from "layout/simpleContainer";
import flatten from "lodash/flatten";
import { common } from "config/routesConfig";

// views
import Profile from "views/Mobile/Profile";
// local
import homeRoutesFeatures from "./routeFeaturesMap";
// views
const Finance = lazyWithRetry(() => import("../../../views/Mobile/Finance"));
const Admissions = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions")
);
const ClassWise = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/ClassWiseStregth")
);
const BranchWiseSummary = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/BranchWiseSummary")
);
const ZoneWiseCampaign = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/BranchWiseCampaignsList")
);
const Collections = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/Collections")
);
const BranchCollectionsOfZone = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/BranchCollections")
);
const BranchWiseCampaignsList = lazyWithRetry(() =>
  import(
    "../../../views/Mobile/Finance/CampaignCollections/BranchWiseCamCollections"
  )
);
const BranchCollectionsOfZonals = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/ZonalBranchesCollections")
);
const ClassWiseSummary = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/BatchWiseSummary")
);
const StudentDetails = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/StudentDetails")
);
const FollowUps = lazyWithRetry(() =>
  import("../../../views/Admissions/FollowUp")
);

const ConcessionApprovals = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/ConcessionApprovals")
);
const ViewTransactions = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/ViewTransactions")
);
const StudentDisplay = lazyWithRetry(() =>
  import("../../../views/Admissions/StudentDisplay")
);

const Dues = lazyWithRetry(() => import("../../../views/Mobile/Finance/Dues"));
const BranchDues = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/BranchDues")
);
const ZonalBranchDues = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/ZonalBranchesDues")
);

const ViewDueStudents = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/ViewDueStudents")
);
const ConcessionDetails = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/ConcessionDetails")
);
// attendance
const Attendance = lazyWithRetry(() =>
  import("../../../views/Mobile/Attendance")
);
const BranchAttendance = lazyWithRetry(() =>
  import("../../../views/Mobile/Attendance/BranchSummary")
);
const BatchesAttendance = lazyWithRetry(() =>
  import("../../../views/Mobile/Attendance/BatchesSummary")
);
const ZoneConcession = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/ZoneConcession")
);
const ClassScheduleAttendance = lazyWithRetry(() =>
  import("../../../views/Attendance/ScheduleAttendance")
);
// const Profile = lazyWithRetry(() => import("../../../views/Mobile/Profile"));
const Card = lazyWithRetry(() =>
  import("../../../components/MobileSubModules")
);
const BranchPunches = lazyWithRetry(() =>
  import("../../../views/Attendance/BranchPunches")
);
const MakePayment = lazyWithRetry(() =>
  import("../../../views/Finance/MakePayment")
);
// const PaymentPage = lazyWithRetry(() =>
//   import("../../../views/Finance/Payment/index")
// );
const BatchWiseCampaignList = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/BatchWiseCampaignAdmissions")
);
const CampaignList = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/CampaignAdmissions")
);
const CampaignStudentDetails = lazyWithRetry(() =>
  import("../../../views/Mobile/Admissions/CampaignStudentsList")
);
const CampaignCollections = lazyWithRetry(() =>
  import("../../../views/Mobile/Finance/CampaignCollections")
);

export default [
  {
    component: <Container />,
    title: "Finance",
    basePath: "app",
    path: "finance",
    features: homeRoutesFeatures["/finance"],
    index: <Finance />,
    children: [
      {
        component: <Container />,
        title: "Collections",
        basePath: "finance",
        path: "collections",
        features: homeRoutesFeatures["/finance/collections"],
        index: <Collections />,
        children: [
          {
            component: <Container />,
            title: "Branch Collections of Zone",
            basePath: "collections",
            path: ":zoneCode",
            features: homeRoutesFeatures["/finance/collections/:zoneCode"],
            index: <BranchCollectionsOfZone />,
            children: [
              {
                component: <ViewTransactions />,
                title: "View Transactions",
                basePath: ":zoneCode",
                path: ":branchCode",
                features:
                  homeRoutesFeatures[
                    "/finance/collections/:zoneCode/:branchCode"
                  ],
              },
            ],
          },
          {
            component: <Container />,
            title: "Branch Collections of Zonals",
            basePath: "collections",
            path: "head",
            features: homeRoutesFeatures["/finance/collections/head"],
            index: <BranchCollectionsOfZonals />,
            children: [
              {
                component: <ViewTransactions />,
                title: "View Transactions",
                basePath: ":branchCode",
              },
            ],
          },
        ],
      },
      {
        component: <Container />,
        title: " Campaign Collections",
        basePath: "finance",
        path: "campaign-collections",
        features: homeRoutesFeatures["/finance/campaign-collections"],
        index: <CampaignCollections />,
        children: [
          {
            component: <Container />,
            title: "Branch Collections of Zone",
            basePath: "campaign-collections",
            path: "camapign",
            features:
              homeRoutesFeatures["/finance/campaign-collections/camapign"],
            index: <BranchWiseCampaignsList />,
            children: [
              {
                component: <ViewTransactions />,
                title: "View Transactions",
                basePath: "campaign",
                path: "txns",
                features:
                  homeRoutesFeatures[
                    "/finance/campaign-collectionss/camapign/txns"
                  ],
              },
            ],
          },
          {
            component: <Container />,
            title: "Branch Collections of Zonals",
            basePath: "collections",
            path: "head",
            features: homeRoutesFeatures["/finance/collections/head"],
            index: <BranchCollectionsOfZonals />,
            children: [
              {
                component: <ViewTransactions />,
                title: "View Transactions",
                basePath: ":branchCode",
              },
            ],
          },
        ],
      },

      {
        component: <Container />,
        title: "Dues",
        basePath: "finance",
        path: "dues",
        features: homeRoutesFeatures["/finance/dues"],
        index: <Dues />,
        children: [
          {
            component: <Container />,
            title: "Branch Dues",
            basePath: "dues",
            path: ":zoneCode",
            features: homeRoutesFeatures["/finance/dues/:zoneCode"],
            index: <BranchDues />,
            children: [
              {
                component: <ViewDueStudents />,
                title: "View Due Students",
                basePath: ":zoneCode",
                path: ":branchCode",
                features:
                  homeRoutesFeatures["/finance/dues/:zoneCode/:branchCode"],
              },
            ],
          },
          {
            component: <Container />,
            title: "Branch Dues of Zonals",
            basePath: "dues",
            path: "head",
            features: homeRoutesFeatures["/finance/dues/head"],
            index: <ZonalBranchDues />,
            children: [
              {
                component: <ViewDueStudents />,
                title: "View Due Students",
                basePath: ":zoneCode",
                path: ":branchCode",
                features: [],
              },
            ],
          },
        ],
      },
      {
        component: <Container />,
        title: "Concession Approvals",
        basePath: "finance",
        path: "concession-approvals",
        features: [],
        index: <ConcessionApprovals />,
        children: [
          {
            component: <Container />,
            index: <ZoneConcession />,
            title: "Concession approvals",
            basePath: "concession-approvals",
            path: ":zoneCode",
            // features: flatten(Object.values(features.dashboard.Finance_tab)),
            children: [
              {
                component: <ConcessionDetails />,
                title: "Concession approvals",
                basePath: ":zoneCode",
                path: ":branchCode",
              },
            ],
          },
        ],
      },
      {
        component: <Container />,
        title: "Txn. Revert Approvals",
        basePath: "finance",
        path: "transaction_revert-approvals",
        features: [],
        index: <ConcessionApprovals />,
        children: [
          {
            component: <Container />,
            index: <ZoneConcession />,
            title: "Txn. Revert Approvals",
            basePath: "transaction_revert-approvals",
            path: ":zoneCode",
            // features: flatten(Object.values(features.dashboard.Finance_tab)),
            children: [
              {
                component: <ConcessionDetails />,
                title: "Txn. Revert Approvals",
                basePath: ":zoneCode",
                path: ":branchCode",
              },
            ],
          },
        ],
      },
      {
        component: <Container />,
        title: "Txn. Update Approvals",
        basePath: "finance",
        path: "transaction_update-approvals",
        features: [],
        index: <ConcessionApprovals />,
        children: [
          {
            component: <Container />,
            index: <ZoneConcession />,
            title: "Txn. Update Approvals",
            basePath: "transaction_update-approvals",
            path: ":zoneCode",
            // features: flatten(Object.values(features.dashboard.Finance_tab)),
            children: [
              {
                component: <ConcessionDetails />,
                title: "Txn. Update Approvals",
                basePath: ":zoneCode",
                path: ":branchCode",
              },
            ],
          },
        ],
      },
      {
        component: <MakePayment />,
        title: "Payments",
        basePath: "finance",
        path: "make-payment",
        homeRoutesFeatures: homeRoutesFeatures["/finance/make-payment"],
        features: ["make_payment", "reservation_fee_payment"],
        noUiLink: true,
      },
      // {
      //   component: <PaymentPage />,
      //   title: "Payment",
      //   basePath: "finance",
      //   path: "payment",
      //   features: ["make_payment", "reservation_fee_payment"],
      //   noUiLink: true,
      // },
    ],
  },
  {
    component: <Container />,
    title: "Admissions",
    basePath: "app",
    index: <Admissions />,
    path: "admissions",
    features: homeRoutesFeatures["/admissions"],
    noUiLink: true,
    children: [
      {
        component: <ClassWise />,
        title: "Class Wise Strength",
        basePath: "admissions",
        path: "class-strength",
        features: flatten(Object.values(features.dashboard.admission_tab)),
        noUiLink: true,
      },
      {
        component: <Container />,
        title: "Admissions",
        basePath: "app",
        index: <CampaignList />,
        path: "campaigns",
        features: homeRoutesFeatures["/admissions/campaigns"],
        noUiLink: true,

        children: [
          {
            component: <Container />,
            index: <ZoneWiseCampaign />,
            title: "Campaign Admissions",
            basePath: "admissions",
            path: "zone-campaign-admissions",
            features:
              homeRoutesFeatures[
                "/admissions/campaigns/zone-campaign-admissions"
              ],
            noUiLink: true,
            children: [
              {
                index: <BatchWiseCampaignList />,
                component: <Container />,
                title: "Batch Wise Campaign Admissions",
                basePath: "zone-campaign-admissions",
                path: "batch-campaign-admissions",
                noUiLink: true,
                features:
                  homeRoutesFeatures[
                    "/admissions/campaigns/zone-campaign-admissions/batch-campaign-admissions"
                  ],
                children: [
                  {
                    component: <CampaignStudentDetails />,
                    title: "Students List",
                    basePath: "batch-campaign-admissions",
                    path: "students-list",

                    noUiLink: true,
                    features:
                      homeRoutesFeatures[
                        "/admissions/campaigns/zone-campaign-admissions/batch-campaign-admissions/students-list"
                      ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        component: <Container />,
        index: <BranchWiseSummary />,
        title: "Branch Wise Strength",
        basePath: "admissions",
        path: "branches-strength",
        noUiLink: true,
        features: homeRoutesFeatures["/admissions/branches-strength"],
        children: [
          {
            component: <Container />,
            index: <ClassWiseSummary />,
            title: "Class Wise Strength",
            basePath: "branches-strength",
            path: "class-summary",
            features:
              homeRoutesFeatures["/admissions/branches-strength/class-summary"],
            noUiLink: true,
            children: [
              {
                component: <StudentDetails />,
                title: "Students List",
                basePath: "class-summary",
                path: "students-list",

                noUiLink: true,
                features:
                  homeRoutesFeatures[
                    "/admissions/branches-strength/class-summary/students-list"
                  ],
              },
            ],
          },
        ],
      },
      {
        component: <FollowUps />,
        title: "Follow Up",
        basePath: "admissions",
        path: "follow-up",
        features: ["followups"],
      },
      {
        component: <StudentDisplay />,
        title: "Student Profile",
        basePath: "admissions",
        path: "view-student",
        features: ["student_profile"],
      },
    ],
  },
  {
    component: <Container />,
    title: "Attendance",
    basePath: "app",
    path: "attendance",
    features: homeRoutesFeatures["/attendance"],
    index: <Card path="attendance" />,
    children: [
      {
        component: <Container />,
        title: "Attendance",
        basePath: "attendance",
        path: "attendance",
        features: homeRoutesFeatures["/attendance"],
        index: <Attendance />,
        children: [
          {
            component: <Container />,
            title: "Branch Attendance of Zone",
            basePath: "attendance",
            path: ":zoneCode",
            features: homeRoutesFeatures["/attendance"],
            index: <BranchAttendance />,
            children: [
              {
                component: <BatchesAttendance />,
                title: "Batches Attendance",
                basePath: ":zoneCode",
                path: ":branchCode",
                features: homeRoutesFeatures["/attendance"],
              },
            ],
          },
        ],
      },
      {
        component: <ClassScheduleAttendance />,
        title: "Daily Attendance",
        basePath: "attendance",
        path: "give-daily-attendance",
        features: features.attendance.daily_att,
      },
      {
        component: <BranchPunches />,
        title: "Staff Attendance",
        basePath: "attendance",
        path: "staff-attendance",
        features: ["view_staff_attendance"],
      },
    ],
  },
  ...common,
  {
    component: <Profile />,
    title: "Profile",
    basePath: "app",
    path: "profile",
    features: [],
  },
];
