import { lazy } from "react";
import flatten from "lodash/flatten";
// icons
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
// body layout
import features from "config/features";
import Container from "../../layout/simpleContainer";

export const PettyCash = lazy(() =>
  import("../../views/ExpenseManagement/PettyCash")
);
export const ExReports = lazy(() =>
  import("../../views/ExpenseManagement/Reports")
);
export const OcReport = lazy(() =>
  import("../../views/ExpenseManagement/OpeningAndClosingReport")
);
export const BranchesWiseReport = lazy(() =>
  import("../../views/ExpenseManagement/BranchesWiseReport")
);

// modules
// export const ExpenseManagement = lazy(() =>
//   import("../../views/ExpenseManagement/CashAndBankBalance")
// );

export default {
  component: <Container />,
  title: "Expense Management",
  basePath: "app",
  path: "expense-management",
  icon: <CurrencyRupeeRoundedIcon />,
  features: flatten(Object.values(features?.["expense-management"])),
  // authorizedRoles: ["expense-management", roles.organization.SUPER_ADMIN],
  children: [
    // {
    //   component: <Container />,
    //   title: "Cash & Bank Balance",
    //   basePath: "expense-management",
    //   path: "cash-bank-balance",
    //   // index: <ExpenseManagement />,
    //   authorizedRoles: ["fee-plan", roles.organization.SUPER_ADMIN],
    //   children: [{}],
    // },
    {
      component: <PettyCash />,
      title: "Petty Cash",
      basePath: "expense-management",
      path: "petty-cash",
      features: ["credit_cash", "add_to_ledger"],
    },
    {
      component: <ExReports />,
      title: "Expenses Report",
      basePath: "expense-management",
      path: "report",
      features: [
        "expenses_report",
        "credit_edit_action",
        "credit_print_action",
        "debit_edit_action",
        "debit_print_action",
        "debit_delete_action",
        "credit_delete_action",
      ],
    },
    {
      component: <OcReport />,
      title: "Opening & Closing Report",
      basePath: "expense-management",
      path: "opening-closing-report",
      features: ["opening_and_closing_expenses_report"],
    },
    {
      component: <BranchesWiseReport />,
      title: "Branches Wise Report",
      basePath: "expense-management",
      path: "branches-report",
      features: ["branches_report"],
    },
  ],
};
