import unionBy from "lodash/unionBy";

export function sortArrayOfObjectsByAProp(list, sortBy) {
  return list.reduce((a, c) => {
    const val = c[sortBy];
    if (a[val]) a[val].push(c);
    else a[val] = [c];
    return a;
  }, {});
}

export function genrateMapFromList(list, propName) {
  return list.reduce((a, c) => {
    a[c[propName]] = c;
    return a;
  }, {});
}

export function getNewList(list1, list2, propName) {
  const ids = new Set(list1.map((d) => d[propName]));
  return [...list1, ...list2.filter((d) => !ids.has(d[propName]))];
}

export function getUpdateUnionOfLists(list1, list2, propName = "_id") {
  const listOneMap = genrateMapFromList(list1, propName);
  const listTwoMap = genrateMapFromList(list2, propName);
  return Object.values({ ...listOneMap, ...listTwoMap });
}

export function getUnionOfLists(list1, list2, propName = "_id") {
  return unionBy(list1, list2, propName);
}

export function getUpdatedList(updatedObj, list, op = "update") {
  if (op === "delete")
    return list?.filter((item) => item?._id !== updatedObj?._id);
  return list?.map((item) =>
    item?._id === updatedObj?._id ? updatedObj : item
  );
}

export function getUpdateListOfMulti(items, list, op = "update") {
  const idsMap = items.reduce((a, c) => {
    a[c._id] = c;
    return a;
  }, {});
  const keysSet = new Set(Object.keys(idsMap));
  if (op === "delete") return list?.filter((item) => !keysSet.has(item._id));
  return list?.map((item) => (keysSet.has(item._id) ? idsMap[item._id] : item));
}

export const generateDropDownList = (list) =>
  list.map((item) => ({ label: item, value: item }));

export function arrayExists(arr) {
  return arr && arr.length > 0;
}

export const filterCommonObjFromArray = (arr1 = [], arr2 = [], key = "") =>
  arr1.filter((v1) => arr2.some((v2) => v2 === v1[key]));

export const unionOfLists = (list1, list2, key) => {
  const map = new Map();
  list1.forEach((item) => map.set(item[key], item));
  list2.forEach((item) => map.set(item[key], item));
  return Array.from(map.values());
};
