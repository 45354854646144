import { useEffect } from "react";

import DialogV1 from "components/CustomDialog/v1";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SoftButton from "myComponents/SoftButton";
import useFetch from "hooks/useFetch";
import { fetchApplicationCommissionItems } from "APIs/admissions/student";
import store from "store";
import { selectCurrentAcademicYear } from "redux/app/app.selectors";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import SoftTypography from "myComponents/SoftTypography";

const DesignationDialog = ({ open, handleClose }) => {
  const [isLoading, err, res, fetchData, resetFetch] = useFetch(
    fetchApplicationCommissionItems
  );
  const academicYear = useSelector(selectCurrentAcademicYear);

  const info = {
    academic_year: academicYear?.code,
    createdBy: store.get("user").employeeId,
    amt_recieved: false,
    onlyLength: true,
  };
  useEffect(() => {
    if (open) {
      fetchData({ query: info });
    }
    return () => resetFetch();
  }, [open]);

  useEffect(() => {
    if (err) {
      enqueueSnackbar(err?.message || "Failed to fetch data", {
        variant: "error",
      });
    }
  }, [err]);
  return (
    <DialogV1 open={open} onClose={handleClose}>
      <DialogContent fontWeight="bold">
        {isLoading && "Loading..."}
        {err && "No application commissions found"}
        {!isLoading && !err && res && (
          <>
            <SoftTypography
              variant="h5"
              color="success"
              fontWeight="bolder"
              lineHeight={4}
            >
              {res?.message} application commissions Data
            </SoftTypography>
            <SoftTypography variant="h5" fontWeight="bolder" color="info">
              Total Length Is : {res.totalDocs}
            </SoftTypography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SoftButton onClick={handleClose} color="info" variant="contained">
          Close
        </SoftButton>
      </DialogActions>
    </DialogV1>
  );
};

export default DesignationDialog;
