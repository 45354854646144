import store from "store";
import { dynamoDBClient } from "APIs/lambdaConfig";
import baseAxios from "../baseAxios";

export async function createZone(body) {
  try {
    return (await baseAxios.post("/organization/zones", body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchZonesList(params) {
  try {
    const { data } = await baseAxios.get("organization/zones", {
      params: { ...params, instituteId: store.get("instituteId") },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateZone(obj) {
  const { _id, ...rest } = obj;
  try {
    const { data } = await baseAxios.patch(`organization/zones/${_id}`, {
      ...rest,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function reportError(body) {
  try {
    const errorData = {
      userId: body.userId || "unknown",
      createdAt: new Date().toISOString(),
      errorId: `error_${Date.now()}`,
      errorMessage: body.errorMessage || "No message",
      stackTrace: body.stackTrace || "No stack trace",
      url: body.url || "Unknown URL",
      instituteId: body.instituteId || "unknown",
      userAgent: body.userAgent || "unknown",
    };

    const params = {
      TableName: "UIErrorStack",
      Item: errorData,
    };

    await dynamoDBClient.put(params).promise();

    return { success: true, message: "Error logged successfully" };
  } catch (error) {
    return Promise.reject(error);
  }
}
