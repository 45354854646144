import { useSelector, useDispatch } from "react-redux";
// Selectors
import { selectAppDialog } from "redux/app/app.selectors";
// Actions
import { setAppDialog } from "redux/app/app.slice";
// payment actions
import CheckOutDialog from "helpers/AppDialogs/Checkout.v1";
import DynamicFeeAdjustment from "views/Finance/MakePayment/DynamicFeeAdjustment";
// inventory actions
import ManageManufacturersDialog from "views/Inventory/Dialogs/ManageManufacturers";
import ManageBrandsDialog from "views/Inventory/Dialogs/ManageBrands";
import AddAssetGroup from "views/Inventory/Dialogs/AddAssetGroup";
import InventoryAccountDialog from "views/Inventory/Accounts/AccountDialog";
import DesignationDialog from "views/Organization/ChairmanDashBoard/DesignationDialog";

const AppDialogs = () => {
  const dispatch = useDispatch();
  const { dialog } = useSelector(selectAppDialog);

  const handleClose = () => {
    dispatch(
      setAppDialog({
        dialog: "",
        dialogData: {},
      })
    );
  };

  return (
    <>
      {dialog === "CHECK_OUT_V1" && (
        <CheckOutDialog open handleClose={handleClose} />
      )}
      {dialog === "DYNAMIC_FEE_ADJUSTMENT" && (
        <DynamicFeeAdjustment open handleClose={handleClose} />
      )}
      {/* add more */}
      {dialog === "manageManufacturers" && (
        <ManageManufacturersDialog open handleClose={handleClose} />
      )}
      {dialog === "manageBrands" && (
        <ManageBrandsDialog open handleClose={handleClose} />
      )}
      {dialog === "ADD_ASSET_GROUP" && (
        <AddAssetGroup open handleClose={handleClose} />
      )}
      {dialog === "ADD_INVENTORY_ACCOUNT" && (
        <InventoryAccountDialog open handleClose={handleClose} />
      )}
      {dialog === "UPDATE_INVENTORY_ACCOUNT" && (
        <InventoryAccountDialog open handleClose={handleClose} />
      )}
      {dialog === "DESIGNATION_DIALOG" && (
        <DesignationDialog open handleClose={handleClose} />
      )}
      {/* Other components and logic */}
    </>
  );
};

export default AppDialogs;
