import { createSelector } from "@reduxjs/toolkit";

export const selectInventoryState = (state) => state.inventory;

export const selectInventoryItems = createSelector(
  [selectInventoryState],
  (s) => s.inventoryItems
);

export const selectInventoryItemsMap = createSelector(
  [selectInventoryState],
  (s) =>
    (s?.inventoryItems || []).reduce((a, c) => {
      a[c.id] = c;
      return a;
    }, {})
);

export const selectPurchaseOrders = createSelector(
  [selectInventoryState],
  (s) => s.purchaseOrders
);

export const selectPurchaseReceives = createSelector(
  [selectInventoryState],
  (s) => s.purchaseReceives
);

export const selectVendorPayments = createSelector(
  [selectInventoryState],
  (s) => s.vendorPayments
);

export const selectTaxItems = createSelector(
  [selectInventoryState],
  (s) => s.taxItems
);

export const selectUnitsOfMeasurement = createSelector(
  [selectInventoryState],
  (s) => s.unitsOfMeasurement
);

export const selectAssetGroups = createSelector(
  [selectInventoryState],
  (s) => s.assetGroups
);

export const selectCategories = createSelector(
  [selectInventoryState],
  (s) => s.categories
);

export const selectInventoryAccounts = createSelector(
  [selectInventoryState],
  (s) => s.inventoryAccounts
);

export const selectManufacturers = createSelector(
  [selectInventoryState],
  (s) => s.manufacturers
);

export const selectBrands = createSelector(
  [selectInventoryState],
  (s) => s.brands
);

export const selectVendors = createSelector(
  [selectInventoryState],
  (s) => s.vendors
);

export const selectLoading = createSelector(
  [selectInventoryState],
  (s) => s.loading
);

export const selectError = createSelector(
  [selectInventoryState],
  (s) => s.error
);

export const selectInventoryInitialized = createSelector(
  [selectInventoryState],
  (s) => s.initialized
);

export const selectManufacturersMapWithId = createSelector(
  [selectManufacturers],
  (manufacturers) =>
    manufacturers.reduce((a, c) => {
      a[c._id] = c;
      return a;
    }, {})
);

export const selectBrandsMapWithId = createSelector([selectBrands], (brands) =>
  brands.reduce((a, c) => {
    a[c._id] = c;
    return a;
  }, {})
);

export const selectInventoryLoading = createSelector(
  [selectInventoryState],
  (s) => s.loading
);
