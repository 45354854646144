// React and related libraries
import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import Spinner from "components/Spinner";
import SetMPIN from "views/SignIn/SetMPIN";
import ForgotMPIN from "views/SignIn/ForgotMPIN";
import LoginRoute from "routes/LoginRoute";
import PrivateRoutes from "routes/PrivateRoutes";
import NotFound from "views/NotFound";
import ChangePassword from "views/SignIn/changePwd";
import SignIn from "views/SignIn/singIn";
import SiteRelocationNotice from "common/RelocationNotice";
import HtmlPage from "components/HtmlPage";
// import MaintenanceNotice from "common/MaintenanceNotice";

// Redux actions
import { intializeIndexedDb } from "redux/app/app.slice";

// Config
import { RELOCATE } from "config/globalConsts";
// Lazy-loaded components
const ForgotPassword = lazy(() => import("../views/ForgotPassword/v1"));
const FullPaper = lazy(() => import("../helpers/ViewTestPaper/FullTestPaper"));
const CumulativeReport = lazy(() =>
  import("../views/Analysis/StudentOverallResult/CumulativeReportView")
);
const OMRScannerRedirect = lazy(() =>
  import("../views/OfflineAnalysis/OMRScanner/OMRScannerRedirect")
);
const InvoiceBox = lazy(() =>
  import("../views/QRCodeReciptPage/qcodeBalancePage")
);
const ViewXlsx = lazy(() => import("../helpers/ViewXlsx"));

const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(intializeIndexedDb());
  }, [dispatch]);

  // Handle site relocation if applicable
  if (RELOCATE[window.location.hostname]) {
    return <SiteRelocationNotice />;
  }

  // if (window.location.hostname === "tirumalaadmin.onesaz.com") {
  //   return <MaintenanceNotice />;
  // }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/sign-in" />} />
      <Route
        path="sign-in"
        element={
          <LoginRoute>
            <SignIn />
          </LoginRoute>
        }
      />
      <Route
        path="change-password"
        element={
          <LoginRoute>
            <ChangePassword />
          </LoginRoute>
        }
      />
      <Route path="set-mpin" element={<SetMPIN />} />
      <Route path="forgot-mpin" element={<ForgotMPIN />} />
      <Route
        path="invoiceBox"
        element={
          <Suspense fallback={<Spinner />}>
            <InvoiceBox />
          </Suspense>
        }
      />
      <Route
        path="fullpaper"
        element={
          <Suspense fallback={<Spinner />}>
            <FullPaper />
          </Suspense>
        }
      />
      <Route
        path="cumulativeView"
        element={
          <Suspense fallback={<Spinner />}>
            <CumulativeReport />
          </Suspense>
        }
      />
      <Route
        path="OMRScannerApp"
        element={
          <Suspense fallback={<Spinner />}>
            <OMRScannerRedirect />
          </Suspense>
        }
      />
      <Route
        path="forgot-password"
        element={
          <Suspense fallback={<Spinner />}>
            <LoginRoute>
              <ForgotPassword />
            </LoginRoute>
          </Suspense>
        }
      />
      <Route
        path="quick-links"
        element={
          <Suspense fallback={<Spinner />}>
            <HtmlPage />
          </Suspense>
        }
      />
      <Route
        path="view-xlsx"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewXlsx />
          </Suspense>
        }
      />
      {PrivateRoutes()}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
