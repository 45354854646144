import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

// utils
import { objectExists } from "utils/general.utils/object.utils";

const initialState = {
  group: "ALL",
  state: "ALL",
  tag: window.location.host.includes("srgroup") ? "All" : "ALL",
  status: "ALL",
  fromDate: moment().format("YYYY-MM-DD"),
  toDate: moment().format("YYYY-MM-DD"),
  date: moment().format("YYYY-MM-DD"),
  admissionSummary: {
    TOTAL: "N/A",
    MALE: "N/A",
    FEMALE: "N/A",
  },
  attendanceSummary: {
    TOTAL: "N/A",
    PRESENT: "N/A",
    ABSENT: "N/A",
    NOT_GIVEN: "N/A",
  },
  preAdmissionSummary: {
    ENQUIRES: "N/A",
    ADMISSIONS: "N/A",
    APPLICATIONS: "N/A",
  },
  zoneWiseCollectionsSummary: {},
  branchWiseCollectionsSummary: {},
  zoneWiseAdmissionsSummary: [],
  zoneWisePreAdmissionsSummary: [],
  branchWisePreAdmissionSummary: [],
  branchWiseAdmissionsSummary: [],
  classWiseAdmissionSummary: [],
  classwisePreAdmissionSummary: [],
  transportBusListSummary: [],
  transportRouteListSummary: [],
  campaignCollectionSummary: [],
  campaignBranchWiseCollectionSummary: [],
  // attendance
  zoneWiseAttendanceSummary: [],
  branchWiseAttendanceSummary: [],
  batchWiseAttendanceSummary: [],
  allCampaignAdmissionSummary: [],
  branchWiseCapaignAdmissionSummary: [],
  // dues
  zoneWiseDuesSummary: {},
  branchWiseDuesSummary: {},
  actionInProgress: false,
  admissionsSummaryLastUpdated: null,
  attendanceSummaryLastUpdated: null,
  todaysCollectionLastUpdated: null,
  todaysCollection: null,
  instituteFinanceSummary: {
    TOTAL: 0,
    CONCESSION: 0,
    PAYABLE: 0,
    COLLECTED: 0,
    DUE: 0,
  },
  instituteFinanceSummaryLastUpdated: null,
  activeFianceZone: null,
  preBatchWiseCampaignAdmissionSummary: [], // Add this line
  campaignApplications: [],
};

const summariesSlice = createSlice({
  name: "SUMMARIES",
  initialState,
  reducers: {
    setSummariesFiltersGroup(state, action) {
      state.group = action.payload;
    },
    setSummariesFiltersState(state, action) {
      state.state = action.payload;
    },
    setSummariesFiltersStatus(state, action) {
      state.status = action.payload;
    },
    setSummariesFiltersFromDate(state, action) {
      state.fromDate = action.payload;
    },
    setSummariesFiltersToDate(state, action) {
      state.toDate = action.payload;
    },
    setSummariesFiltersDateRange(state, action) {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    setSummariesFiltersDate(state, action) {
      state.date = action.payload;
    },
    clearSummariesFilters(state) {
      state.group = "ALL";
      state.state = "ALL";
      state.status = "ALL";
      state.fromDate = new Date();
      state.toDate = new Date();
      state.date = new Date();
    },
    setZoneWiseCollectionsSummary(state, action) {
      state.zoneWiseCollectionsSummary = action.payload;
    },
    setTransportBusListSummary(state, action) {
      state.transportBusListSummary = action.payload;
    },
    setTransportRouteListSummary(state, action) {
      state.transportRouteListSummary = action.payload;
    },
    setBranchWiseCollectionsSummary(state, action) {
      state.branchWiseCollectionsSummary = action.payload;
    },
    setZoneWiseAdmissionsSummary(state, action) {
      state.zoneWiseAdmissionsSummary = action.payload;
    },
    setZoneWisePreAdmissionsSummary(state, action) {
      state.zoneWisePreAdmissionsSummary = action.payload;
    },
    setBranchWiseAdmissionsSummary(state, action) {
      state.branchWiseAdmissionsSummary = action.payload;
    },
    setZoneWiseAttendanceSummary(state, action) {
      state.zoneWiseAttendanceSummary = action.payload;
    },
    setBranchWiseAttendanceSummary(state, action) {
      state.branchWiseAttendanceSummary = action.payload;
    },
    setBatchWiseAttendanceSummary(state, action) {
      state.batchWiseAttendanceSummary = action.payload;
    },
    setZoneWiseDuesSummary(state, action) {
      state.zoneWiseDuesSummary = action.payload;
    },
    setClassWiseAdmissionSummary(state, action) {
      state.classWiseAdmissionSummary = action.payload;
    },
    setBranchWiseDuesSummary(state, action) {
      state.branchWiseDuesSummary = action.payload;
    },
    setActionInProgress(state, action) {
      state.actionInProgress = action.payload;
    },
    setAdmissionsSummaryLastUpdated(state, action) {
      state.admissionsSummaryLastUpdated = action.payload;
    },
    setAttendanceSummaryLastUpdated(state, action) {
      state.attendanceSummaryLastUpdated = action.payload;
    },
    setBranchWisePreAdmissionsSummary(state, action) {
      state.branchWisePreAdmissionSummary = action.payload;
    },
    setAllCampaignAdmissionSummary(state, action) {
      state.allCampaignAdmissionSummary = action.payload;
    },
    setBranchWiseCampaignAdmissionsSummary(state, action) {
      state.branchWiseCapaignAdmissionSummary = action.payload;
    },
    setClasswisePreAdmissionSummary(state, action) {
      state.classwisePreAdmissionSummary = action.payload;
    },
    setPreBatchWiseCampaignAdmissionSummary(state, action) {
      state.preBatchWiseCampaignAdmissionSummary = action.payload;
    },
    setAdmissionsSummary(state, action) {
      if (
        action.payload?.admissionSummary &&
        objectExists(action.payload?.admissionSummary)
      ) {
        state.admissionSummary = action.payload?.admissionSummary;
      }
      if (
        action.payload?.preAdmissionSummary &&
        objectExists(action.payload?.preAdmissionSummary)
      ) {
        state.preAdmissionSummary = action.payload?.preAdmissionSummary;
      }
    },
    setAttendanceSummary(state, action) {
      if (action.payload && objectExists(action.payload)) {
        state.attendanceSummary = action.payload;
      }
    },
    setTodaysCollectionLastUpdated(state, action) {
      state.todaysCollectionLastUpdated = action.payload;
    },
    setTodaysCollection(state, action) {
      if (action.payload) {
        state.todaysCollection = action.payload;
      }
    },
    setInstituteFinanceSummary(state, action) {
      if (action.payload) {
        state.instituteFinanceSummary = action.payload;
        state.instituteFinanceSummaryLastUpdated = new Date();
      }
    },
    setInstituteFinanceSummaryLastUpdated(state, action) {
      state.instituteFinanceSummaryLastUpdated = action.payload;
    },
    setActiveFinanceZone(state, action) {
      state.activeFianceZone = action.payload;
    },
    setSummariesFiltersTag(state, action) {
      state.tag = action.payload;
    },
    setCampaignApplications(state, action) {
      state.campaignApplications = action.payload;
    },
    setCampaignCollectionsSummary(state, action) {
      state.campaignCollectionSummary = action.payload;
    },
    setCampaignBranchWiseCollectionSummary(state, action) {
      state.campaignBranchWiseCollectionSummary = action.payload;
    },
  },
});

export const {
  setSummariesFiltersGroup,
  setSummariesFiltersState,
  setSummariesFiltersStatus,
  setSummariesFiltersFromDate,
  setSummariesFiltersToDate,
  setSummariesFiltersDate,
  clearSummariesFilters,
  setZoneWiseCollectionsSummary,
  setBranchWiseCollectionsSummary,
  setZoneWiseAdmissionsSummary,
  setBranchWiseAdmissionsSummary,
  setZoneWiseAttendanceSummary,
  setBranchWiseAttendanceSummary,
  setSummariesFiltersDateRange,
  setZoneWisePreAdmissionsSummary,
  zoneWisePreAdmissionsSummary,
  setBranchWisePreAdmissionsSummary,
  setClassWiseAdmissionSummary,
  setClasswisePreAdmissionSummary,
  setActionInProgress,
  setAdmissionsSummaryLastUpdated,
  setAdmissionsSummary,
  setAttendanceSummaryLastUpdated,
  setAttendanceSummary,
  setTodaysCollectionLastUpdated,
  setTodaysCollection,
  setInstituteFinanceSummary,
  setInstituteFinanceSummaryLastUpdated,
  setActiveFinanceZone,
  setZoneWiseDuesSummary,
  setBranchWiseDuesSummary,
  setBatchWiseAttendanceSummary,
  setTransportBusListSummary,
  setTransportRouteListSummary,
  setSummariesFiltersTag,
  setAllCampaignAdmissionSummary,
  setBranchWiseCampaignAdmissionsSummary,
  setPreBatchWiseCampaignAdmissionSummary,
  setCampaignApplications,
  setCampaignCollectionsSummary,
  setCampaignBranchWiseCollectionSummary,
} = summariesSlice.actions;

export default summariesSlice.reducer;
