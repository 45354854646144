import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// SELECTORS
import { selectBranchesMap } from "redux/organization/branches/branches.selectors";
import { selectBatchesMap } from "redux/organization/batches/batches.selectors";
import { selectZonesMap } from "redux/organization/zones/zones.selectors";
import { selectAcademicYears } from "redux/organization/academicYear/academicYear.selectors";
// consts
import { FIELD_NAMES, SELECT_TYPES } from "config/globalConsts";
import { objectExists } from "utils/general.utils/object.utils";
import { startCase } from "lodash";

const useUrlQueryParams = ({ values, setValues, fields }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const branchesMap = useSelector(selectBranchesMap);
  const batchesMap = useSelector(selectBatchesMap);
  const zonesMap = useSelector(selectZonesMap);
  const academicYearMap = useSelector(selectAcademicYears);

  const { pathname } = location;

  const academicYearObj = useMemo(
    () => Object.fromEntries(academicYearMap.map((item) => [item.code, item])),
    [academicYearMap]
  );

  const typesMap = useMemo(
    () =>
      fields.reduce((acc, curr) => {
        acc[curr.field] = curr.selectType;
        return acc;
      }, {}),
    [fields]
  );
  // get branch from url and populate in values
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const myParams = Object.fromEntries(query);
    const {
      branches = "",
      branch = "",
      batches = "",
      batch = "",
      zones = "",
      zone = "",
      academicYear = "",
    } = myParams;

    // PHASE
    const phaseValue = query.get("phase");

    if (phaseValue) {
      setValues((prev) => ({
        ...prev,
        [FIELD_NAMES.PHASE]: {
          value: phaseValue,
          label1: startCase(phaseValue),
          label2: `${startCase(phaseValue)}`,
        },
      }));
    }
    // ACADEMIC YEAR
    if (
      typesMap[FIELD_NAMES.ACADEMIC_YEAR] === SELECT_TYPES.SINGLE &&
      objectExists(academicYearObj)
    ) {
      const myAcademicYear = academicYearObj[academicYear];
      setValues((prev) => ({
        ...prev,
        [FIELD_NAMES.ACADEMIC_YEAR]:
          myAcademicYear || prev[FIELD_NAMES.ACADEMIC_YEAR] || "",
      }));
    }

    // BRANCH MULTI
    if (
      branches &&
      typesMap[FIELD_NAMES.BRANCH] === SELECT_TYPES.MULTI &&
      objectExists(branchesMap)
    ) {
      const myBranches = branches
        .split(",")
        .filter((item) => !!item)
        .map((item) => branchesMap[item])
        .filter((item) => !!item);
      setValues((prev) => ({
        ...prev,
        [FIELD_NAMES.BRANCH]: myBranches,
      }));
    }

    // BRANCH SINGLE
    if (
      typesMap[FIELD_NAMES.BRANCH] === SELECT_TYPES.SINGLE &&
      objectExists(branchesMap)
    ) {
      const myBranch = branchesMap[branch];

      setValues((prev) => ({
        ...prev,
        [FIELD_NAMES.BRANCH]: myBranch || prev[FIELD_NAMES.BRANCH] || "", // Keep previous value if missing
      }));
    }
    // BATCH MULTI
    if (
      batches &&
      typesMap[FIELD_NAMES.BATCH] === SELECT_TYPES.MULTI &&
      objectExists(batchesMap)
    ) {
      const myBatches = batches
        .split(",")
        .filter((item) => !!item)
        .map((item) => batchesMap[item])
        .filter((item) => !!item);
      setValues((prev) => ({
        ...prev,
        [FIELD_NAMES.BATCH]: myBatches,
      }));
    }

    // BATCH SINGLE
    if (
      typesMap[FIELD_NAMES.BATCH] === SELECT_TYPES.SINGLE &&
      objectExists(batchesMap)
    ) {
      const myBatch = batchesMap[batch];

      setValues((prev) => ({
        ...prev,
        // [FIELD_NAMES.BATCH]: myBatch,
        [FIELD_NAMES.BATCH]: myBatch || prev[FIELD_NAMES.BATCH] || "",
      }));
    }
    // ZONE MULTI
    if (
      zones &&
      typesMap[FIELD_NAMES.ZONE] === SELECT_TYPES.MULTI &&
      objectExists(zonesMap)
    ) {
      const myZones = zones
        .split(",")
        .filter((item) => !!item)
        .map((item) => zonesMap[item])
        .filter((item) => !!item);
      setValues((prev) => ({
        ...prev,
        [FIELD_NAMES.ZONE]: myZones,
      }));
    }

    // ZONE SINGLE
    if (
      typesMap[FIELD_NAMES.ZONE] === SELECT_TYPES.SINGLE &&
      objectExists(zonesMap)
    ) {
      const myZone = zonesMap[zone];

      setValues((prev) => ({
        ...prev,
        [FIELD_NAMES.ZONE]: myZone || prev[FIELD_NAMES.ZONE] || "",
      }));
    }
  }, [location.search]);

  useEffect(() => {
    const myParams = new URLSearchParams(location.search); // Get current URL params

    // Branch multi
    if (
      values[FIELD_NAMES.BRANCH] &&
      typesMap[FIELD_NAMES.BRANCH] === SELECT_TYPES.MULTI
    ) {
      if (values[FIELD_NAMES.BRANCH].length > 0) {
        myParams.set(
          "branches",
          values[FIELD_NAMES.BRANCH].map((item) => item.branchCode).join(",")
        );
      } else {
        myParams.delete("branches");
      }
    }
    //  SINGLE

    if (
      values[FIELD_NAMES.BRANCH] &&
      typesMap[FIELD_NAMES.BRANCH] === SELECT_TYPES.SINGLE
    ) {
      const branchValue = values[FIELD_NAMES.BRANCH];

      // Ensure branchValue is always an array
      const branchArray = Array.isArray(branchValue)
        ? branchValue
        : [branchValue];

      myParams.set(
        "branch",
        branchArray.map((item) => item?.branchCode).join(",")
      );
    } else {
      myParams.delete("branch");
    }

    // BATCH MULTI
    if (
      values[FIELD_NAMES.BATCH] &&
      typesMap[FIELD_NAMES.BATCH] === SELECT_TYPES.MULTI
    ) {
      if (values[FIELD_NAMES.BATCH].length > 0) {
        myParams.set(
          "batches",
          values[FIELD_NAMES.BATCH].map((item) => item.batchId).join(",")
        );
      } else {
        myParams.delete("batches");
      }
    }

    // BATCH SINGLE
    if (
      values[FIELD_NAMES.BATCH] &&
      typesMap[FIELD_NAMES.BATCH] === SELECT_TYPES.SINGLE
    ) {
      const batchvalue = values[FIELD_NAMES.BATCH];
      const batchArray = Array.isArray(batchvalue) ? batchvalue : [batchvalue];

      myParams.set("batch", batchArray.map((item) => item.batchId).join(","));
    } else {
      myParams.delete("batch");
    }
    // ZONE MUTLTI
    if (
      values[FIELD_NAMES.ZONE] &&
      typesMap[FIELD_NAMES.ZONE] === SELECT_TYPES.MULTI
    ) {
      if (values[FIELD_NAMES.ZONE].length > 0) {
        myParams.set(
          "zones",
          values[FIELD_NAMES.ZONE].map((item) => item.zoneCode).join(",")
        );
      } else {
        myParams.delete("zones");
      }
    }
    // ZONE SINGLE
    if (
      values[FIELD_NAMES.ZONE] &&
      typesMap[FIELD_NAMES.ZONE] === SELECT_TYPES.SINGLE
    ) {
      const zonevalue = values[FIELD_NAMES.ZONE];
      const zoneArray = Array.isArray(zonevalue) ? zonevalue : [zonevalue];
      myParams.set("zone", zoneArray.map((item) => item.zoneCode).join(","));
    } else {
      myParams.delete("zone");
    }
    // Acadmic year
    if (
      values[FIELD_NAMES.ACADEMIC_YEAR] &&
      typesMap[FIELD_NAMES.ACADEMIC_YEAR] === SELECT_TYPES.SINGLE
    ) {
      const academicvalue = values[FIELD_NAMES.ACADEMIC_YEAR];
      const academicArray = Array.isArray(academicvalue)
        ? academicvalue
        : [academicvalue];
      myParams.set(
        "academicYear",
        academicArray.map((item) => item.code).join(",")
      );
    } else {
      myParams.delete("academicYear");
    }
    // PHASE
    if (
      values[FIELD_NAMES.PHASE] &&
      typesMap[FIELD_NAMES.PHASE] === SELECT_TYPES.PHASE
    ) {
      myParams.set("phase", values[FIELD_NAMES.PHASE]?.value || "");
    } else {
      myParams.delete("phase");
    }

    // Convert params to string
    const newSearch = myParams.toString();
    const newUrl = newSearch ? `${pathname}?${newSearch}` : pathname;

    const MAX_URL_LENGTH = 2048;

    if (newUrl.length > MAX_URL_LENGTH) {
      enqueueSnackbar(
        "URL exceeds maximum allowed length. Please reduce the number of selected items.",
        { variant: "error" }
      );
    } else if (newUrl !== window.location.pathname + window.location.search) {
      navigate(newUrl, { replace: true });
    }
  }, [values, pathname, navigate]);

  return { values, setValues };
};
export default useUrlQueryParams;

/*
// if (Object.keys(myParams).length) {
    //   navigate(`${pathname}?${new URLSearchParams(myParams).toString()}`, {
    //     replace: true,
    //   });
    // }
    // }, [values]);
*/
