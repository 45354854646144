import { lazy } from "react";
import flatten from "lodash/flatten";
// icons
// import flatten from "lodash/flatten";
// import features from "config/features";
import EmojiTransportationRoundedIcon from "@mui/icons-material/EmojiTransportationRounded";
import features from "config/features";
import Container from "../../layout/simpleContainer";

const TransportDashboard = lazy(() =>
  import("../../views/transport/Dashboard")
);
const Buses = lazy(() => import("../../views/transport/Buses/BusesList"));

const FuelList = lazy(() => import("../../views/transport/Buses/FuelList"));
const RepairList = lazy(() => import("../../views/transport/Buses/RepairList"));
const AddEditRepair = lazy(() =>
  import("../../views/transport/Buses/AddEditRepair")
);
const FitnessList = lazy(() =>
  import("../../views/transport/Buses/FitnessList")
);
const AddEditFitness = lazy(() =>
  import("../../views/transport/Buses/AddEditFitness")
);
const MaintenanceList = lazy(() =>
  import("../../views/transport/Buses/MaintenanceList")
);
const MaintenanceForm = lazy(() =>
  import("../../views/transport/Buses/forms/MaintenanceForm")
);

const Stops = lazy(() => import("../../views/transport/Stops"));
const AssignBusesToRoute = lazy(() =>
  import("../../views/transport/Buses/AssignBusesToRoute")
);
const Renewals = lazy(() => import("../../views/transport/Buses/Renewals"));
const FuelStations = lazy(() =>
  import("../../views/transport/Buses/FuelStations")
);
const AddEditFuel = lazy(() =>
  import("../../views/transport/Buses/AddEditFuel")
);
const AssignStaffToBus = lazy(() =>
  import("../../views/transport/Staff/AssignStaffToBus")
);
const AssignStopsToRoute = lazy(() =>
  import("../../views/transport/Stops/AssignStopsToRoute")
);
const TransPortFeeManagement = lazy(() => import("../../views/transport/Fees"));
const AddEditBus = lazy(() => import("../../views/transport/Buses/AddEditBus"));
const BusCheckListForm = lazy(() =>
  import("../../views/transport/Buses/BusCheckListForm")
);
const MaintenanceSheet = lazy(() =>
  import("../../views/transport/Buses/MaintenanceSheetList")
);
const MaintenanceSheetForm = lazy(() =>
  import("../../views/transport/Buses/forms/MaintenanceSheet")
);
const VehicleLog = lazy(() =>
  import("../../views/transport/Buses/VehicleLogList")
);
const VehicleLogForm = lazy(() =>
  import("../../views/transport/Buses/forms/VehicleLogForm")
);
const UploadRenewalFiles = lazy(() =>
  import("../../views/transport/Buses/forms/UploadRenewalFiles")
);

export default {
  component: <Container />,
  title: "Transport",
  basePath: "app",
  path: "Transport-management",
  icon: <EmojiTransportationRoundedIcon />,
  features: [...flatten(Object.values(features.transport))],
  children: [
    {
      component: <TransportDashboard />,
      title: "Dashboard",
      basePath: "Transport-management",
      path: "transport_dashboard",
      features: ["dashboard_list"],
    },
    {
      component: <Container />,
      index: <Buses />,
      title: "Buses",
      basePath: "Transport-management",
      path: "buses",
      features: [
        ...features.transport.buses,
        ...features.transport.renewals,
        ...features.transport.assign_buses_to_route,
        ...features.transport.filling_stations,
        ...features.transport.fuel_details,
        ...features.transport.repair_breakdowns,
        ...features.transport.fitness,
        ...features.transport.maintenance_history,
        ...features.transport.bus_check_list,
        ...features.transport.maintenance_sheet,
        ...features.transport.vehicle_log,
      ],
      children: [
        {
          component: <AddEditBus />,
          title: "Create Bus",
          basePath: "buses",
          path: "create-bus",
          features: ["create_bus"],
        },
        {
          component: <AddEditBus />,
          title: "Edit Bus",
          basePath: "buses",
          path: "edit/:id",
          features: ["update_bus"],
        },
        {
          component: <Container />,
          index: <FuelList />,
          title: "Fuel Details",
          basePath: "buses",
          path: "fuel-details",
          features: [
            ...features.transport.filling_stations,
            ...features.transport.fuel_details,
          ],
          children: [
            {
              component: <FuelStations />,
              title: "Fuel Stations",
              basePath: "fuel-details",
              path: "fuel-stations",
              features: ["filling_station_list"],
            },
            {
              component: <AddEditFuel />,
              title: "Fuel Stations",
              basePath: "fuel-details",
              path: "fuel-form",
              features: ["create_fuel_detail"],
            },
            {
              component: <AddEditFuel />,
              title: "Fuel Stations",
              basePath: "fuel-details",
              path: "edit/:id",
              features: ["update_fuel_detail"],
            },
          ],
        },
        {
          component: <Container />,
          index: <RepairList />,
          title: "Repair & Breakdowns",
          basePath: "buses",
          path: "repair-breakdowns",
          features: [...features.transport.repair_breakdowns],
          children: [
            {
              component: <AddEditRepair />,
              title: "Repairs & BreakDowns",
              basePath: "repair-breakdowns",
              path: "repair-form",
              features: ["create_repair"],
            },
            {
              component: <AddEditRepair />,
              title: "Repairs & BreakDowns",
              basePath: "repair-breakdowns",
              path: "edit/:id",
              features: ["update_repair"],
            },
          ],
        },
        {
          component: <Container />,
          index: <FitnessList />,
          title: "Fitness Details",
          basePath: "buses",
          path: "fitness-details",
          features: [...features.transport.fitness],
          children: [
            {
              component: <AddEditFitness />,
              title: "Fitness Form",
              basePath: "fitness-details",
              path: "fitness-form",
              features: ["create_fitness"],
            },
            {
              component: <AddEditFitness />,
              title: "Fitness Form",
              basePath: "fitness-details",
              path: "edit/:id",
              features: ["update_fitness"],
            },
          ],
        },
        {
          component: <Container />,
          index: <MaintenanceList />,
          title: "Maintenance History",
          basePath: "buses",
          path: "maintenance-history",
          features: [...features.transport.maintenance_history],
          children: [
            {
              component: <MaintenanceForm />,
              title: "Maintenance Form",
              basePath: "maintenance-history",
              path: "maintenance-form",
              features: ["create_maintenance", "update_maintenance"],
            },
          ],
        },
        {
          component: <Container />,
          index: <MaintenanceSheet />,
          title: "Maintenance Sheet",
          basePath: "buses",
          path: "maintenance-sheet",
          features: [...features.transport.maintenance_sheet],
          children: [
            {
              component: <MaintenanceSheetForm />,
              title: "Maintenance Sheet Form",
              basePath: "maintenance-sheet_form",
              path: "maintenance-sheet-form",
              features: [
                "create_maintenance_sheet",
                "update_maintenance_sheet",
              ],
            },
          ],
        },
        {
          component: <Container />,
          index: <VehicleLog />,
          title: "Vehicle Log",
          basePath: "buses",
          path: "vehicle-logs",
          features: [...features.transport.vehicle_log],
          children: [
            {
              component: <VehicleLogForm />,
              title: "Vehicle Log Form",
              basePath: "vehicle-logs",
              path: "vehicle-log-form",
              features: ["create_vehicle_log", "update_vehicle_log"],
            },
          ],
        },
        {
          component: <BusCheckListForm />,
          title: "Bus Check List",
          basePath: "buses",
          path: "bus-check-list",
          features: [...features.transport.bus_check_list],
        },
        {
          component: <AssignBusesToRoute />,
          title: "AssignBusesToRoute",
          basePath: "buses",
          path: "assign-buses-to-routes",
          features: ["assign_buses_to_route"],
        },
        {
          component: <Container />,
          index: <Renewals />,
          title: "Renewals",
          basePath: "buses",
          path: "renewals",
          features: ["renewals_list"],
          children: [
            {
              component: <UploadRenewalFiles />,
              title: "Upload Renewal Files",
              basePath: "renewals",
              path: "upload-renewals-files",
              features: [],
            },
          ],
        },
      ],
    },
    {
      component: <Container />,
      title: "Routes",
      basePath: "Transport-management",
      path: "routes",
      index: <AssignStopsToRoute />,
      features: [
        ...features.transport.routes,
        ...features.transport.assign_stop_to_route,
      ],
      children: [
        {
          component: <AssignBusesToRoute />,
          title: "AssignBusesToRoute",
          basePath: "buses",
          path: "assign-buses-to-routes",
          features: ["assign_buses_to_route"],
        },
      ],
    },
    {
      component: <Stops />,
      title: "Stops",
      basePath: "Transport-management",
      path: "stops",
      features: [...features.transport.stops],
    },
    {
      component: <AssignStaffToBus />,
      title: "Staff",
      basePath: "Transport-management",
      path: "staff",
      features: [...features.transport.assign_staff_to_bus],
    },
    {
      component: <TransPortFeeManagement />,
      title: "Fees",
      basePath: "Transport-management",
      path: "fees",
      features: [...features.transport.fees],
    },
  ],
};
