import { useEffect, useRef } from "react";
import dayjs from "dayjs";
// MUI
import Grid from "@mui/material/Grid";
// myComponents
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import SoftInput from "myComponents/SoftInput";
// hooks
import useMyPrivileges from "hooks/useMyPrivileges";

const CheckPaymentForm = ({ values, setValues }) => {
  const { allow_past_transaction_entry } = useMyPrivileges([
    "allow_past_transaction_entry",
  ]);
  const today = dayjs();
  const startOfToday = today.startOf("day").format("YYYY-MM-DD");
  const chequeDateRef = useRef(null);

  useEffect(() => {
    if (chequeDateRef.current) {
      chequeDateRef.current.setAttribute("max", today.format("YYYY-MM-DD"));
      if (!allow_past_transaction_entry) {
        chequeDateRef.current.setAttribute("min", startOfToday);
      }
    }
  }, [allow_past_transaction_entry, today, startOfToday]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <SoftBox p={1} ml={3} pb={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SoftBox maxWidth={300}>
            <SoftTypography
              variant="caption"
              fontWeight="bold"
              color="secondary"
              display="block"
              mb={0.2}
              ml={0.2}
            >
              Cheque Date
            </SoftTypography>
            <SoftInput
              type="date"
              name="chequeDate"
              size="small"
              fullWidth
              value={values.chequeDate}
              onChange={handleChange}
              inputRef={chequeDateRef}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftBox maxWidth={300}>
            <SoftTypography
              variant="caption"
              fontWeight="bold"
              color="secondary"
              display="block"
              mb={0.2}
              ml={0.2}
            >
              Cheque No
            </SoftTypography>
            <SoftInput
              name="chequeNo"
              size="small"
              fullWidth
              value={values.chequeNo}
              onChange={handleChange}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftBox maxWidth={300}>
            <SoftTypography
              variant="caption"
              fontWeight="bold"
              color="secondary"
              display="block"
              mb={0.2}
              ml={0.2}
            >
              IFSC
            </SoftTypography>
            <SoftInput
              name="ifsc"
              size="small"
              fullWidth
              value={values.ifsc}
              onChange={handleChange}
            />
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
};

export default CheckPaymentForm;
