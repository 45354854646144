import flatten from "lodash/flatten";
// icons
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
// Helpers
import lazy from "helpers/LazyImport";
// body layout
import Container from "layout/simpleContainer";
// features
import features from "config/features";

// roles

const Entries = lazy(() => import("../../views/Admissions/Entries"));
const StudentDisplay = lazy(() =>
  import("../../views/Admissions/StudentDisplay")
);

const AssignCounsellorToEnquries = lazy(() =>
  import("../../views/Admissions/AssignCounsellor")
);
const FollowUps = lazy(() => import("../../views/Admissions/FollowUp"));
const Registration = lazy(() => import("../../views/Admissions/Registration"));

const ManageSections = lazy(() =>
  import("../../views/Admissions/ManageSections")
);
const AddStrength = lazy(() =>
  import("../../views/Admissions/ManageSections/AddStrength")
);
const StudentAdmission = lazy(() =>
  import("../../views/Admissions/Registration/admission")
);
// const StudentApplication = lazy(() =>
//   import("../../views/Admissions/Registration/application")
// );
const StudentApplication = lazy(() =>
  import("../../views/Admissions/Registration/Application/index")
);
const StudentEnquiry = lazy(() =>
  import("../../views/Admissions/Registration/enquiries")
);
const ApplicationsOverview = lazy(() =>
  import("../../views/Admissions/ApplicationsOverview")
);
const StudentUpload = lazy(() => import("../../helpers/StudentUpload"));

const Active = lazy(() => import("../../helpers/StudentUpload/active"));

const StatusApprovals = lazy(() =>
  import("../../views/Admissions/StatusApprovals")
);
const AdmissionCancellation = lazy(() =>
  import("../../views/Admissions/AdmissionCancellation")
);
const RequestAdmissionCancellation = lazy(() =>
  import("../../views/Admissions/RequestAdmissionCancellation")
);
const TransferApprovals = lazy(() =>
  import("../../views/Admissions/TransferApprovals")
);
const StudentPromotion = lazy(() =>
  import("../../views/Admissions/StudentPromotion")
);
// const StudentPerformance = lazy(() =>
//   import("../../views/Analysis/StudentPerformance")
// );
const AssignStudentsToEmployess = lazy(() =>
  import("views/Admissions/AssignStudentsToEmployees")
);
const Reports = lazy(() => import("views/Admissions/Reports"));
const NewAdmissionReport = lazy(() =>
  import("views/Admissions/Reports/NewAdmissionReport")
);
const TransportConfirmationDialog = lazy(() =>
  import("views/Admissions/StudentDisplay/TransportConfirmationDialog")
);
const BatchWiseAdmissionReport = lazy(() =>
  import("views/Admissions/Reports/BatchWiseSummaryReport")
);

export default {
  component: <Container />,
  title: "Admissions",
  basePath: "app",
  path: "admissions",
  icon: <SupervisorAccountRoundedIcon />,
  features: flatten(Object.values(features.admissions)),
  children: [
    {
      component: <Active />,
      basePath: "admissions",
      path: "Active",
      noUiLink: true,
      features: ["bulk_Upgrade"],
    },
    {
      component: <StudentUpload />,
      title: "Bulk Creation",
      basePath: "admissions",
      path: "bulk-creation",
      features: ["bulk_registrations"],
      noUiLink: true,
    },
    {
      component: <StudentUpload />,
      title: "Bulk Creation",
      basePath: "admissions",
      path: "bulk-creation",
      features: ["bulk_registrations"],
      noUiLink: true,
    },
    {
      component: <StudentUpload />,
      title: "Bulk Update",
      basePath: "admissions",
      path: "bulk-update",
      features: ["bulk_update"],
      noUiLink: true,
    },

    {
      component: <Entries />,
      title: "Enrollment Records",
      basePath: "admissions",
      path: "records",
      features: [
        "view_students",
        "single_registration",
        "new_application",
        "manual_ApplicationNo",
        "manual_AdmissionNo",
        "admission_settings",
      ],
    },
    {
      component: <Registration />,
      title: "Registration",
      basePath: "admissions",
      path: "registration",
      features: [
        "single_registration",
        "manual_ApplicationNo",
        "manual_AdmissionNo",
      ],
      noUiLink: true,
    },
    {
      component: <StudentAdmission />,
      title: "admission",
      basePath: "admissions",
      path: "admission",
      features: [
        "single_registration",
        "manual_ApplicationNo",
        "manual_AdmissionNo",
      ],
      noUiLink: true,
    },
    {
      component: <StudentEnquiry />,
      title: "admission",
      basePath: "admissions",
      path: "enquiry",
      features: ["new_enquiry"],
      noUiLink: true,
    },
    {
      component: <StudentApplication />,
      title: "application",
      basePath: "admissions",
      path: "application",
      features: [
        "new_application",
        "manual_ApplicationNo",
        "manual_AdmissionNo",
        "aadhar_verification",
      ],
      noUiLink: true,
    },

    {
      component: <AssignStudentsToEmployess />,
      title: "Assign Students To Employees",
      basePath: "admissions",
      path: "assign-to-employees",
      features: [
        "assign_counsellor",
        "assign_members",
        "assign_memebrs_&_submembers",
      ],
    },

    {
      component: <AssignCounsellorToEnquries />,
      title: "Assign Counsellor",
      basePath: "admissions",
      path: "assign-counsellor",
      features: ["assign_counsellor"],
    },
    {
      component: <FollowUps />,
      title: "Follow Up",
      basePath: "admissions",
      path: "follow-up",
      features: ["followups"],
    },
    {
      component: <Container />,
      title: "Manage Sections",
      index: <ManageSections />,
      basePath: "admissions",
      path: "manage-sections",
      features: ["sections_read", "sections_write"],
      children: [
        {
          component: <AddStrength />,
          title: "Add Strength to sections",
          basePath: "manage-sections",
          path: "add-strength",
          features: ["sections_write"],
        },
      ],
    },
    {
      component: <Container />,
      index: <StudentDisplay />,
      title: "Student Profile",
      basePath: "admissions",
      path: "view-student",
      features: ["student_profile"],
      children: [
        {
          component: <TransportConfirmationDialog />,
          title: "Assign Transport to Student Confirmation",
          basePath: "view-student",
          path: "transport-confirmation",
          features: [],
        },
      ],
    },

    {
      component: <TransferApprovals />,
      title: "Transfer Approvals",
      basePath: "admissions",
      path: "transfer-approvals",
      features: features.admissions.transfer_approvals,
    },
    {
      component: <ApplicationsOverview />,
      title: "Incentive Withdrawal",
      basePath: "admissions",
      path: "application-overview",
      features: features.admissions.applications,
    },
    {
      component: <StatusApprovals />,
      title: "Status Approvals",
      basePath: "admissions",
      path: "status-approvals",
      features: features.admissions.status_approvals,
    },
    {
      component: <RequestAdmissionCancellation />,
      title: "Cancel Admission",
      basePath: "admissions",
      path: "cancel-admission",
      features: features.admissions.cancel_admission,
    },
    {
      component: <AdmissionCancellation />,
      title: "Refund Approvals",
      basePath: "admissions",
      path: "refund-approvals",
      features: features.admissions.admission_refund_approvals,
    },
    {
      component: <StudentPromotion />,
      title: "Promote Students",
      basePath: "admissions",
      path: "student-promotion",
      features: features.admissions.student_promotion,
    },

    {
      component: <Container />,
      title: "Reports",
      index: <Reports />,
      basePath: "admissions",
      path: "reports",
      features: features.admissions.admission_reports,
      children: [
        {
          component: <NewAdmissionReport />,
          title: "New Admission Report",
          basePath: "reports",
          path: "new-admission-report",
          features: ["new_admission_report"],
        },
        {
          component: <BatchWiseAdmissionReport />,
          title: "New Admission Report",
          basePath: "reports",
          path: "batch-wise-admission-report",
          features: ["batch-wise-admission-report"],
          noUiLink: true,
        },
      ],
    },
  ],
};
