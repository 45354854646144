import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== "cellBorder",
})(({ rowCount }) => {
  const styles = {
    "& .MuiDataGrid-virtualScroller": {
      ...(!rowCount && { minHeight: 40 }),
      overflowX: "auto",
      "& .MuiDataGrid-virtualScrollerContent": {
        "& .MuiDataGrid-virtualScrollerRenderZone": {
          "& .MuiDataGrid-cell": {
            "& .MuiInputBase-root.MuiDataGrid-editInputCell": {
              height: "95% !important",
              overflowX: "auto",
            },
          },
        },
      },
    },
  };

  return styles;
});

export default StyledDataGrid;
