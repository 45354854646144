import store from "store";
import flatten from "lodash/flatten";
// icon
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
// Helpers
import lazyWithRetry from "helpers/LazyImport";
// body layout
import { lazy } from "react";
import Container from "../../layout/simpleContainer";
// features
import features from "../features";
// modules
const SetUp = lazyWithRetry(() =>
  import("../../views/Organization/InstituteSetUp/Form")
);
const ZonalCreationForm = lazyWithRetry(() =>
  import("../../views/Organization/ZonalCreation/form")
);

const ZonalCreation = lazyWithRetry(() =>
  import("../../views/Organization/ZonalCreation")
);

const UsersManagement = lazyWithRetry(() =>
  import("../../views/Organization/UsersManagement")
);

const BranchCreation = lazyWithRetry(() =>
  import("../../views/Organization/BranchCreation")
);

const CreateBranch = lazyWithRetry(() =>
  import("../../views/Organization/BranchCreation/CreateBranch")
);
const EditBranch = lazyWithRetry(() =>
  import("../../views/Organization/BranchCreation/EditBranch")
);
const BranchDisplay = lazyWithRetry(() =>
  import("../../views/Organization/BranchCreation/BranchDisplay")
);

const BatchCreation = lazyWithRetry(() =>
  import("../../views/Organization/BatchCreation")
);

const CreateUserForm = lazyWithRetry(() =>
  import("../../views/Organization/UsersManagement/components/createUserForm")
);

const CreateRecruter = lazyWithRetry(() =>
  import("../../views/Organization/UsersManagement/components/CreateRecruter")
);
const RoleMgntIndex = lazyWithRetry(() =>
  import("../../views/Organization/UsersManagement/RoleManagement")
);

const SetUpInstitute = lazyWithRetry(() =>
  import("../../views/Organization/InstituteSetUp")
);

// const ApplicationLogs = lazyWithRetry(() => import("../../views/Organization/Logs"));

// const FinanceDashboard = lazyWithRetry(() =>
//   import(
//     "../../views/Organization/ChairmanDashBoard/FeeCollection/OverAllFinanceDetails"
//   )
// );
// const AdmissionsDashboard = lazyWithRetry(() =>
//   import(
//     "../../views/Organization/ChairmanDashBoard/AdmissionsDashboard/overAllAdmissionsDashborad"
//   )
// );
// const AdmissionsOverAllTables = lazyWithRetry(() =>
//   import(
//     "../../views/Organization/ChairmanDashBoard/AdmissionsDashboard/AdmissionsOverAllTable"
//   )
// );

// const AttendanceDashboard = lazyWithRetry(() =>
//   import(
//     "../../views/Organization/ChairmanDashBoard/AttendanceDashboard/OverAllAttendanceDashboard"
//   )
// );
// const CommunicationDashboard = lazyWithRetry(() =>
//   import(
//     "../../views/Organization/ChairmanDashBoard/CommunicationDashboard/OverAllCommunicationDashboard"
//   )
// );

const UserActivity = lazyWithRetry(() =>
  import("../../views/Organization/UserActivity")
);

const SimpleBranchCreation = lazyWithRetry(() =>
  import(
    "../../views/Organization/BranchCreation/SimpleBranch/CreateSimpleBranch"
  )
);

export default {
  component: <Container />,
  title: "Organization",
  basePath: "app",
  path: "organization",
  icon: <CorporateFareRoundedIcon />,
  features: flatten(Object.values(features.organization)),
  children: [
    {
      component: <Container />,
      title: "SetUp Institute",
      basePath: "organization",
      index: <SetUpInstitute />,
      noUiLink: store.get("instituteId") !== "5d679d49c136660a09596d85",
      path: "institute-setup",
      // features: features.organization,
      children: [
        {
          component: <SetUp />,
          title: " Institute create",
          basePath: "institute-setup",
          path: "SetUP",
        },
      ],
    },

    // {
    //   component: <FinanceDashboard />,
    //   title: "Finance Dashboard",
    //   basePath: "app",
    //   noUiLink: true,
    //   path: "finance-dashboard",
    //   features: features.organization.Finance_tab,
    // },
    // {
    //   component: <AdmissionsDashboard />,
    //   title: "Admissions Dashboard",
    //   basePath: "app",
    //   noUiLink: true,
    //   path: "admissions-dashboard",
    //   features: features.organization.admission_tab,
    // },
    // {
    //   component: <AdmissionsOverAllTables />,
    //   title: "Admissions Dashboard",
    //   basePath: "app",
    //   noUiLink: true,
    //   path: "admissions-AcademicYear-dashboard",
    //   features: features.organization.admission_tab,
    // },
    // {
    //   component: <AttendanceDashboard />,
    //   title: "Attendance Dashboard",
    //   basePath: "app",
    //   noUiLink: true,
    //   path: "attendance-dashboard",
    //   features: features.organization.attendance_tab,
    // },
    // {
    //   component: <CommunicationDashboard />,
    //   title: "Communication Dashboard",
    //   basePath: "app",
    //   noUiLink: true,
    //   path: "communication-dashboard",
    //   // features: features.organization.attendance_tab,
    // },
    {
      component: <Container />,
      title: "Zonal Creation",
      basePath: "organization",
      path: "zonal-creation",
      index: <ZonalCreation />,
      features: features.organization.zones,
      children: [
        {
          component: <ZonalCreationForm />,
          title: "Create Zone",
          basePath: "zonal-creation",
          path: "create",
          features: ["zones_write"],
          authorizedRoles: [],
        },
      ],
    },
    {
      component: <BatchCreation />,
      title: "Manage Batches",
      basePath: "organization",
      path: "batches",
      features: features.organization.batches,
    },
    {
      component: <Container />,
      title: "Branch Creation",
      basePath: "organization",
      path: "branch-creation",
      index: <BranchCreation />,
      features: features.organization.branches,
      children: [
        {
          component: <CreateBranch />,
          title: "Create Branch",
          basePath: "branch-creation",
          path: "create",
          features: ["branches_write"],
        },
        {
          component: <EditBranch />,
          title: "Edit Branch",
          basePath: "branch-creation",
          path: "edit",
          features: ["branches_write"],
        },
        {
          component: <BranchDisplay />,
          title: "Display Branch",
          basePath: "branch-creation",
          path: "view",
          features: ["branches_read"],
        },
        {
          component: <SimpleBranchCreation />,
          title: "Simple Branch",
          basePath: "branch-creation",
          path: "simple",
          features: ["branches_write"],
        },
      ],
    },
    {
      component: <Container />,
      title: "User Management",
      basePath: "organization",
      path: "user-management",
      index: <UsersManagement />,
      features: [
        ...features.organization.user_management,
        ...features.organization.role_management,
      ],
      children: [
        {
          component: <CreateUserForm />,
          title: "Create User",
          basePath: "user-management",
          path: "create",
          noUiLink: true,
          features: ["create_pro", "user_write", "create_recruter"],
        },
        {
          component: <CreateUserForm />,
          title: "Employee",
          basePath: "user-management",
          path: "emp",
          noUiLink: true,
          features: ["user_write", "create_pro"],
        },
        {
          component: <CreateRecruter />,
          title: "Employee",
          basePath: "user-management",
          path: "recruter",
          noUiLink: true,
          features: ["user_write", "create_recruter"],
        },
        {
          component: <RoleMgntIndex />,
          title: "Role Management",
          basePath: "user-management",
          path: "manage-roles&privileges",
          noUiLink: true,
          features: features.organization.role_management,
        },
      ],
    },
    {
      component: <UserActivity />,
      title: "User Activity",
      basePath: "organization",
      path: "user-activity",
      features: features.organization.user_activity,
    },
  ],
};
