import { createSlice } from "@reduxjs/toolkit";
// uitils
// import { getUpdatedList } from "../../../utils/general.utils/array.utils";

const initialState = {
  data: null,
  student: null,
  onlineTxnReqInfo: null,
  customer: null,
  reports: [],
  posTerminals: {},
  lastFetchedPosTerminalsAt: null,
  feeTypes: [],
  lastFetchedFeeTypesAt: null,
};

const makePaymentSlice = createSlice({
  name: "MAKE_PAYMENT",
  initialState,
  reducers: {
    fetchStudentDetailsAndTxnHistory() {},
    fetchTxnHistory() {},
    fetchStudentDetailsAndTxnHistorySuccess: (state, action) => {
      state.student = action.payload;
    },
    clearStudentDetailAndTxnHistory: (state) => {
      state.student = null;
    },
    resetMakePayment: (state) => {
      state.data = null;
    },
    startChequeOrCashPayment: (state, action) => {
      state.data = action.payload;
    },
    // chequeOrCashPaymentSuccess: (state, action) => {
    //   state.student = {
    //     ...state.student,
    //     txnHistory: [...(state?.student?.txnHistory || []), action.payload],
    //   };
    // },
    chequeOrCashPaymentSuccess: () => {},
    initiateOnlineTransaction: (state, action) => {
      state.data = action.payload;
    },
    initiateOnlineTxnSuccess: (state, action) => {
      state.onlineTxnReqInfo = action.payload;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    generateTxtnReceipt() {},
    updateTxnStart() {},
    fetchFeeReportStart() {},
    clearFinanceReports(state) {
      state.reports = [];
    },
    fetchFeeReportSuccess(state, action) {
      state.reports = action.payload;
    },
    fetchPosTerminalsStart() {},
    fetchPosTerminalsSuccess(state, action) {
      state.posTerminals = action.payload;
      state.lastFetchedPosTerminalsAt = Date.now();
    },
    fetchFeeTypesStart() {},
    fetchFeeTypesSuccess(state, action) {
      state.feeTypes = action.payload;
      state.lastFetchedFeeTypesAt = Date.now();
    },
  },
});

export const {
  fetchStudentDetailsAndTxnHistory,
  fetchStudentDetailsAndTxnHistorySuccess,
  resetMakePayment,
  startChequeOrCashPayment,
  chequeOrCashPaymentSuccess,
  initiateOnlineTransaction,
  initiateOnlineTxnSuccess,
  fetchTxnHistory,
  setCustomer,
  generateTxtnReceipt,
  updateTxnStart,
  fetchFeeReportStart,
  fetchFeeReportSuccess,
  clearFinanceReports,
  clearStudentDetailAndTxnHistory,
  fetchPosTerminalsStart,
  fetchPosTerminalsSuccess,
  fetchFeeTypesStart,
  fetchFeeTypesSuccess,
} = makePaymentSlice.actions;

export default makePaymentSlice.reducer;
