// import { useState } from "react";
import startCase from "lodash/startCase";
// material ui
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
// myComponents
import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";

export const RequiredLabel = ({
  required,
  name,
  label,
  noHeaderLabel,
  getComponent,
}) => (
  <Stack direction="row">
    {!noHeaderLabel && (
      <SoftBox display="flex">
        <SoftTypography variant="caption" fontWeight="bold">
          {required ? startCase(name || label) : startCase(name || label)}
        </SoftTypography>
      </SoftBox>
    )}

    <SoftTypography variant="caption" color="error" fontWeight="bold">
      {required ? "*" : null}
    </SoftTypography>
    {getComponent && (
      <SoftBox display="flex" ml={0.5}>
        {getComponent && getComponent()}
      </SoftBox>
    )}
  </Stack>
);

export default function SoftAutoCompleteV2({
  options,
  value,
  multiple,
  label,
  filled,
  required = false,
  optionLabelPropName,
  optionValuePropName,
  textFieldProps,
  helperText,
  noHeaderLabel,
  simpleOptions = false,
  getComponent,
  nLabel = false,
  icon,
  iconClick = null,
  size,
  error,
  onBlur,
  name,
  placeholder,
  onChange,
  ...props
}) {
  const addProps = simpleOptions
    ? {}
    : {
        getOptionLabel: (option) =>
          option?.[optionLabelPropName || "label"] || "",
        isOptionEqualToValue: (option, item) =>
          option?.[optionValuePropName || "_id"] ===
          item?.[optionValuePropName || "_id"],
        renderOption: (prop, option) => {
          const listItem =
            option && typeof option === "object"
              ? option[optionLabelPropName || "label"] || ""
              : "";

          return (
            <li {...prop}>
              <Tooltip title={listItem} placement="left" arrow>
                <div>
                  <span>{listItem}</span>
                </div>
              </Tooltip>
            </li>
          );
        },
      };

  return (
    <SoftBox width="100%">
      {label && nLabel && (
        <SoftBox ml={0.5} mb={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SoftTypography>
        </SoftBox>
      )}
      {label && !nLabel && (
        <SoftBox ml={0.5} mb={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {label ? (
              <SoftBox ml={0.5} display="flex">
                <RequiredLabel
                  label={label}
                  required={required}
                  noHeaderLabel={noHeaderLabel}
                  getComponent={getComponent}
                />
              </SoftBox>
            ) : null}
          </SoftTypography>
        </SoftBox>
      )}
      <Autocomplete
        options={options}
        {...addProps}
        fullWidth
        value={value || null}
        onChange={(e, i) => {
          onChange(e, i);
        }}
        renderInput={(params) => (
          <TextField
            name={name}
            ref={params.InputProps.ref}
            {...params}
            placeholder={placeholder}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment:
                icon && icon.component ? (
                  <InputAdornment
                    position="start"
                    onClick={iconClick}
                    sx={{
                      cursor: "pointer",
                      fontSize: "large",
                      color: "dark.main",
                    }}
                  >
                    <Icon>{icon.component}</Icon>
                  </InputAdornment>
                ) : null,
            }}
            {...(size ? { size } : {})}
            {...(error ? { error } : {})}
            onBlur={onBlur}
          />
        )}
        {...props}
        forcePopupIcon
      />
    </SoftBox>
  );
}
