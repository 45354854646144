import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import moment from "moment";
// x-data-grid
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  // GridToolbarExport,
  GridToolbarQuickFilter,
  // useGridApiContext,
  GridPagination,
  GridToolbarExportContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
// hooks
// import useHighlightInput from "hooks/useHighlightInput";
// api
// import { postOperation } from "APIs/organization/operations";
import { createEmployeeActivity } from "APIs/organization/UserManagement"; // Import the API

export const ItemsSection = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexGrow: 1,
  [theme.breakpoints.up("lg")]: {
    paddingRight: theme.spacing(5),
  },
  marginLeft: theme.spacing(1.25),
}));
export const ItemsEndSection = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",

  flexGrow: 1,
}));
export const fontStyles = {
  fontSize: "12px",
  fontweight: 600,
  alignContent: "flex-end",
  color: "#31456A",
};

// const rowsRef = useGridApiContext().current;
// const val = rowsRef.state?.filter?.filterModel?.quickFilterValues[0];
// useHighlightInput(val, rowsRef?.windowRef);

const exportAllRows = async (title, sensitiveInfo, exp) => {
  if (sensitiveInfo) {
    const res = await createEmployeeActivity({
      action: "DOWNLOAD",
      description: title,
      // params: JSON.stringify(params),
    });
    if (res?.message === "Activity created successfully") {
      exp();
    }
  } else {
    exp();
  }
};

const GridCsvExportMenuItem = (props) => {
  const { title, sensitiveInfo, hideMenu, options, dataParams, ...other } =
    props;
  const apiRef = useGridApiContext();
  return (
    <MenuItem
      onClick={() => {
        exportAllRows(
          title,
          sensitiveInfo,
          () =>
            apiRef.current.exportDataAsCsv({
              fileName: `${title || "Downloaded file"}_${moment().format()}`,
            }),
          dataParams
        );
        hideMenu?.();
      }}
      {...other}
      sx={{ fontSize: "12px" }}
    >
      <ListItemIcon>
        <SystemUpdateAltIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Export CSV</ListItemText>
    </MenuItem>
  );
};

const csvOptions = { delimiter: ";" };

const CustomToolbar =
  ({
    Component,
    gridPagination,
    columnsForTable,
    title,
    sensitiveInfo,
    dataParams = {},
    customContent,
  }) =>
  () =>
    (
      <>
        <GridToolbarContainer sx={{ p: "0.5rem" }}>
          <GridToolbarQuickFilter sx={{ maxWidth: "300px" }} />
          <ItemsEndSection>{Component && <Component />}</ItemsEndSection>
          {gridPagination ? <GridPagination /> : null}
          {columnsForTable ? (
            <GridToolbarColumnsButton
              sx={{
                ...fontStyles,
              }}
            />
          ) : null}

          {/* <GridToolbarExport
          startIcon={<SystemUpdateAltIcon />}
          sx={{
            ...fontStyles,
          }}
        /> */}

          <GridToolbarExportContainer
            sx={{
              ...fontStyles,
            }}
          >
            <GridCsvExportMenuItem
              options={csvOptions}
              title={title}
              sensitiveInfo={sensitiveInfo}
              dataParams={dataParams}
            />
          </GridToolbarExportContainer>
        </GridToolbarContainer>
        {customContent ? customContent() : null}
      </>
    );
export default CustomToolbar;
