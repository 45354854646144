import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const useErrorAlert = (processStatus, resetBackdropProcess) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { name, result, message, showFeedback, feedbackType } = processStatus;
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState();
  const show = useMemo(() => {
    if (
      location.pathname === "/app/finance/make-payment" &&
      result === "success" &&
      name === "fetch fee plan[s]"
    )
      return false;

    if (showFeedback === false) return false;
    if (feedbackType === "ALL") return true;
    if (feedbackType === "SUCCESS") return result === "success";
    if (feedbackType === "ERROR") return result === "error";
    return true;
  }, [location, showFeedback, name, result]);

  const getMessage = () => {
    if (message && (result === "success" || result === "info")) {
      return message;
    }
    if (!message) return null;
    const index = message?.indexOf("{");
    const duplicateString = message?.includes("duplicate")
      ? " already exists"
      : "";
    const m = `${String(message).substring(index)} ${duplicateString || ""}`;
    return m.replace(/[{}]/g, " ");
  };
  useEffect(() => {
    const str = getMessage();
    setMsg(str);
    if (show && name) {
      setOpen(true);
    }
    if (show === false) dispatch(resetBackdropProcess());
  }, [processStatus]);

  return [msg, result, open, setOpen, show];
};
export default useErrorAlert;
