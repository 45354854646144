// React and Redux imports
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

// Formik and validation imports
import { useFormik } from "formik";
import * as yup from "yup";

// Custom components
import SoftButton from "myComponents/SoftButton";
import SoftInput from "myComponents/SoftInput";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import SoftAutoCompleteV2 from "myComponents/SoftAutoComplete/v2";
// Redux actions
import {
  addInventoryAccountStart,
  updateInventoryAccountStart,
} from "redux/inventory/slice";

// Components
import DialogV1 from "components/CustomDialog/v1";

// Selectors
import { selectAppDialog, selectProcessStatus } from "redux/app/app.selectors";
import { selectBranchesList } from "redux/organization/branches/branches.selectors";

const AccountDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectAppDialog);
  const branches = useSelector(selectBranchesList);
  const [editAccount, setEditAccount] = useState(null);
  const processStatus = useSelector(selectProcessStatus);
  const { name, result } = processStatus;
  const { dialogData } = data;

  const formik = useFormik({
    initialValues: {
      accountName: editAccount ? editAccount.accountName : "",
      isActive: editAccount ? editAccount.isActive : true,
      branch: editAccount ? editAccount.branch : null,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      accountName: yup.string().required("Account Name is required"),
    }),
    onSubmit: (values) => {
      const accountData = {
        ...values,
      };
      if (editAccount) {
        dispatch(
          updateInventoryAccountStart({ id: editAccount._id, ...accountData })
        );
      } else {
        dispatch(addInventoryAccountStart(accountData));
      }
    },
  });

  const getBranch = (branchCode) =>
    branches.find((branch) => branch.branchCode === branchCode);

  useEffect(() => {
    if (dialogData && dialogData) {
      setEditAccount(dialogData);
    }
  }, [dialogData]);

  useEffect(() => {
    if (
      (name === "create inventory account" ||
        name === "update inventory account") &&
      result === "success"
    ) {
      handleClose();
    }
  }, [name, result]);

  return (
    <DialogV1
      open={open}
      handleClose={handleClose}
      title={editAccount ? "Edit Account" : "Add Account"}
    >
      <SoftBox p={3} pt={0} mt={2}>
        <form onSubmit={formik.handleSubmit}>
          <SoftBox bgColor="white" borderRadius={16}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={0.5} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="body2"
                    color="error"
                  >
                    Branch*
                  </SoftTypography>
                </SoftBox>
                <SoftAutoCompleteV2
                  options={branches}
                  value={getBranch(formik.values.branch)}
                  onChange={(_, newValue) =>
                    formik.setFieldValue("branch", newValue.branchCode)
                  }
                  error={formik.touched.branch && Boolean(formik.errors.branch)}
                  helperText={formik.touched.branch && formik.errors.branch}
                  placeholder="Select Branch"
                  optionLabelPropName="branchName"
                  optionValuePropName="branchCode"
                  fullWidth
                  readOnly={editAccount}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mb={0.5} ml={0.5}>
                  <SoftTypography
                    component="label"
                    variant="body2"
                    color="error"
                  >
                    Account Name*
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  name="accountName"
                  label="Account Name"
                  value={formik.values.accountName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountName &&
                    Boolean(formik.errors.accountName)
                  }
                  helperText={
                    formik.touched.accountName && formik.errors.accountName
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SoftBox display="flex" alignItems="center">
                  <Checkbox
                    checked={formik.values.isActive}
                    onChange={(e) =>
                      formik.setFieldValue("isActive", e.target.checked)
                    }
                  />
                  <SoftTypography variant="body2">Active</SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox display="flex" justifyContent="flex-end" borderRadius={16}>
              <SoftButton
                type="submit"
                size="small"
                color="info"
                variant="contained"
              >
                Save
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </form>
      </SoftBox>
    </DialogV1>
  );
};

export default AccountDialog;
