import baseAxios from "APIs/baseAxios";

const COMMON = "/finance/elements";

export async function fetchFeeElements(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/get-eles`, { params: obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createFeeElement(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/create-ele`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFeeElement(id) {
  try {
    return (await baseAxios.get(`${COMMON}/get-ele/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFeeTypes() {
  try {
    return (await baseAxios.get(`${COMMON}/get-types`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateFeeElement(obj) {
  const { _id, ...rest } = obj;
  try {
    return (await baseAxios.put(`${COMMON}/update-ele/${_id}`, { ...rest }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateFeeType(obj) {
  const { _id, ...rest } = obj;
  try {
    return (await baseAxios.put(`${COMMON}/update-type/${_id}`, { ...rest }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteFeeElement(id) {
  try {
    return (await baseAxios.delete(`${COMMON}/delete-ele/${id}`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createFeeType(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/create-type`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createFeeInstallment(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/create-installment`, { ...obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchFeeInstallments(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/get-installments`, { params: obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateFeeInstallment(obj) {
  const { _id, ...rest } = obj;
  try {
    return (
      await baseAxios.put(`${COMMON}/update-installment/${_id}`, { ...rest })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createFeeTypesPrecedence(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/precedence-create`, { ...obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchFeeTypesPrecedence(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/precedence-get`, { params: obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updateFeeTypesPrecedence(obj) {
  const { _id, ...rest } = obj;
  try {
    return (
      await baseAxios.put(`${COMMON}/precedence-update/${_id}`, { ...rest })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
