// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
// Soft UI Dashboard React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "layout/LayoutComponents/PageLayout";
import Footer from "layout/LayoutComponents/Footer";
import onesaz from "assets/onesaz_mini.png";

function CoverLayout({
  color = "info",
  header,
  title,
  description,
  image,
  top,
  ifLogoRequired = false,
  children,
}) {
  return (
    <PageLayout
      sx={{
        backgroundColor: "#eff2f4",
      }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          minHeight: "75vh",
          margin: 0,
        }}
      >
        <Grid item xs={11} sm={8} md={5} xl={3}>
          {ifLogoRequired && (
            <SoftBox
              mx={3}
              mt={3}
              component="img"
              src={onesaz}
              alt="UI Logo"
              width="9rem"
            />
          )}
          <SoftBox mt={top}>
            <SoftBox px={3}>
              {!header ? (
                <>
                  <SoftBox mb={1}>
                    <SoftTypography
                      variant="h3"
                      fontWeight="bold"
                      color={color}
                      textGradient
                    >
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    variant="body2"
                    fontWeight="regular"
                    color="text"
                  >
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <SoftBox
            height="100%"
            display={{ xs: "none", md: "block" }}
            position="relative"
            right={{ md: "-12rem", xl: "-16rem" }}
            mr={-16}
            sx={{
              transform: "skewX(-10deg)",
              overflow: "hidden",
              borderBottomLeftRadius: ({ borders: { borderRadius } }) =>
                borderRadius.lg,
            }}
          >
            <SoftBox
              ml={-8}
              height="100%"
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                transform: "skewX(10deg)",
              }}
            />
          </SoftBox>
        </Grid>
      </Grid>
      {ifLogoRequired ? null : <Footer />}
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 10,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
