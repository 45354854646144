import store from "store";
import baseAxios from "../baseAxios";

export async function createAcademicYear(body) {
  try {
    return (await baseAxios.post("/organization/academic-years", body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// export async function getAcademicYears(id, params) {
//   const path = id
//     ? `/organization/academic-years/${id}`
//     : "/organization/academic-years";
//   try {
//     return (
//       await baseAxios.get(path, {
//         params: {
//           ...params,
//           instituteId: store.get("instituteId"),
//           isPreAdmissionYear: true,
//         },
//       })
//     ).data;
//   } catch (error) {
//     return Promise.reject(error);
//   }
// }

export async function getAcademicYears(id, params) {
  const path = id
    ? `/organization/academic-years/${id}`
    : "/organization/academic-years";

  // Get the user's designation
  // const user = store.get("user");
  // const isCampaign = user?.designation === "CAMPAIGN";

  // Conditionally include the isPreAdmissionYear parameter
  const queryParams = {
    ...params,
    instituteId: store.get("instituteId"),
    // ...(isCampaign && {
    //   isPreAdmissionYear: true,
    // }),
  };

  try {
    return (
      await baseAxios.get(path, {
        params: queryParams,
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchAy(body) {
  try {
    return (
      await baseAxios.get("/organization/academic-years", { params: body })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updatAcademicYear(id, body) {
  try {
    return (await baseAxios.patch(`/organization/academic-years/${id}`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function editAcademicYear(body) {
  const id = body?._id;
  console.log(id);
  try {
    return (await baseAxios.patch(`/organization/academic-years/${id}`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
