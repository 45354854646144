import { takeLatest, put, all, call, select } from "redux-saga/effects";
// apis
import {
  fetchTransactions,
  saveTransaction,
  intiateOnlineTransaction,
  getTxtReceipt,
  updateTxn,
  getFeeReports,
} from "../../../APIs/finance/makePayment";

import {
  fetchFeePlans,
  getCollectionsAndPosTerminalsMap,
} from "../../../APIs/finance/feePlan";
import { getFeeTypes } from "../../../APIs/finance/elements";
// acitions
import {
  chequeOrCashPaymentSuccess,
  fetchStudentDetailsAndTxnHistorySuccess,
  resetMakePayment,
  initiateOnlineTxnSuccess,
  fetchFeeReportSuccess,
  fetchPosTerminalsSuccess,
  fetchFeeTypesSuccess,
} from "./makePayment.slice";
import { setAdmissionTxnHistory } from "../../admissions/student/student.slice";
// utils
import {
  fetchMulti,
  create,
  fetch,
  fetchFile,
  update,
} from "../../app/app.utils";

export function* fetchTxnHistoryAsync({ payload }) {
  const { handler, ...rest } = payload;
  yield fetch({
    callName: "fetch_txn_history",
    query: { ...rest },
    api: fetchTransactions,
    *handleRes(items) {
      if (handler === "admission") yield put(setAdmissionTxnHistory(items));
    },
  });
}

export function* fetchStuDetailsAndTxnHistoryAsync({ payload }) {
  const { feePlanId, academicYear, batch, branch, admissionType, ...rest } =
    payload;
  yield fetchMulti({
    callName: "fetch fee plan[s]",
    des: "student information",
    query1: {
      query: rest,
      api: fetchTransactions,
    },
    query2: {
      query: {
        academicYear,
        batch,
        branch,
        fromMap: true,
        admissionNo: rest.admissionNo,
        admissionType,
      },
      api: fetchFeePlans,
      targetPath: "result",
    },
    *handleRes(r) {
      const haveFeePlan = Boolean(r.query2);
      if (!haveFeePlan) {
        throw new Error("Fee Plan not found");
      }
      yield put(
        fetchStudentDetailsAndTxnHistorySuccess({
          txnHistory: r.query1,
          feePlan: r.query2,
        })
      );
      return { success: true };
    },
  });
}

export function* makeCashOrChequePaymentAsync({ payload }) {
  const { handler, ...rest } = payload;
  yield create({
    callName: "make_cash_or_cheque_payment",
    inputs: { ...rest },
    api: saveTransaction,
    *handleRes(r) {
      if (handler === "fee") yield put(chequeOrCashPaymentSuccess(r));
      yield put(resetMakePayment());
    },
    resName: "savedTxns",
    needAdditionalInfo: true,
  });
}

export function* intiateOnlineTransactionAsync({ payload }) {
  const { showFeedBack, ...rest } = payload;
  yield create({
    callName: "initiate_online_payment",
    inputs: rest,
    api: intiateOnlineTransaction,
    *handleRes(r) {
      yield put(initiateOnlineTxnSuccess(r));
      yield put(resetMakePayment());
    },
    resName: "payload",
    showFeedBack,
  });
}

export function* fetchReceiptAsync({ payload }) {
  yield fetchFile({
    callName: "fetch receipt",
    query: payload,
    api: getTxtReceipt,
  });
}

export function* updateTxnAsync({ payload }) {
  yield update({
    callName: "update_txn",
    updates: payload,
    api: updateTxn,
    *handleRes() {
      yield 0;
    },
    updatedName: "updatedTxn",
    needAdditionalInfo: true,
  });
}

export function* fetchFeeReportsAsync({ payload }) {
  yield fetch({
    callName: "fetch_fee_report",
    query: payload,
    targetPath: "result",
    api: getFeeReports,
    *handleRes(items) {
      if (!payload.download) yield put(fetchFeeReportSuccess(items));
    },
  });
}

const FETCH_INTERVAL = 10 * 60 * 1000; // 10 minute

export function* fetchPosTerminalsAsync({ payload }) {
  const lastFetchedPosTerminalsAt = yield select(
    (state) => state.makePayment.lastFetchedPosTerminalsAt
  );
  const currentTime = Date.now();

  if (
    !lastFetchedPosTerminalsAt ||
    currentTime - lastFetchedPosTerminalsAt > FETCH_INTERVAL
  ) {
    yield fetch({
      callName: "fetch_pos_terminals",
      query: payload,
      api: getCollectionsAndPosTerminalsMap,
      *handleRes(items) {
        yield put(fetchPosTerminalsSuccess(items));
      },
    });
  }
}

export function* fetchFeeTypesAsync({ payload }) {
  const lastFetchedFeeTypesAt = yield select(
    (state) => state.makePayment.lastFetchedFeeTypesAt
  );
  const currentTime = Date.now();

  if (
    !lastFetchedFeeTypesAt ||
    currentTime - lastFetchedFeeTypesAt > FETCH_INTERVAL
  ) {
    yield fetch({
      callName: "fetch_fee_types",
      query: payload,
      api: getFeeTypes,
      targetPath: "data",
      *handleRes(items) {
        yield put(fetchFeeTypesSuccess(items));
      },
    });
  }
}

export function* onFetchStuDetailsAndTxnHist() {
  yield takeLatest(
    "MAKE_PAYMENT/fetchStudentDetailsAndTxnHistory",
    fetchStuDetailsAndTxnHistoryAsync
  );
}

export function* onMakeCashOrChequePayment() {
  yield takeLatest(
    "MAKE_PAYMENT/startChequeOrCashPayment",
    makeCashOrChequePaymentAsync
  );
}

export function* onOnlineTxnStart() {
  yield takeLatest(
    "MAKE_PAYMENT/initiateOnlineTransaction",
    intiateOnlineTransactionAsync
  );
}

export function* onFetchTxnHistory() {
  yield takeLatest("MAKE_PAYMENT/fetchTxnHistory", fetchTxnHistoryAsync);
}
export function* onGenerateTxtnReceipt() {
  yield takeLatest("MAKE_PAYMENT/generateTxtnReceipt", fetchReceiptAsync);
}

export function* onUpdateTxnStart() {
  yield takeLatest("MAKE_PAYMENT/updateTxnStart", updateTxnAsync);
}

export function* onFetchFeeReportStart() {
  yield takeLatest("MAKE_PAYMENT/fetchFeeReportStart", fetchFeeReportsAsync);
}

export function* onFetchPosTerminals() {
  yield takeLatest(
    "MAKE_PAYMENT/fetchPosTerminalsStart",
    fetchPosTerminalsAsync
  );
}

export function* onFetchFeeTypes() {
  yield takeLatest("MAKE_PAYMENT/fetchFeeTypesStart", fetchFeeTypesAsync);
}

export default function* txnSaga() {
  yield all([
    call(onFetchTxnHistory),
    call(onFetchStuDetailsAndTxnHist),
    call(onMakeCashOrChequePayment),
    call(onOnlineTxnStart),
    call(onGenerateTxtnReceipt),
    call(onUpdateTxnStart),
    call(onFetchFeeReportStart),
    call(onFetchPosTerminals),
    call(onFetchFeeTypes),
  ]);
}
