import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  buses: [],
  routes: [],
  transInit: false,
};

const transportSlice = createSlice({
  name: "TRANSPORT",
  initialState,
  reducers: {
    setTranInit(state, action) {
      state.transInit = action.payload;
    },
    setBusesList(state, action) {
      state.buses = action.payload;
    },
    setRoutesList(state, action) {
      state.routes = action.payload;
    },
    addBusesToList(state, action) {
      state.buses = action.payload;
    },
    addRouteToList(state, action) {
      state.routes = action.payload;
    },
    fetchBasicTransportDetails() {},
  },
});

export const {
  setTranInit,
  setBusesList,
  setRoutesList,
  addBusesToList,
  addRouteToList,
  fetchBasicTransportDetails,
} = transportSlice.actions;

export default transportSlice.reducer;
