import { useLocation } from "react-router-dom";
import logoURLMap from "layout/primeLayout/logoURLMap";
import { institutesMap } from "views/Live/ZoomApp/institutes";
// utils
import { objectExists } from "utils/general.utils/object.utils";
// myComponents
import SoftBox from "myComponents/SoftBox";

const HtmlPage = () => {
  const location = useLocation();
  const host = window.location.hostname;
  const instituteId = institutesMap[host] || "";
  const insDetails = logoURLMap?.[instituteId] || {};

  if (!objectExists(insDetails)) return <div />;

  const search = location.search || "";
  const { p } = Object.fromEntries(new URLSearchParams(search));

  if (!p) return <div />;

  const pageUrl = insDetails[p];

  if (!pageUrl) return <div />;

  return (
    <SoftBox display="flex" justifyContent="center" alignItems="center" mt={3}>
      <iframe
        src={pageUrl}
        title={insDetails.title || "Document"}
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      />
    </SoftBox>
  );
};

export default HtmlPage;
