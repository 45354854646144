import { useMemo } from "react";
import { useSelector } from "react-redux";
import store from "store";
// selector
import { selectPrivilegesOfRoles } from "redux/settings/settings.selector";

const useMyPrivileges = (features) => {
  const config = useSelector(selectPrivilegesOfRoles) || {};
  const {
    addedPrivileges = [],
    removedPrivileges = [],
    assignedRoles,
  } = store.get("user");
  const isSuperAdmin = (assignedRoles || []).includes("SUPER_ADMIN");
  const expensesCond = window.location.hostname.includes("expenses");

  const some = useMemo(
    () =>
      [
        ...Object.keys(config).reduce((acc, feature) => {
          if (
            (assignedRoles || []).some((role) =>
              config[feature]?.includes(role)
            )
          ) {
            return [...acc, feature];
          }
          return acc;
        }, []),
        ...addedPrivileges,
      ].filter((feature) => !removedPrivileges.includes(feature)),
    [addedPrivileges, removedPrivileges]
  );
  const notIncludedSuperAdmin = [
    "manual_ApplicationNo",
    "manual_AdmissionNo",
    "allow_past_transaction_entry",
    "simple_batch_update",
    "simple_branch_update",
    "custom_fee_adjustment",
    "simple_transfer",
  ];
  if (expensesCond) notIncludedSuperAdmin.push("create_pro");
  if (!features) return some;
  return features.reduce((acc, feature) => {
    if (isSuperAdmin && !notIncludedSuperAdmin.includes(feature)) {
      acc[feature] = true;
    } else {
      acc[feature] = some.includes(feature);
    }
    return acc;
  }, {});
};

export default useMyPrivileges;
