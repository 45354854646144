// import store from "store";
import baseAxios from "../baseAxios";

const COMMON = "/finance/txn";
// /reset-res-fee
export async function intiateTransaction(obj) {
  try {
    return (await baseAxios.post(COMMON, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateTxn(obj) {
  const { _id, ...rest } = obj;
  try {
    const { data } = await baseAxios.patch(`${COMMON}/${_id}`, {
      ...rest,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function saveTransaction(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/save-txn`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function intiateOnlineTransaction(obj) {
  try {
    return (await baseAxios.post(COMMON, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function changeApplicationStatus(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/changeApplicationStatus`, obj))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchTransactions(obj) {
  console.log(obj);
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/hist/${id}` : `${COMMON}/hist`;
  // instituteId: store.get("instituteId")
  try {
    return (
      await baseAxios.get(path, {
        params: { ...rest },
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getTxtReceipt(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/receipt`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function BranchWiseCollectionSummary(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/branch-wise-summary`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function ZoneWiseCollectionSummary(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/zone-wise-summary`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchACWiseTransactions(obj) {
  const path = `${COMMON}/getAcWise`;
  try {
    return (await baseAxios.get(path, { params: obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getFeeReports(obj) {
  const { download, ...rest } = obj;
  try {
    return (
      await baseAxios.post("admissions/student/fee-report", {
        ...rest,
        headers: download ? { "x-download": true } : {},
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFeePlanAndTxnHistory(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/plan-history`, { params: obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getFeeRevertxns(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/revert-txns`, { params: obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
