import { useState } from "react";
// material ui
import EditIcon from "@mui/icons-material/Edit";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import { Grid, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

// x-data-grid
import { GridRowModes, GridActionsCellItem } from "@mui/x-data-grid";

// myCompoents
import SoftInput from "myComponents/SoftInput";
// styled
import StyledDataGrid from "./styles";
import CustomToolbar from "./ToolBar";
import GridCheckBox from "../../components/CheckBoxType";

const headerGroupStyles = (color, bgColor) => ({
  backgroundColor: bgColor || ((theme) => theme.palette.info.main),
  color: color || "#fff",
  "& .MuiDataGrid-columnHeaderDraggableContainer": {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "center",
    },
  },
});

export default function SoftDataGrid({
  rows,
  columns,
  processRowUpdate,
  onProcessRowUpdateError,
  actions,
  handleDeleteClick,
  handleEditClick,
  handleViewClick,
  handleCloneClick,
  handleAdmissionFeePayClick,
  handlePrintClick,
  toolBar = false,
  AdditionalComponent,
  CheckBoxcomponent,
  checkboxType = false,
  height,
  density,
  FooterComponent,
  footerProps,
  containerStyles,
  latestDataToDisplay = true,
  haveColumnGrouping,
  elevation = true,
  gridPagination = true,
  columnsForTable = true,
  title = null,
  sensitiveInfo = false,
  params,
  disableMultipleSelection = false,
  cellBorder = false,
  customContent,
  ...rest
}) {
  const [rowModesModel, setRowModesModel] = useState({});
  const [pageSize, setPageSize] = useState(50);
  const emptyFooter = () => <Grid padding={1} />;
  const havePagination = rows?.length > 30;
  const additionalComponents = {};
  additionalComponents.LoadingOverlay = LinearProgress;
  if (checkboxType)
    additionalComponents.BaseCheckbox = GridCheckBox(checkboxType);
  if (FooterComponent) additionalComponents.Footer = FooterComponent;
  if (toolBar)
    additionalComponents.Toolbar = CustomToolbar({
      Component: AdditionalComponent,
      gridPagination,
      columnsForTable,
      title,
      sensitiveInfo,
      dataParams: params || {},
      customContent,
    });
  if (!havePagination) additionalComponents.Pagination = emptyFooter;
  let finalRows;
  if (latestDataToDisplay === true) {
    finalRows = [...rows].reverse();
  } else {
    finalRows = rows;
  }
  const handleRowEditStartOrStop = (_, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleDefaultEditClick = (id) => () =>
    handleEditClick
      ? handleEditClick(id)
      : setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.Edit },
        });
  const handleDefaultCloneClick = (id) => () =>
    handleCloneClick
      ? handleCloneClick(id)
      : setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.Clone },
        });
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const getActions = (actionsObj) => {
    if (
      actionsObj &&
      (actionsObj.edit || actionsObj.del || actionsObj.view || actionsObj.print)
    ) {
      const col = {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 100,
        flex: 0.5,
        cellClassName: "actions",
        align: "left",
        headerAlign: "left",
      };
      col.getActions = ({ id, row }) => {
        if (row.isPinned) {
          // Return an empty array if the row is pinned, thus no actions will be shown
          return [];
        }
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        const myArr = [];
        if (isInEditMode) {
          myArr.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              // color="inherit"
            />
          );
        } else {
          if (actions.feePay)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Proceed To Pay">
                    <CurrencyRupeeIcon color="dark" />
                  </Tooltip>
                }
                label="Fee pay"
                onClick={() => {
                  handleAdmissionFeePayClick(id);
                }}
              />
            );
          if (actions.edit)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Edit">
                    <EditIcon color="info" />
                  </Tooltip>
                }
                label="Edit"
                className="textPrimary"
                onClick={handleDefaultEditClick(id)}
                // color="inherit"
              />
            );
          if (actions.print)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Print">
                    <LocalPrintshopIcon
                      sx={{ fill: (theme) => theme.palette.info.main }}
                    />
                  </Tooltip>
                }
                label="Edit"
                className="textPrimary"
                onClick={() => handlePrintClick(id)}
              />
            );
          if (actions.view)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="View">
                    <VisibilityIcon
                      sx={{ fill: (theme) => theme.palette.info.main }}
                    />
                  </Tooltip>
                }
                label="View"
                className="textPrimary"
                onClick={() => handleViewClick(id)}
              />
            );
          if (actions.del)
            myArr.push(
              <GridActionsCellItem
                icon={<DeleteIcon color="error" />}
                label="Delete"
                onClick={() => {
                  handleDeleteClick(id);
                }}
              />
            );
          if (actions.clone)
            myArr.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Clone">
                    <FileCopyIcon color="info" />
                  </Tooltip>
                }
                label="Clone"
                className="textPrimary"
                onClick={handleDefaultCloneClick(id)}
                // color="inherit"
              />
            );
        }

        return myArr;
      };
      return [col];
    }
    return [];
  };

  const allColumns = [...columns].concat(getActions(actions));

  const columnGroupingStyles = haveColumnGrouping
    ? {
        "& .column-group-item1": headerGroupStyles(),
        "& .column-group-item2": headerGroupStyles(null, "#39D0B8"),
      }
    : {};

  return (
    <TableContainer
      sx={{
        ...containerStyles,
        ...columnGroupingStyles,
        boxShadow: elevation ? null : "none",
      }}
    >
      <StyledDataGrid
        rows={finalRows}
        columns={allColumns}
        rowModesModel={rowModesModel}
        // onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStartOrStop}
        onRowEditStop={handleRowEditStartOrStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        experimentalFeatures={{
          columnGrouping: true,
          newEditingApi: true,
          lazyLoading: true,
        }}
        checkboxSelection={!!checkboxType}
        components={{
          ...additionalComponents,
          BaseTextField: SoftInput,
          BaseSwitch: Checkbox,
        }}
        componentsProps={{ footer: footerProps }}
        disableSelectionOnClick
        density={density || "compact"}
        pageSize={havePagination ? pageSize : null}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[30, 50, 100, 500]}
        hideFooter={!havePagination && !FooterComponent}
        cellBorder={cellBorder}
        disableRowSelectionOnClick
        disableColumnMenu
        disableMultipleSelection={disableMultipleSelection}
        {...rest}
      />
    </TableContainer>
  );
}
