import dayjs from "dayjs";
import { useEffect, useRef } from "react";
// hooks
import useMyPrivileges from "hooks/useMyPrivileges";
// myComponents
import SoftBox from "myComponents/SoftBox";
import SoftInput from "myComponents/SoftInput";
import SoftTypography from "myComponents/SoftTypography";

const CashPayment = ({ values, setValues }) => {
  const { allow_past_transaction_entry } = useMyPrivileges([
    "allow_past_transaction_entry",
  ]);
  const today = dayjs();
  const endOfToday = today.endOf("day").format("YYYY-MM-DD");
  const startOfToday = today.startOf("day").format("YYYY-MM-DD");
  const dateInputRef = useRef(null);

  useEffect(() => {
    if (dateInputRef.current) {
      dateInputRef.current.setAttribute("max", endOfToday);
      if (!allow_past_transaction_entry) {
        dateInputRef.current.setAttribute("min", startOfToday);
      }
    }
  }, [allow_past_transaction_entry, endOfToday, startOfToday]);

  if (!allow_past_transaction_entry) {
    return null;
  }

  return (
    <SoftBox p={1} ml={3} pb={4}>
      <SoftBox maxWidth={300}>
        <SoftTypography
          variant="caption"
          fontWeight="bold"
          color="secondary"
          display="block"
          mb={0.3}
          ml={0.3}
        >
          Date And Time of transaction Date(optional)
        </SoftTypography>
        <SoftInput
          type="date"
          inputRef={dateInputRef}
          value={values.receiptDate}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, receiptDate: e.target.value }))
          }
          fullWidth
          size="small"
          disabled={!allow_past_transaction_entry}
        />
      </SoftBox>
    </SoftBox>
  );
};
export default CashPayment;
