import SoftBox from "myComponents/SoftBox";
// utils
import useSignOut from "utils/auth/useSignOut";
// layout components
import ScreenContainer from "layout/LayoutComponents/ScreeContainer";
// myComponents
import SoftButton from "myComponents/SoftButton";
// helpers
import MobileHeader from "helpers/MobileHeader";

const Profile = () => {
  const signOut = useSignOut();
  return (
    <>
      <MobileHeader title="Profile" haveAySelect={false} subTitle="AY" />
      <ScreenContainer>
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <SoftButton onClick={signOut}>Sign Out</SoftButton>
        </SoftBox>
      </ScreenContainer>
    </>
  );
};

export default Profile;
