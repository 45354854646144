// React and related hooks
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Store
import store from "store";
// Cache Buster
import CacheBuster from "react-cache-buster";
// MUI components
import { useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// Soft UI Dashboard React components
import SoftAutoComplete from "myComponents/SoftAutoComplete";
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
import SoftInput from "myComponents/SoftInput";
import SoftNotification from "myComponents/SoftNotification";
import SoftTypography from "myComponents/SoftTypography";
// Layout components
import CoverLayout from "layout/LayoutComponents/CoverLayout";
import InstituteLayout from "layout/LayoutComponents/InstituteLayout";
// Validation schema
import signinSchema from "validation/signinSchema";
// APIs
import { getInstConfig, userLogin } from "APIs/UserServices";
// Utils
import { objectExists } from "utils/general.utils/object.utils";
// Custom hooks
import useFetch from "hooks/useFetch";
// Redux actions
import { setLoginUser } from "redux/app/app.slice";
// Images
import indexImg from "assets/images/indexImg.png";
import onesaz from "assets/onesaz_mini.png";

import logoURLMap from "layout/primeLayout/logoURLMap";
import { institutesMap } from "views/Live/ZoomApp/institutes";

// Components
import Spinner from "components/Spinner";
import KeyIcon from "./KeyIcon";
import MPINInput from "./MPINInput";

// Other
import packageObj from "../../../package.json";

const initialValues = {
  employeeId: "",
  password: "",
};
const bhas_img =
  "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/BHASHYAM+ADMIN+LOGO.jpg";
const HtmlPage = () => {
  const host = window.location.hostname;
  const instituteId = institutesMap[host] || "";
  const insDetails = logoURLMap?.[instituteId] || {};
  if (!objectExists(insDetails)) return null;
  return insDetails;
};

// Add styled dialog component
const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    minWidth: "300px",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.92)",
  },
}));

function SignIn() {
  const [imageUrl, setImageUrl] = useState(indexImg);
  const { hostname } = window.location;
  const ifExpense = hostname.includes("expenses");
  const inst = institutesMap[hostname];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [myHost, setMyHost] = useState("");
  const sessionExpired = store.get("sessionExpired");
  const employeeId_MPIN = store.get("employeeId");
  const institute = store.get("instituteId");
  const ifMpinLoginRequired =
    employeeId_MPIN &&
    ["5d679d49c136660a09596d85", "65772522ee64cfa8dc9851f2"].includes(
      institute
    );
  const logoObj = logoURLMap[institute];
  // const [checked, setChecked] = useState(true);
  // const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [touched, setIfTouched] = useState(false);
  const [enteredMPIN, setEnteredMPIN] = useState("");
  const [isValidatingMPIN, setIsValidatingMPIN] = useState(false);
  const [loginStep, setLoginStep] = useState(1); // Add this for step management
  const [credentials, setCredentials] = useState(null); // Store credentials temporarily
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [load, er, instConfig, fetchInst, rst] = useFetch(getInstConfig);
  const [isUserLoading, userError, response, fetchUser, resetFetch] =
    useFetch(userLogin);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const mpinInputRef = useRef(null);

  useEffect(() => {
    fetchInst(inst);
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
    const handleOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  useEffect(() => {
    if (myHost?.includes("bhashyam")) {
      setImageUrl(bhas_img);
    } else {
      setImageUrl(indexImg);
    }
  }, [myHost]);

  const redirectPath =
    location.state?.path && location.state?.path !== "/change-password"
      ? location.state.path
      : ifExpense
      ? "/app/expense-management"
      : "/app";
  // initialize formik and set initial values
  const formik = useFormik({
    initialValues,
    validationSchema: signinSchema,
    onSubmit: async (credential) => {
      setIfTouched(true);
      const loginCredentials = {
        ...credential,
        employeeCode: `${myHost ? institutesMap[myHost] : inst}_${
          credential.employeeId
        }`,
      };
      setCredentials(loginCredentials);
      fetchUser(loginCredentials);
      setOpen(true);
    },
  });

  const msgs = {
    prevPath: "Password changed successfully, Sign in with new password",
    error: userError || "something went wrong...",
    success: "Successfully logged in",
    expired: "Token expired, Login again",
    logingIn: "Logging In Pleas wait...",
    mismatch: "Invalid MPIN!",
    offline: "Please check your internet connection and try again.",
  };

  useEffect(() => {
    if (response) {
      const { user, accessToken } = response;
      // for admin purposes
      const { branch, phone, fullName, employeeId, instituteId } = user || {};

      // Handle first login step
      if (loginStep === 1) {
        if (
          instConfig?.result?.privileges?.enable_MPIN?.includes(
            user.designation
          ) &&
          user.mPin &&
          ["5d679d49c136660a09596d85", "65772522ee64cfa8dc9851f2"].includes(
            instituteId
          )
        ) {
          setLoginStep(2);
          resetFetch();
          setOpen(false);
          return;
        }
      }

      // Complete login process
      store.set("user", {
        userId: employeeId,
        name: fullName,
        phoneno: phone,
        branchId: branch,
        ...user,
      });
      dispatch(
        setLoginUser({
          userId: employeeId,
          employeeId,
          name: fullName,
          assignedBranches: user.assignedBranches,
          assignedZones: user.assignedZones,
          accessLevel: user.accessLevel,
          designation: user.designation,
        })
      );
      // dispatch(setLoginId(employeeId));
      store.set("instituteId", instituteId);
      store.set("sessionExpired", false);
      if (accessToken) {
        store.set("accessToken", accessToken);
        if (
          user.mPin ||
          !["5d679d49c136660a09596d85", "65772522ee64cfa8dc9851f2"].includes(
            instituteId
          )
        ) {
          navigate(redirectPath, { replace: true });
        } else if (
          !instConfig?.result?.privileges?.enable_MPIN?.includes(
            user.designation
          )
        ) {
          navigate(redirectPath, { replace: true });
        } else {
          store.remove("accessToken");
          navigate("/set-mpin", { replace: true });
        }
      } else navigate("/change-password", { replace: true });
    }
  }, [response]);

  useEffect(() => {
    if (location.state?.path === "/change-password") setOpen(true);
  }, [location.state?.path]);

  useEffect(() => {
    if (sessionExpired) setOpen(true);
  }, [sessionExpired]);

  useEffect(() => {
    if (!isUserLoading) {
      setIsValidatingMPIN(false);
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (ifMpinLoginRequired || loginStep === 2) {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(false);
    }
  }, [ifMpinLoginRequired, loginStep]);

  useEffect(() => {
    if (isDialogOpen && mpinInputRef.current) {
      setTimeout(() => {
        mpinInputRef.current.focus();
      }, 100);
    }
  }, [isDialogOpen]);

  const handleClose = () => {
    resetFetch();
    if (sessionExpired) store.set("sessionExpired", false);
    setOpen(false);
  };
  const getFeedback = () => {
    if (isUserLoading) return { msg: msgs.logingIn, type: "info" };
    if (sessionExpired) return { msg: msgs.expired, type: "error" };
    if (!touched && location.state?.path === "/change-password")
      return { msg: msgs.prevPath, type: "success" };
    if (response) return { msg: msgs.success, type: "success" };
    if (!isOnline) return { msg: msgs.offline, type: "warning" };
    if (userError) return { msg: msgs.error, type: "error" };
    // if (enteredMPIN.length !== 4) return { msg: msgs.mismatch, type: "error" };

    return { msg: msgs.info, type: "Processing..." };
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleValidateMPIN = (mpin) => {
    if (isValidatingMPIN) return;
    setIsValidatingMPIN(true);
    fetchUser({
      mPin: mpin,
      employeeCode: `${inst}_${employeeId_MPIN}`,
    });
    setOpen(true);
  };
  const handleMPIN = (mpin) => {
    setEnteredMPIN(mpin);
    if (mpin.length === 4) {
      handleValidateMPIN(mpin);
    }
  };

  const handleMPINSubmit = (mpin) => {
    if (mpin.length === 4 && credentials) {
      if (isValidatingMPIN) return;
      setIsValidatingMPIN(true);
      fetchUser({
        employeeCode: `${inst}_${credentials.employeeId}`,
        mPin: mpin,
      });
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    if (loginStep === 2) {
      setLoginStep(1);
    }
    setIsDialogOpen(false);
  };

  const renderMPINContent = () => (
    <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
      <SoftBox
        component="img"
        src={logoObj ? logoObj.url : onesaz}
        alt="brand_logo"
        width="5rem"
      />
      <SoftTypography variant="subtitle1" fontWeight="bold">
        {logoObj ? logoObj.title : "ONESAZ"}
      </SoftTypography>
      <MPINInput
        ref={mpinInputRef}
        title={
          <>
            Enter your MPIN&nbsp;
            <KeyIcon />
          </>
        }
        onMPINChange={ifMpinLoginRequired ? handleMPIN : handleMPINSubmit}
        reset={!!userError}
      />
      <SoftBox mt={2}>
        {ifMpinLoginRequired ? (
          <Typography variant="caption">
            Forgot your MPIN?&nbsp;
            <Typography
              component="span"
              color="#08a1c4"
              variant="caption"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/forgot-mpin")}
            >
              Click here
            </Typography>
          </Typography>
        ) : (
          <SoftButton
            variant="outlined"
            color="info"
            onClick={() => setLoginStep(1)}
            fullWidth
          >
            Back to Login
          </SoftButton>
        )}
      </SoftBox>
    </SoftBox>
  );

  const renderMainForm = () => (
    <SoftBox pt={2} pb={3} px={isMobile ? 0 : 3}>
      <SoftBox
        mb={2}
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SoftBox
          component="img"
          src={HtmlPage() ? HtmlPage()?.url : onesaz}
          alt="UI Logo"
          width="5rem"
          height="5rem"
        />
      </SoftBox>
      <SoftBox component="form" role="form" onSubmit={formik.handleSubmit}>
        {process.env.NODE_ENV === "development" ||
        window.location.hostname === "t-admin.onesaz.com" ? (
          <SoftBox mb={1}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Hostname
            </SoftTypography>
            <SoftAutoComplete
              simpleOptions
              list={Object.keys(institutesMap)}
              selected={myHost}
              onChange={(e, i) => setMyHost(i)}
            />
          </SoftBox>
        ) : null}
        <SoftBox mb={1}>
          <SoftBox mb={0.5} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Employee Id
            </SoftTypography>
          </SoftBox>
          <SoftInput
            name="employeeId"
            type="text"
            placeholder="EmployeeId"
            value={formik.values.employeeId}
            error={
              formik.touched.employeeId && Boolean(formik.errors.employeeId)
            }
            onChange={formik.handleChange}
          />
          {formik.touched.employeeId && Boolean(formik.errors.employeeId) ? (
            <SoftTypography
              variant="caption"
              color={
                formik.touched.employeeId && Boolean(formik.errors.employeeId)
              }
            >
              {formik.errors.employeeId}
            </SoftTypography>
          ) : null}
        </SoftBox>
        <SoftBox mb={1}>
          <SoftBox mb={0.5} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            name="password"
            type="password"
            placeholder="Password"
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            onChange={formik.handleChange}
          />
          {formik.touched.password && Boolean(formik.errors.password) ? (
            <SoftTypography
              variant="caption"
              color={formik.touched.password && Boolean(formik.errors.password)}
            >
              {formik.errors.password}
            </SoftTypography>
          ) : null}
        </SoftBox>
        {/* <SoftBox display="flex" alignItems="center">
      <Switch checked={rememberMe} onChange={handleSetRememberMe} />
      <SoftTypography
        variant="button"
        fontWeight="regular"
        onClick={handleSetRememberMe}
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        &nbsp;&nbsp;Remember me
      </SoftTypography>
    </SoftBox> */}
        <SoftBox>
          <Typography variant="caption">
            Forgot Password?&nbsp;
            <Typography
              component="span"
              color="#08a1c4"
              variant="caption"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/forgot-password")}
            >
              Click here
            </Typography>
          </Typography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="contained" color="info" fullWidth>
            sign in
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );

  const renderContent = () => {
    if (ifMpinLoginRequired || loginStep === 2) {
      return (
        <>
          <div style={{ filter: isDialogOpen ? "blur(5px)" : "none" }}>
            {!ifMpinLoginRequired && renderMainForm()}
          </div>
          <StyledDialog
            open={isDialogOpen}
            maxWidth="xs"
            fullWidth
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            disableEscapeKeyDown
            aria-describedby="mpin-dialog"
          >
            <DialogTitle>
              {ifMpinLoginRequired ? "Login with MPIN" : "Enter MPIN"}
            </DialogTitle>
            <DialogContent>{renderMPINContent()}</DialogContent>
          </StyledDialog>
        </>
      );
    }

    return renderMainForm();
  };

  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageObj.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Spinner />}
    >
      <SoftNotification
        open={isOpen}
        close={handleClose}
        color={getFeedback().type}
      >
        {getFeedback().msg}
      </SoftNotification>
      {myHost?.includes("bhashyam") ? (
        <InstituteLayout
          ifLogoRequired={ifMpinLoginRequired}
          title={loginStep === 2 ? "Enter MPIN" : "Welcome Back"}
          description={
            loginStep === 2
              ? "Please enter your MPIN to continue"
              : "Enter your employee Id and password to sign in"
          }
          image={imageUrl}
        >
          {isMobile ? (
            renderContent()
          ) : (
            <Card
              sx={{
                width: loginStep === 2 ? "max-content" : "initial",
              }}
            >
              {renderContent()}
            </Card>
          )}
        </InstituteLayout>
      ) : (
        <CoverLayout
          ifLogoRequired={ifMpinLoginRequired || loginStep === 2}
          title={loginStep === 2 ? "Enter MPIN" : "Welcome Back"}
          description={
            loginStep === 2
              ? "Please enter your MPIN to continue"
              : "Enter your employee Id and password to sign in"
          }
          image={imageUrl}
        >
          {isMobile ? (
            renderContent()
          ) : (
            <Card
              sx={{
                width: ifMpinLoginRequired ? "max-content" : "initial",
              }}
            >
              {renderContent()}
            </Card>
          )}
        </CoverLayout>
      )}
    </CacheBuster>
  );
}

export default SignIn;
