import store from "store";
import baseAxios from "../baseAxios";

const COMMON = "/organization/config";

export async function createInsConfig(body) {
  try {
    return (await baseAxios.post(COMMON, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addOrUpdateTopicSelection(body) {
  try {
    return (await baseAxios.post(`${COMMON}/addOrUpdateTopicSelection`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchInstituteConfig() {
  try {
    const { data } = await baseAxios.get(COMMON, {
      params: { instituteId: store.get("instituteId") },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getInstituteConfig(insId) {
  console.log(insId);
  try {
    const { data } = await baseAxios.get(COMMON, {
      params: { instituteId: insId },
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updatedInstituteConfig(obj) {
  console.log(obj);
  const { _id, ...rest } = obj;
  try {
    const { data } = await baseAxios.patch(`${COMMON}/${_id}`, {
      ...rest,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchInstituteAyConfig(obj) {
  try {
    const { data } = await baseAxios.get(`${COMMON}/status`, { params: obj });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getTopicSelection(obj) {
  try {
    const { data } = await baseAxios.get(`${COMMON}/getTopicSelection`, {
      params: obj,
    });

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updatedInstituteAyBranchesConfig(obj) {
  try {
    const { data } = await baseAxios.patch(`${COMMON}/branches`, {
      ...obj,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updatedInstituteAyBatchesConfig(obj) {
  try {
    const { data } = await baseAxios.patch(`${COMMON}/batches`, {
      ...obj,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function updatedInstituteAyFilterBatchesConfig(obj) {
  try {
    const { data } = await baseAxios.patch(`${COMMON}/filter-batches`, {
      ...obj,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}
