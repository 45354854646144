import store from "store";
import baseAxios from "../baseAxios";

const ENDPOINT = "/transports";
const BUSES_ENDPOINT = `${ENDPOINT}/buses`;
const ROUTES_ENDPOINT = `${ENDPOINT}/routes`;
const REPAIRS_ENDPOINT = `${ENDPOINT}/repairs`;
const MAINTENANCE_ENDPOINT = `${ENDPOINT}/maintenance`;
const FILLING_STATIONS_ENDPOINT = `${ENDPOINT}/filling-stations`;
const FUEL_RECORDS_ENDPOINT = `${ENDPOINT}/fuel-records`;
const FITNESS_ENDPOINT = `${ENDPOINT}/fitness`;
const STOPS_ENDPOINT = `${ENDPOINT}/stops`;
const DASHBOARD_ENDPOINT = `${ENDPOINT}/dashboard`;
const STUDTRANSPORT_ENDPOINT = `${ENDPOINT}/student-transport-details`;
const DIST_RANGE_TO_FEE_MAP_ENDPOINT = `${ENDPOINT}/dist-range-to-fee-map`;
const PERIODIC_CHECKS = `${ENDPOINT}/periodic-checks`;
const vehicle_log = `${ENDPOINT}/vehicle-logs`;
const maintenance_sheet = `${ENDPOINT}/maintenance-sheets`;

// Bus Management APIs
export async function createBus(obj) {
  const { headers, formDataToSubmit } = obj;

  try {
    return (await baseAxios.post(BUSES_ENDPOINT, formDataToSubmit, { headers }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateBus(obj) {
  console.log("obj", obj);
  const { headers, formDataToSubmit } = obj;
  try {
    const response = await baseAxios.put(
      `${BUSES_ENDPOINT}/${obj.id}`,
      formDataToSubmit,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBuses(params = {}) {
  console.log("BUSES_ENDPOINT", params);
  try {
    const response = await baseAxios.get(BUSES_ENDPOINT, {
      params: {
        ...params,
        //
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteBus(id) {
  try {
    const response = await baseAxios.delete(`${BUSES_ENDPOINT}/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function assignRoutesToBuses(busRoutes) {
  try {
    const response = await baseAxios.post(`${BUSES_ENDPOINT}/assign-routes`, {
      busRoutes,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBusesWithStaffDetails(params = {}) {
  try {
    const response = await baseAxios.get(`${BUSES_ENDPOINT}/staff-details`, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function assignStaffToBus(body) {
  try {
    const response = await baseAxios.post(
      `${BUSES_ENDPOINT}/assign-staff`,
      body
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function allotSeatToStudent(data) {
  try {
    const response = await baseAxios.post(`${BUSES_ENDPOINT}/allot-seat`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Route Management APIs
export async function createRoute(routeData) {
  try {
    const response = await baseAxios.post(ROUTES_ENDPOINT, {
      ...routeData,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateRoute(data) {
  const { formDataObject } = data;
  try {
    const response = await baseAxios.put(
      `${ROUTES_ENDPOINT}/${data.routeId}`,
      formDataObject
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteRoute(routeId) {
  try {
    const response = await baseAxios.delete(`${ROUTES_ENDPOINT}/${routeId}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getRoutes(params = {}) {
  try {
    const response = await baseAxios.get(ROUTES_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createStop(stopData) {
  try {
    const response = await baseAxios.post(STOPS_ENDPOINT, {
      ...stopData,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateStop(data) {
  const { _id, stopId, ...rest } = data;
  try {
    const response = await baseAxios.put(`${STOPS_ENDPOINT}/${stopId}`, {
      ...rest,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteStop(stopId) {
  console.log("@@@@@@@", stopId);
  try {
    const response = await baseAxios.delete(`${STOPS_ENDPOINT}/${stopId}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getStops(params = {}) {
  try {
    const response = await baseAxios.get(STOPS_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addStopsToRoute(data) {
  try {
    const response = await baseAxios.put(
      `${ROUTES_ENDPOINT}/${data.routeId}/stops`,
      { stops: data.stops }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function removeStopFromRoute(data) {
  const { routeId, stopId } = data;
  try {
    const response = await baseAxios.delete(
      `${ROUTES_ENDPOINT}/${routeId}/stops`,
      { data: { stopId } }
    );
    return response.data;
  } catch (error) {
    console.error("Full error details:", {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      headers: error.response?.headers,
    });
    return Promise.reject(error);
  }
}

// Repair Management APIs
export async function createRepair(body) {
  try {
    const response = await baseAxios.post(REPAIRS_ENDPOINT, body);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editRepair(data) {
  const { formDataToSubmit } = data;
  try {
    const response = await baseAxios.put(
      `${REPAIRS_ENDPOINT}/${data.id}`,
      formDataToSubmit
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getRepairs(params = {}) {
  try {
    const response = await baseAxios.get(REPAIRS_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Maintenance Management APIs
export async function createMaintenance(body) {
  try {
    const response = await baseAxios.post(MAINTENANCE_ENDPOINT, body);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editMaintenance(data) {
  const { formDataToSubmit } = data;
  try {
    const response = await baseAxios.put(
      `${MAINTENANCE_ENDPOINT}/${data.id}`,
      formDataToSubmit
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMaintenanceRecords(params = {}) {
  try {
    const response = await baseAxios.get(MAINTENANCE_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Filling Station Management APIs
export async function createFillingStation(body) {
  try {
    const response = await baseAxios.post(FILLING_STATIONS_ENDPOINT, {
      ...body,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editFillingStation(data) {
  const { payload } = data;
  try {
    const response = await baseAxios.put(
      `${FILLING_STATIONS_ENDPOINT}/${data.id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFillingStations(params = {}) {
  try {
    const response = await baseAxios.get(FILLING_STATIONS_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Fuel Record Management APIs
export async function createFuelRecord(body) {
  try {
    const response = await baseAxios.post(FUEL_RECORDS_ENDPOINT, body);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editFuelRecord(data) {
  const { formDataToSubmit } = data;
  try {
    const response = await baseAxios.put(
      `${FUEL_RECORDS_ENDPOINT}/${data.id}`,
      formDataToSubmit
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFuelRecords(params = {}) {
  try {
    const response = await baseAxios.get(FUEL_RECORDS_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Fitness Record Management APIs
export async function createFitness(body) {
  try {
    const response = await baseAxios.post(FITNESS_ENDPOINT, body);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function editFitness(data) {
  const { formDataToSubmit } = data;
  try {
    const response = await baseAxios.put(
      `${FITNESS_ENDPOINT}/${data.id}`,
      formDataToSubmit
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFitnessRecords(params = {}) {
  try {
    const response = await baseAxios.get(FITNESS_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getInstituteTransportStats(params = {}) {
  try {
    const response = await baseAxios.get(`${DASHBOARD_ENDPOINT}/stats`, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getFuelExpenses(params = {}) {
  try {
    const response = await baseAxios.post(
      `${DASHBOARD_ENDPOINT}/fuel-expenses`,
      {
        ...params,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getFuelSummary(params = {}) {
  try {
    const response = await baseAxios.post(
      `${DASHBOARD_ENDPOINT}/fuel-summary`,
      {
        params: {
          ...params,
        },
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getBusesFuelDetails(params = {}) {
  try {
    const response = await baseAxios.post(
      `${DASHBOARD_ENDPOINT}/buses-fuel-details`, // Adjust the endpoint as needed
      {
        ...params,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getFuelDueAmount(params = {}) {
  console.log("this fuel due payload", params);
  try {
    const response = await baseAxios.get(`${ENDPOINT}/fuel-due`, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getStudentTransportDetails(params) {
  console.log("admissionNo", params.admissionNo);
  try {
    const response = await baseAxios.get(
      `${STUDTRANSPORT_ENDPOINT}/${params.admissionNo}`,
      { ...params }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Distance Range to Fee Map APIs
export async function createDistRangeToFeeMap(data) {
  try {
    const response = await baseAxios.post(DIST_RANGE_TO_FEE_MAP_ENDPOINT, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateDistRangeToFeeMap(data) {
  const { id, ...rest } = data;
  try {
    const response = await baseAxios.put(
      `${DIST_RANGE_TO_FEE_MAP_ENDPOINT}/${id}`,
      { ...rest }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDistRangeToFeeMaps(params = {}) {
  try {
    const response = await baseAxios.get(DIST_RANGE_TO_FEE_MAP_ENDPOINT, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDistRangeToFeeMapById(id) {
  try {
    const response = await baseAxios.get(
      `${DIST_RANGE_TO_FEE_MAP_ENDPOINT}/${id}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteDistRangeToFeeMap(id) {
  try {
    const response = await baseAxios.delete(
      `${DIST_RANGE_TO_FEE_MAP_ENDPOINT}/${id}`
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Vehicle Log Management APIs
// Bus Check List Apis
export const createPeriodicCheck = async ({ busId, date, parts }) => {
  try {
    const response = await baseAxios.post(PERIODIC_CHECKS, {
      busId,
      date,
      parts,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const updatePeriodicCheck = async ({ id, data }) => {
  console.log(data, "ui");

  try {
    const response = await baseAxios.put(`${PERIODIC_CHECKS}/${id}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const getPeriodicChecks = async ({ busId, startDate, endDate }) => {
  try {
    const response = await baseAxios.get(PERIODIC_CHECKS, {
      params: {
        ...(busId && { busId }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// Vehicle log
export async function createVehicleLog(body) {
  try {
    const response = await baseAxios.post(vehicle_log, body);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getVehicleLogs(params = {}) {
  try {
    const response = await baseAxios.get(vehicle_log, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getVehicleLogById(id) {
  try {
    const response = await baseAxios.get(`${vehicle_log}/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateVehicleLog(data) {
  const { id, ...rest } = data;
  try {
    const response = await baseAxios.put(`${vehicle_log}/${id}`, rest);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteVehicleLog(id) {
  try {
    const response = await baseAxios.delete(`${vehicle_log}/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getVehicleLogsSummary(params = {}) {
  try {
    const response = await baseAxios.get(`/vehicle-logs-summary`, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// Maintenance Sheet Management APIs
export async function createMaintenanceSheet(body) {
  try {
    const response = await baseAxios.post(maintenance_sheet, body);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMaintenanceSheets(params = {}) {
  try {
    const response = await baseAxios.get(maintenance_sheet, {
      params: {
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMaintenanceSheetById(id) {
  try {
    const response = await baseAxios.get(`${maintenance_sheet}/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateMaintenanceSheet(data) {
  const { id, ...rest } = data;
  try {
    const response = await baseAxios.put(`${maintenance_sheet}/${id}`, rest);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteMaintenanceSheet(id) {
  try {
    const response = await baseAxios.delete(`${maintenance_sheet}/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
