export default {
  dashboard: {
    Finance_tab: ["Finance_tab"],
    attendance_tab: ["attendance_tab"],
    admission_tab: ["admission_tab"],
    expenses_tab: ["expenses_tab"],
  },
  organization: {
    zones: ["zones_read", "zones_write"],
    batches: ["batches_read", "batches_write"],
    branches: ["branches_read", "branches_write"],
    user_management: [
      "user_read",
      "user_write",
      "create_pro",
      "password_reset",
      "assign_roles",
      "resrtict_user",
      "user_update",
      "create_recruter",
    ],
    user_activity: ["view_user_activity"],
    role_management: ["role_read", "role_write"],
    logs: ["logs_read"],
    Finance_tab: ["Finance_tab"],
    attendance_tab: ["attendance_tab"],
    admission_tab: ["admission_tab"],
    expenses_tab: ["expenses_tab"],
    createdByAdmissions_tab: ["createdByAdmissions_tab"],
    campaign_collections_tab: ["campaign_collections_tab"],
  },
  payroll_and_hr: {
    staff: ["staff_read", "staff_write"],
    departments: ["departments_read", "departments_write"],
    designations: ["designations_read", "designations_write"],
    qualifications: ["qualifications_read", "qualifications_write"],
    staff_profile: ["staff_profile"],
    staff_shifts: ["shifts_read", "shifts_write"],
    staff_schedule: ["schedule_read", "schedule_write"],
  },
  infra_structure: {
    building_management: ["building_management"],
    seating_allotment: ["seating_allotment"],
  },
  admissions: {
    admission_reports: [
      "view_reports",
      "new_admission_report",
      "batch-wise-admission-report",
    ],

    student: [
      "view_students",
      "single_registration",
      "new_enquiry",
      "new_application",
      "bulk_update",
      "bulk_registrations",
      "print_blank_form",
      "view_admission_process",
      "student_profile",
      "manual_ApplicationNo",
      "manual_AdmissionNo",
      "admission_settings",
      "mobile_view_next_year_admissions",
      "mobile_view_current_year_admissions",
      "bulk_Upgrade",
      "manual_EnquiryNo",
    ],
    assign_counsellor: [
      "assign_counsellor",
      "assign_members",
      "assign_memebrs_&_submembers",
    ],
    followups: ["followups"],
    Assign_Employees_flow: ["assign_employees_flow"],
    cancel_admission: ["cancel_admission"],
    manage_sections: ["sections_read", "sections_write"],
    branch_transfer: ["branch_transfer"],
    applications: [
      "view_applications",
      "withdraw_pro_commissions",
      "reservation_fee_payment",
      "withdraw_current_date_only",
    ],
    status_approvals: ["status_approvals"],
    admission_refund_approvals: ["admission_refund_approvals"],
    student_promotion: ["student_promotion"],
    transfer_approvals: [
      "branch_transfer_approval",
      "within_branch_transfer_approval",
    ],
  },
  settings: {
    academic_year_settings: ["academic_years_read", "academic_years_write"],
    admission_sequence_settings: [
      "admission_sequences_read",
      "admission_sequences_write",
    ],
    application_sequence_settings: [
      "application_sequences_read",
      "application_sequences_write",
    ],

    admission_form_fields_settings: [
      "admission_form_fields_read",
      "admission_form_fields_write",
    ],
    application_form_fields_settings: [
      "application_form_fields_read",
      "application_form_fields_write",
    ],

    admission_academicYear_settings: [
      "admission_academicYear_settings_read",
      "admission_academicYear_settings_write",
    ],
    // academic_setting_attendance: [
    //   "academic_setting_attendance_read",
    //   "academic_setting_attendance_write",
    // ],
    academic_exam_settings: ["lock_uploaded_data_files", "disable_results"],
    // academic_attendance_timings: [
    //   "academic_attendance_timings_read",
    //   "academic_attendance_timings_write",
    // ],
    ay_wise_setting: [
      "ay_wise_branches",
      "ay_wise_batches",
      "excluded_batches",
    ],
    staff_form_fields_settings: [
      "staff_form_fields_read",
      "staff_form_fields_write",
    ],
    finance_settings: [
      "conncession_approval_settings",
      "previous_year_due_settings",
    ],
  },
  inventory: {
    inventory: ["inventory_report", "inventory", "debit_books"],
    inventory_report: ["student_ledger_report", "student_allowance_report"],
  },

  finance: {
    fee_management: ["fee_structures_read", "fee_structures_write"],
    fee_reports: ["view_reports", "download_fee_reports"],
    collection_reports: [
      "view_collections_other_than_campaign",
      "view_campaign_collections",
      "view_collections_and_expenses",
      "view_branch_fee_ledger_report",
      "view_due_reports",
      "view_concession_report",
      "incentive_collection_report",
      "view_branch_summary_report",
      "view_GST_report",
      "view_misc_fee_report",
      "view_campaign_fee_ledger_report",
      "zone_wise_Summary_Report",
    ],
    make_payment: [
      "view_student_txn_history",
      "make_payment",
      "revert_transaction",
      "update_transaction",
      "view_student_txn_history",
      "Proceed_to_pay_button_access",
      "branch_transfer_history",
      "caution_deposit_withdraw",
      "student_ledger",
      "view_raise_concession_requests",
      "student_allowance",
      "student_allowance_credit",
      "student_allowance_debit",
      "student_books_from_inventory",
      "misc_fee_payment",
      "additional_concession_access",
      "custom_fee_adjustment",
    ],
    concession_approvals: [
      "concession_approvals_read",
      "concession_approvals_write",
    ],
    misc_fee_approvals: ["misc_fee_approvals_read", "misc_fee_approvals_write"],
    revertTxn_approvals: [
      "revert_transaction_approvals_read",
      "revert_transaction_approvals_write",
    ],
    updateTxn_approvals: [
      "update_transaction_approvals_read",
      "update_transaction_approvals_write",
    ],
    custom_fee_approvals: [
      "custom_fee_approvals_read",
      "custom_fee_approvals_write",
    ],
    allowance_ledger: [
      "allowance_ledger",
      "allowance_ledger_download",
      "allowance_credit",
      "allowance_debit",
    ],
    misc_fee: [
      "misc_fee",
      "misc_fee_add",
      "misc_fee_edit",
      "misc_feeTypes_view",
      "misc_fee_assign",
      "misc_fee_unassign",
      "transport_fee_assign",
    ],
    collection_accounts: [
      "collection_accounts_read",
      "collection_accounts_write",
    ],
  },
  attendance: {
    class_schedule: ["class_schedule"],
    daily_att: ["give_daily_attendance"],
    att_reports: ["view_att_reports", "view_staff_attendance"],
    att_sms: ["att_sms"],
    zoom_reports: ["view_zoom_reports"],
    att_task_management: ["view_att_tasks"],
    punches_report: ["view_punches_report"],
    single_punches_report: ["view_single_punches_report"],
    sections_status: ["sections_status"],
    custom_time_att_push_notifications: ["custom_time_att_push_notifications"],
  },
  academics: {
    attendance: ["attendance_read", "attendance_write"],
    scheduler: [
      "scheduler_create",
      "Manage_teachers_and_sections",
      "add_courses",
    ],
    assignments: ["assignments_read", "assignments_write"],
    hallTicketEntry: ["hall_Ticket_Entry"],
    reports: [
      "tc_generation_report",
      "documents_submitted_report",
      "in_out_Report",
    ],
  },
  "live-classes": {
    manage_live: ["manage-live_upload"],
    zoom_live: ["zoom-live_upload"],

    videos: [
      "videos_view",
      "video_upload",
      "videos_url_update",
      "start_meeting",
      "delete_meeting",
    ],
  },
  questions: {
    all_question_papers: [
      "view_paper",
      "delete_paper",
      "click_to_hide_rank",
      "click_to_hide_answer_key",
      "paper_Batches_access",
      "see_questions",
      "edit_question",
      "solution_download",
      "codes_paper_download",
      "keys_download",
      "monitor_students",
      "submit_test",
      "upload_key",
      "upload_topics",
      "modify_test",
      "conduct_test",
      "upload_solutions",
      "add_instructions",
    ],
    upload_question_papers: ["upload_question_papers"],
    board_exam: ["board_exam_upload"],
    question_bank: [
      "view_question_bank",
      "edit_question_bank_question_edit",
      "approve_question",
      "reject_question",
      "unverified_question",
      "upload_questions_to_question_bank",
      "verified/unverified_view_questions",
      "verified/unverified_update_questions",
      "verified/unverified_delete_file",
      "verified/unverified_update_topics_of_file",
      "map_files_to_folder",
    ],
    test_creation: [
      // "create_new_test",
      "Test_creation",
      // "edit_created_test",
      // "delete_created_test",
      // "onesaz_question_bank_access",
      // "organization_question_bank_access",
      // "save_created_test",
      // "submit_created_test",
    ],
    topic_selection: ["topic_selection"],
  },
  analysis: {
    allTests: ["download_tests_result"],
    progressReport: ["view_progress_Report"],
    studentAnswers: ["student_Answers_getTests_by_userId"],
    toppersReport: ["view_toppers_report"],
    boardExamAnalysis: ["view_Board_Exam_Analysis"],
    toppers_errors_list: [
      "view_toppers_Errors_list",
      // "download_toppers_errors_list",
      // "view_toppers_error_list_in_compact",
    ],
    campus_errors_list: ["view_campus_Errors_list"],
    least_attempted_questions: [
      "view_least_attempted_questions_report",
      // "download_least_attempted_report",
    ],
    view_test_test_comparison: ["view_test_test_comparison"],
    cwu_count_analysis: ["view_cwu_count_list"],
    cumulativeresults: ["view_cumulativeresults"],
  },
  communications: {
    notifications: ["send_notification", "view_notifications"],
    // app_notifications: ["app_notifications"],
    sms_templates: ["save_credentials", "send_SMS"],
    send_results: ["send_result_sms"],
    templates: ["templates_creation"],
    sms_reports: ["sms-delivery-report"],
    parent_credentials: ["parent_credentials"],
    parent_concerns: ["parent_concerns"],
  },
  "offline-utilities": {
    omr_scan: ["view_scanned_omrs", "go_to_scan_omr", "reupload"],
    upload_results: ["Upload_offline_data_files"],
    exam_summary: ["exam_summary"],
  },
  hostel: {},
  building: {
    building_creatiom: [
      "building_structures_read",
      "building_structures_write",
    ],
  },
  "expense-management": {
    petty_cash: ["credit_cash", "add_to_ledger"],
    expenses_report: [
      "expenses_report",
      "credit_edit_action",
      "credit_print_action",
      "debit_edit_action",
      "debit_print_action",
      "debit_delete_action",
      "credit_delete_action",
    ],
    opening_and_closing_expenses_report: [
      "opening_and_closing_expenses_report",
    ],
    branches_report: ["branches_report"],
  },
  transport: {
    dashboard: ["dashboard_list"],
    buses: ["create_bus", "update_bus", "bus_list", "enable_fields_to_update"],
    renewals: ["renewals_list"],
    assign_buses_to_route: ["assign_buses_to_route"],
    filling_stations: [
      "create_filling_station",
      "update_filling_station",
      "filling_station_list",
    ],
    fuel_details: [
      "create_fuel_detail",
      "update_fuel_detail",
      "fuel_detail_list",
    ],
    repair_breakdowns: ["create_repair", "update_repair", "repair_list"],
    fitness: ["create_fitness", "update_fitness", "fitness_list"],
    maintenance_history: [
      "create_maintenance",
      "update_maintenance",
      "maintenance_list",
    ],
    routes: [
      "create_route",
      "update_route",
      "route_list",
      "delete_route",
      "clone_route",
    ],
    assign_stop_to_route: ["assign_stop_to_route", "stop_route_list"],
    stops: ["create_stop", "update_stop", "stop_list"],
    assign_staff_to_bus: ["assign_staff", "staff_list"],
    fees: ["assign_fee", "fee_list"],
    bus_check_list: ["bus_check_list"],
    maintenance_sheet: [
      "create_maintenance_sheet",
      "update_maintenance_sheet",
      "maintenance_sheet_list",
    ],
    vehicle_log: [
      "create_vehicle_log",
      "update_vehicle_log",
      "vehicle_log_list",
    ],
  },
};
