// React and Redux imports
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import { enqueueSnackbar } from "notistack";

// MUI components and utilities
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Custom hooks
import useFetch from "hooks/useFetch";
import useCashfree from "hooks/useCashfree";

// Apis
import { initPayment } from "APIs/finance/feePlan";

// Redux selectors
import { selectAppDialog } from "redux/app/app.selectors";
import { selectFromInstituteConfig } from "redux/settings/settings.selector";

// Redux actions
import { fetchPosTerminalsStart } from "redux/finance/makePayment/makePayment.slice";

// Custom components
import DialogV1 from "components/CustomDialog/v1";
import SoftDataGrid from "myComponents/SoftDataGrid";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import SoftButton from "myComponents/SoftButton";
import SoftInput from "myComponents/SoftInput";

// Assets and theme
import colors from "assets/theme/base/colors";

// Local imports
import columns, { checkoutUtilsData } from "./columns";
import CashPayment from "./CashPayment";
import CustomForm from "./CustomPayment";
import CheckPaymentForm from "./ChequePayment";

const FooterComponent = ({ total }) => (
  <SoftBox sx={{ p: 1.5, display: "flex" }}>
    <SoftTypography variant="h6">Total: {total}/-</SoftTypography>
  </SoftBox>
);

const CheckOutDialog = ({ open, handleClose }) => {
  const instituteId = store.get("instituteId");
  const dispatch = useDispatch();
  const { dialogData } = useSelector(selectAppDialog);
  const [l0, e0, d0, p0, r0] = useFetch(initPayment);
  const [remarks, setRemarks] = useState("");
  const [means, setMeans] = useState(null);
  const [stage, setStage] = useState("SELECT_MEANS");
  const [directEntryDetails, setDirectEntryDetails] = useState({});
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);
  const regConf = useSelector(selectFromInstituteConfig("regConf"));
  const integratedPgs = useSelector(
    selectFromInstituteConfig("paymentGateways")
  );
  const initiateCashfreePayment = useCashfree(integratedPgs);
  // const collectionAccountToTerminalMap = useSelector(selectPosTerminals);

  const { studentDetails, summary, gstInfo = {} } = dialogData;
  const { paymentGateways, offCash, offCC, offNB, offSWIPE, offDC, offCQ } =
    checkoutUtilsData;

  const feeTypes = Object.keys(summary || {});
  const sources = ["CAMPAIGN", "From Recruiter"];

  const allowedOffline = useMemo(() => {
    if (
      feeTypes.includes("application_fee") ||
      feeTypes.includes("reservation_fee")
    ) {
      if (
        sources.includes(studentDetails?.source) &&
        (store.get("user")?.designation === "ACCOUNTANT" ||
          store.get("user")?.designation === "CAMPAIGN")
      ) {
        const allowed = [];
        const configModes = regConf?.payModes || [];

        if (configModes.includes("CA")) allowed.push(offCash);
        if (configModes.includes("CC")) allowed.push(offCC);
        if (configModes.includes("NB")) allowed.push(offNB);
        if (configModes.includes("SWIPE")) allowed.push(offSWIPE);
        if (configModes.includes("DC")) allowed.push(offDC);
        if (configModes.includes("CQ")) allowed.push(offCQ);
        return allowed;
      }
    }
    return [offCash, offCC, offNB, offDC, offCQ];
  }, [feeTypes, studentDetails]);

  const feeItems = Object.keys(summary || {}).map((item) => ({
    feeType: item,
    amount: summary[item],
    isGST: !!gstInfo[item],
    includingGST: gstInfo[item]?.includingGST,
    gstAmount: gstInfo[item]?.amount,
  }));

  const summaryAmt = Object.keys(summary || {}).reduce(
    (a, c) => a + Number(summary[c]),
    0
  );

  const gstAmount = Object.values(gstInfo).reduce((a, c) => {
    const { amount, includingGST } = c;
    return !includingGST ? a + amount : a;
  }, 0);

  const rows = Object.keys(summary || {}).map((item, i) => {
    const gst = gstInfo?.[item] || {};
    const { includingGST, amount } = gst;

    const returnObj = {
      id: i,
      feeType: item,
      payingAmount: summary[item],
      amount: summary[item],
      exclusiveGST: 0,
      includingGST: 0,
    };
    if (includingGST) {
      returnObj.includingGST = amount || 0;
    } else {
      returnObj.exclusiveGST = amount || 0;
    }

    return returnObj;
  });

  const reset = () => {
    setRemarks("");
    setMeans(null);
    setStage("SELECT_MEANS");
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const { mode } = directEntryDetails || {};
    if (mode !== value) {
      setDirectEntryDetails((prev) => ({
        ...prev,
        mode: value,
      }));
    } else {
      setDirectEntryDetails((prev) => ({
        ...prev,
        mode: null,
      }));
    }
  };

  const getNextButtonText = () => {
    if (stage === "SELECT_MEANS") {
      return "Next";
    }
    if (stage === "SELECT_PAYMENT_METHOD_FOR_DIRECT_ENTRY") {
      return "Next";
    }

    if (stage === "SELECT_PAYMENT_GATEWAY") {
      return "Next";
    }
    if (stage === "SELECT_TERMINAL") {
      return "Next";
    }
    if (stage === "CONFIRMATION" && means === "PAYMENT_GATEWAY") {
      return "Pay Now";
    }
    if (stage === "CONFIRMATION" && means === "POS") {
      return "Pay Now";
    }
    if (stage === "CONFIRMATION" && means === "DIRECT_ENTRY") {
      return "Confirm";
    }
    return "Proceed to Pay";
  };

  const handleBackOrCancelClick = () => {
    if (stage === "SELECT_MEANS") {
      handleClose();
    } else {
      reset();
    }
  };

  const renderSelectMeans = () => (
    <SoftBox>
      <SoftTypography
        gutterBottom
        variant="h5"
        fontWeight="medium"
        color="info"
        pl={2}
      >
        Select Payment type
      </SoftTypography>
      <SoftBox pl={4}>
        <SoftBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={means === "DIRECT_ENTRY"}
                onChange={(e) => {
                  setMeans(e.target.checked ? "DIRECT_ENTRY" : null);
                }}
                name="DIRECT_ENTRY"
              />
            }
            label="Direct Entry"
          />
        </SoftBox>
        <SoftBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={means === "POS"}
                onChange={(e) => {
                  setMeans(e.target.checked ? "POS" : null);
                }}
                name="POS"
              />
            }
            label="POS"
          />
        </SoftBox>
        <SoftBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={means === "PAYMENT_GATEWAY"}
                onChange={(e) => {
                  setMeans(e.target.checked ? "PAYMENT_GATEWAY" : null);
                }}
                name="PAYMENT_GATEWAY"
              />
            }
            label="Payment Gateway"
          />
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );

  const renderDirectEntryMethods = () => (
    <SoftBox>
      <SoftTypography
        gutterBottom
        variant="h5"
        fontWeight="medium"
        color="info"
        pl={2}
      >
        Select Payment Method
      </SoftTypography>
      <SoftBox pl={4}>
        {allowedOffline.map((a1) => (
          <SoftBox key={a1.mode} pl={2}>
            <SoftBox>
              <FormControlLabel
                control={
                  <Radio
                    checked={
                      a1.mode === directEntryDetails?.mode &&
                      means === "DIRECT_ENTRY"
                    }
                    onChange={handleChange}
                    color="info"
                    value={a1.mode}
                    name={a1.label}
                    size="small"
                  />
                }
                label={
                  <SoftBox display="flex" alignItems="center">
                    <Icon sx={{ color: a1.color }} fontSize="medium">
                      {a1.icon}
                    </Icon>
                    &nbsp;
                    {a1.label}
                  </SoftBox>
                }
                sx={{ display: "flex", alignItems: "center" }}
              />
            </SoftBox>
            {a1.mode === directEntryDetails?.mode && a1.mode === "CA" ? (
              <CashPayment
                values={directEntryDetails}
                setValues={setDirectEntryDetails}
              />
            ) : null}
            {a1.mode === directEntryDetails?.mode && a1.mode === "CQ" ? (
              <CheckPaymentForm
                values={directEntryDetails}
                setValues={setDirectEntryDetails}
              />
            ) : null}
            {a1.mode === directEntryDetails?.mode &&
            (a1.mode === "NB" || a1.mode === "CC" || a1.mode === "DC") ? (
              <CustomForm
                values={directEntryDetails}
                setValues={setDirectEntryDetails}
              />
            ) : null}
          </SoftBox>
        ))}
      </SoftBox>
    </SoftBox>
  );

  const renderConfirmation = () => (
    <SoftBox>
      <SoftTypography
        gutterBottom
        variant="h5"
        fontWeight="medium"
        color="info"
        pl={2}
      >
        Confirmation
      </SoftTypography>
      <SoftBox pl={4}>
        <SoftBox>
          <SoftTypography
            variant="body2"
            fontWeight="medium"
            color="text"
            pl={2}
          >
            Payment Means: {means}
          </SoftTypography>
          <SoftTypography
            variant="body2"
            fontWeight="medium"
            color="text"
            pl={2}
          >
            Are you sure you want to proceed with the payment?
          </SoftTypography>
          {remarks ? (
            <SoftTypography
              variant="body2"
              fontWeight="medium"
              color="text"
              pl={2}
            >
              Remarks: {remarks}
            </SoftTypography>
          ) : null}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );

  const activePgs = useMemo(
    () => paymentGateways?.filter((pg) => integratedPgs?.includes(pg.id)) || [],
    [integratedPgs]
  );

  const renderPaymentGateways = () => {
    if (!activePgs || activePgs.length === 0 || activePgs.length === 1) {
      return null;
    }

    return (
      <SoftBox>
        <SoftTypography
          gutterBottom
          variant="h5"
          fontWeight="medium"
          color="info"
          pl={2}
        >
          Select Payment Gateway
        </SoftTypography>
        {activePgs.map((pg) => (
          <SoftBox key={pg.id} pl={4}>
            <SoftBox pl={2} mb={1}>
              <FormControlLabel
                control={
                  <Radio
                    checked={pg.id === selectedPaymentGateway}
                    onChange={(e) => {
                      setSelectedPaymentGateway(
                        e.target.checked ? pg.id : null
                      );
                    }}
                    color="info"
                    value={pg.id}
                    name={pg.name}
                    size="small"
                  />
                }
                label={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox
                      p={0.5}
                      borderRadius="sm"
                      sx={{
                        ...(pg.id === "1pay"
                          ? { backgroundColor: colors.newUi.info }
                          : {}),
                      }}
                    >
                      <img src={pg.logo} alt={pg.name} width="30" height="30" />
                    </SoftBox>
                    &nbsp;
                    {pg.name}
                  </SoftBox>
                }
                sx={{ display: "flex", alignItems: "center" }}
              />
            </SoftBox>
            {pg.id === selectedPaymentGateway ? (
              <SoftBox pl={2}>
                <SoftTypography
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                >
                  {pg.description}
                </SoftTypography>
              </SoftBox>
            ) : null}
          </SoftBox>
        ))}
      </SoftBox>
    );
  };

  const handleConfirmation = () => {
    const data = {
      sid: studentDetails?._id,
      feeItems,
      amount: summaryAmt,
      gst: gstAmount,
      branchId: studentDetails?.branchId,
      customerId: studentDetails?.customerId || studentDetails?.admissionNo,
      hostUrl: `${window.location.origin}${window.location.pathname}`,
      means,
      timestamp: new Date().getTime(),
      academicYear:
        studentDetails?.current_txn_ay || studentDetails?.current_ay,
    };
    if (summary.application_fee) {
      data.applicationNo = studentDetails?.applicationNo;
    }

    if (means === "DIRECT_ENTRY") {
      const { mode } = directEntryDetails || {};
      data.directEntryPaymentMode = mode;
      data.message = `${mode} payment recorded successfully`;
      if (mode === "account_transfer") {
        data.paymentMode = "NB";
        data.bankRefId = directEntryDetails?.RefNo;
        data.chequeDate = directEntryDetails?.chequeDate;
      }

      if (mode === "SWIPE") {
        data.bankRefId = directEntryDetails.RefNo;
      }
    }
    if (means === "PAYMENT_GATEWAY") {
      data.gatewayName = selectedPaymentGateway;
    }
    if (remarks) data.remarks = remarks;
    p0(data);
  };

  const handleNextActionClick = () => {
    if (stage === "SELECT_MEANS") {
      if (means === "DIRECT_ENTRY") {
        setStage("SELECT_PAYMENT_METHOD_FOR_DIRECT_ENTRY");
      }

      if (means === "PAYMENT_GATEWAY") {
        if (integratedPgs && integratedPgs.length > 1) {
          setStage("SELECT_PAYMENT_GATEWAY");
        } else if (integratedPgs && integratedPgs.length === 1) {
          const selectedPg = integratedPgs[0];
          setSelectedPaymentGateway(selectedPg);
          setStage("CONFIRMATION");
        } else {
          enqueueSnackbar("No payment gateways integrations found", {
            variant: "warning",
            autoHideDuration: 2000,
          });
        }
      }

      if (means === "POS") {
        setStage("SELECT_TERMINAL");
      }
    }

    if (stage === "SELECT_PAYMENT_METHOD_FOR_DIRECT_ENTRY") {
      if (directEntryDetails?.mode) {
        setStage("CONFIRMATION");
      } else {
        enqueueSnackbar("Please select a payment method", {
          variant: "warning",
          autoHideDuration: 2000,
        });
      }
    }
    if (stage === "SELECT_PAYMENT_GATEWAY") {
      if (selectedPaymentGateway) {
        setStage("CONFIRMATION");
      } else {
        enqueueSnackbar("Please select a payment gateway", {
          variant: "warning",
          autoHideDuration: 2000,
        });
      }
    }

    if (stage === "CONFIRMATION") {
      handleConfirmation();
    }
  };

  useEffect(() => {
    dispatch(fetchPosTerminalsStart({ instituteId }));
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (d0) {
      if (d0?.status === "SUCCESS") {
        if (
          means === "PAYMENT_GATEWAY" &&
          selectedPaymentGateway === "cashfree"
        ) {
          if (!d0?.payment_session_id) {
            enqueueSnackbar("Payment session id not found", {
              variant: "error",
              autoHideDuration: 2000,
            });
          } else {
            initiateCashfreePayment(d0.payment_session_id);
          }
        }
        handleClose();
      }

      if (d0?.status === "FAILURE") {
        enqueueSnackbar(d0?.message, {
          variant: "warning",
          autoHideDuration: 2000,
        });
      }
    }

    if (e0) {
      enqueueSnackbar(e0?.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }

    return () => {
      r0();
    };
  }, [d0, e0]);

  return (
    <DialogV1
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      title="Payment Checkout"
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SoftDataGrid
              rows={rows}
              columns={columns}
              FooterComponent={FooterComponent}
              footerProps={{ total: summaryAmt + gstAmount }}
              autoHeight
              showCellRightBorder
              showColumnRightBorder
              elevation={0}
              containerStyles={{
                backgroundColor: colors.grey[100],
              }}
            />
            {stage === "CONFIRMATION" ? (
              <SoftBox mt={2}>
                <SoftTypography
                  gutterBottom
                  variant="body2"
                  fontWeight="medium"
                >
                  Remarks
                </SoftTypography>
                <SoftInput
                  multiline
                  rows={4}
                  name="remarks"
                  fullWidth
                  placeholder="Enter remarks"
                  variant="outlined"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  required
                />
              </SoftBox>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mt: 1 }} />
            {stage === "SELECT_MEANS" ? renderSelectMeans() : null}
            {stage === "SELECT_PAYMENT_METHOD_FOR_DIRECT_ENTRY"
              ? renderDirectEntryMethods()
              : null}
            {stage === "SELECT_PAYMENT_GATEWAY"
              ? renderPaymentGateways()
              : null}
            {stage === "CONFIRMATION" ? renderConfirmation() : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SoftButton
          onClick={handleBackOrCancelClick}
          color="secondary"
          variant="plain"
          disabled={l0}
        >
          Cancel
        </SoftButton>
        <SoftButton
          onClick={handleNextActionClick}
          color="info"
          size="small"
          disabled={l0}
        >
          {getNextButtonText()}
        </SoftButton>
      </DialogActions>
    </DialogV1>
  );
};

export default CheckOutDialog;
