import flatten from "lodash/flatten";
// icon
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
// Helpers
import lazy from "helpers/LazyImport";
// body layout
import Container from "layout/simpleContainer";
// features
import features from "config/features";
// view
import AcademicSetting from "views/Settings/AcademicsSettings";
import CreateForm from "views/Settings/FinanceSettings/ConcessionApprovalSettings/Components/CreateConcessionForm/Form";
// modules
const AdmissionSequenceSettings = lazy(() =>
  import("../../views/Settings/AdmissionSettings/AdmissionNumberSequence")
);
// const AttendanceSetting = lazy(() =>
//   import("../../views/Settings/AcademicsSettings/AttendanceSettings")
// );
const AdmissionSettings = lazy(() =>
  import("../../views/Settings/AdmissionSettings/index")
);
const FinanceSettings = lazy(() =>
  import("../../views/Settings/FinanceSettings")
);
// const HostelSettings = lazy(() =>
//   import("../../views/Settings/HostelSettings")
// );
// const HostelBuildingSettings = lazy(() =>
//   import("../../views/Settings/HostelSettings/HostelBuildingSettings")
// );
// const CreateHostelForm = lazy(() =>
//   import(
//     "../../views/Settings/HostelSettings/HostelBuildingSettings/CreateForm"
//   )
// );
// const HostelBuildingFloorSettings = lazy(() =>
//   import(
//     "../../views/Settings/HostelSettings/HostelBuildingSettings/FloorsDetails"
//   )
// );
const ConcessionApprovalSettings = lazy(() =>
  import("../../views/Settings/FinanceSettings/ConcessionApprovalSettings")
);

// const ExpensesSettings = lazy(() =>
//   import("../../views/Settings/ExpensesSettings")
// );
// const PettyCashSettings = lazy(() =>
//   import("../../views/Settings/ExpensesSettings/PettyCashSettings")
// );
// const PaymentVoucherForm = lazy(() =>
//   import(
//     "../../views/Settings/ExpensesSettings/PettyCashSettings/PettyCashApprovalSettings/PaymentVoucherForm"
//   )
// );
const AdmissionFormFields = lazy(() =>
  import("../../views/Settings/AdmissionSettings/AdmissionFormFields")
);
const StaffFormFields = lazy(() =>
  import("../../views/Settings/HrPayRollSettings/staffFormFields")
);
const HrPayRollSettings = lazy(() =>
  import("../../views/Settings/HrPayRollSettings")
);

const AdmissionYearSettings = lazy(() =>
  import("../../views/Settings/AdmissionSettings/AdmissionYearSettings")
);
const OrganizationSettings = lazy(() =>
  import("../../views/Settings/OrganizationSettings")
);
// const AcademicYearSetting = lazy(() =>
//   import("../../views/Settings/OrganizationSettings/AcademicYearSetting")
// );
// const AcademicYearMapping = lazy(() =>
//   import("../../views/Settings/OrganizationSettings/AcademicYearMapping")
// );
const ExamSettings = lazy(() =>
  import("../../views/Settings/AcademicsSettings/ExamSettings")
);

// const AttendanceTimings = lazy(() =>
//   import("../../views/Settings/AcademicsSettings/AttendanceTimings")
// );
// const ParentPortalAccessSettings = lazy(() =>
//   import("../../views/Settings/OrganizationSettings/ParentPortalAccessSettings")
// );
const AyBranchesAndBatches = lazy(() =>
  import("../../views/Settings/OrganizationSettings/AyBranchesAndBatches")
);

const PreviousDuesProiority = lazy(() =>
  import("../../views/Settings/FinanceSettings/PreviousDuesPriority")
);
export const ApplicationNoRanges = lazy(() =>
  import("../../views/Finance/FeePlan/ApplicationNoRanges")
);

const Card = lazy(() => import("../../components/MobileSubModules"));

export default {
  component: <Container />,
  title: "Settings",
  basePath: "app",
  path: "settings",
  index: <Card path="settings" />,
  icon: <SettingsRoundedIcon />,
  features: flatten(Object.values(features.settings)),
  children: [
    {
      component: <Container />,
      title: "Organization Settings",
      basePath: "settings",
      path: "organization-settings",
      index: <OrganizationSettings />,
      features: features.settings.academic_year_settings,
      children: [
        // {
        //   component: <AcademicYearMapping />,
        //   title: "Academic Year mapping",
        //   basePath: "organization-settings",
        //   path: "academic-year-mapping",
        //   features: features.settings.academic_year_settings,
        // },
        // {
        //   component: <AcademicYearSetting />,
        //   title: "Academic Year setting",
        //   basePath: "organization-settings",
        //   path: "academic-year-setting",
        //   features: features.settings.academic_year_settings,
        // },
        // {
        //   component: <ParentPortalAccessSettings />,
        //   title: "Parent portal settings",
        //   basePath: "organization-settings",
        //   path: "parent-portal-setting",
        //   // features: features.settings.academic_year_settings,
        // },
        {
          component: <AyBranchesAndBatches />,
          title: "AY wise settings",
          basePath: "organization-settings",
          path: "ay-wise-setting",
          features: [...features.settings.ay_wise_setting],
        },
      ],
    },
    {
      component: <Container />,
      index: <AdmissionSettings />,
      title: "Admission Settings",
      basePath: "settings",
      path: "admission",
      features: [
        ...features.settings.admission_form_fields_settings,
        ...features.settings.admission_sequence_settings,
        ...features.settings.application_sequence_settings,
        ...features.settings.admission_academicYear_settings,
      ],
      children: [
        {
          component: <AdmissionSequenceSettings />,
          title: "Admission Number Settings",
          basePath: "admission",
          path: "admission-sequence-number",
          features: features.settings.admission_sequence_settings,
        },
        {
          component: <ApplicationNoRanges />,
          title: "Application No Ranges",
          basePath: "admission",
          path: "application-no-ranges",
          features: features.settings.application_sequence_settings,
        },
        {
          component: <AdmissionFormFields />,
          title: "Admission Form Fields",
          basePath: "admission",
          path: "admission-form-fields",
          features: features.settings.admission_form_fields_settings,
        },
        {
          component: <AdmissionFormFields />,
          title: "Application Form Fields",
          basePath: "admission",
          path: "application-form-fields",
          features: features.settings.application_form_fields_settings,
        },
        {
          component: <AdmissionFormFields />,
          title: "Enquiry Form Fields",
          basePath: "admission",
          path: "enquiry-form-fields",
          features: features.settings.enquiry_form_fields_settings,
        },
        {
          component: <AdmissionYearSettings />,
          title: "Admission Year Settings",
          basePath: "admission",
          path: "admission-year-settings",
          features: features.settings.admission_academicYear_settings,
        },
      ],
    },
    {
      component: <Container />,
      title: "Academic Settings",
      basePath: "settings",
      path: "academics",
      index: <AcademicSetting />,
      features: [
        ...features.settings.academic_exam_settings,
        // ...features.settings.academic_attendance_timings,
      ],
      children: [
        // {
        //   component: <AttendanceSetting />,
        //   title: "Attendance Setting",
        //   basePath: "academics",
        //   path: "setting-attendance",
        //   features: features.settings.academic_setting_attendance,
        // },
        {
          component: <ExamSettings />,
          title: "Exam Settings",
          basePath: "academics",
          path: "exam-settings",

          features: features.settings.academic_exam_settings,
        },

        // {
        //   component: <AttendanceTimings />,
        //   title: "Attendance Timings Settings",
        //   basePath: "academics",
        //   path: "attendance-timings",

        //   features: features.settings.academic_attendance_timings,
        // },
      ],
    },
    {
      component: <Container />,
      title: "Hr Pay Roll Settings",
      basePath: "settings",
      path: "hr-pay-roll-settings",
      index: <HrPayRollSettings />,
      features: [...features.settings.staff_form_fields_settings],
      children: [
        {
          component: <StaffFormFields />,
          title: "Staff Form Settings",
          basePath: "hr-pay-roll-settings",
          path: "staff-form-fields",
          features: features.settings.staff_form_fields_settings,
        },
      ],
    },

    {
      component: <Container />,
      title: "Finance Settings",
      basePath: "settings",
      path: "finance-settings",
      index: <FinanceSettings />,
      // authorizedRoles: ["finance-settings", roles.organization.SUPER_ADMIN],
      features: [...features.settings.finance_settings],

      children: [
        {
          component: <ConcessionApprovalSettings />,
          title: "Concession Approval settings",
          basePath: "finance-settings",
          path: "concession-approval-settings",
          authorizedRoles: [],
          features: ["conncession_approval_settings"],
        },
        {
          component: <PreviousDuesProiority />,
          title: "Previous Dues Priority",
          basePath: "finance-settings",
          path: "previous-dues-prority",
          authorizedRoles: [],
          faetures: ["previous_year_due_settings"],
        },
        {
          component: <CreateForm />,
          title: "Concession settings form                          ",
          basePath: "concession-approval-settings",
          path: "create",
          authorizedRoles: [],
          features: ["conncession_approval_settings"],
        },
      ],
    },
    // {
    //   component: <Container />,
    //   title: "Expenses Settings",
    //   basePath: "settings",
    //   path: "expenses-settings",
    //   index: <ExpensesSettings />,
    //   authorizedRoles: ["expenses-settings", roles.organization.SUPER_ADMIN],
    //   children: [
    //     {
    //       component: <PettyCashSettings />,
    //       title: "Petty Cash  Settings",
    //       basePath: "expenses-settings",
    //       path: "petty-cash-settings",
    //       authorizedRoles: [],
    //     },
    //     {
    //       component: <PaymentVoucherForm />,
    //       title: "Attendance Setting",
    //       basePath: "petty-cash-settings",
    //       path: "create",
    //       authorizedRoles: [],
    //     },
    //   ],
    // },
    // {
    //   component: <Container />,
    //   title: "Hostel Settings",
    //   basePath: "settings",
    //   path: "hostel-settings",
    //   index: <HostelSettings />,
    //   authorizedRoles: ["hostel-settings", roles.organization.SUPER_ADMIN],
    //   children: [
    //     {
    //       component: <HostelBuildingSettings />,
    //       title: "hostel-building-settings",
    //       basePath: "hostel-settings",
    //       path: "hostel-building-settings",
    //       authorizedRoles: [],
    //     },
    //     {
    //       component: <CreateHostelForm />,
    //       title: "hostel-building-settings",
    //       basePath: "hostel-settings",
    //       path: "create",
    //       authorizedRoles: [],
    //     },
    //   ],
    // },
  ],
};
