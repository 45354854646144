export default {
  organization: {
    SUPER_ADMIN: "SUPER_ADMIN",
    FINANCE_HEAD: "FINANCE_HEAD",
    HR_MANAGER: "HR_MANAGER",
    FINANCE_MANAGER: "FINANCE_MANAGER",
    FINANCIAL_CONTROLLER: "FINANCIAL_CONTROLLER",
    BRANCH_ACCOUNTING_HEAD: "BRANCH_ACCOUNTING_HEAD",
    CALL_CENTER_HEAD: "CALL_CENTER_EXECUTIVE",
    ACADEMIC_INCHARGE: "ACADEMIC_INCHARGE",
    PURCHASES: "PURCHASES",
    TRANSPORT_TRACKER: "TRANSPORT_TRACKER",
    BIOMETRIC_ADMIN: "BIOMETRIC_ADMIN",
    STORES: "STORES",
    DIRECTOR: "DIRECTOR",
    DEAN_OF_ACADEMIC: "DEAN_OF_ACADEMIC",
    ONESAZ_SYS_ADMIN: "ONESAZ_SYS_ADMIN",
    INVENTORY_MANAGEMENT: "INVENTORY_MANAGEMENT",
    COMPUTER_INCHARGE: "COMPUTER_INCHARGE",
    CHIEF_EXECUTIVE_OFFICER: "CHIEF_EXECUTIVE_OFFICER",
    EXAMS_CELL: "EXAMS_CELL",
    CONCESSION_HEAD: "CONCESSION_HEAD",
    CZI: "CZI",
    GENERAL_MANAGER: "GENERAL_MANAGER",
    CAMPAIGN: "CAMPAIGN",
  },
  zonal: {
    ZONAL_HEAD: "ZONAL_HEAD",
    ZONAL_FINANCE_HEAD: "ZONAL_FINANCE_HEAD",
    ZONAL_ASSISTANT: "ZONAL_ASSISTANT",
    ACADEMIC_ADVISOR: "ACADEMIC_ADVISOR",
    SUB_ZONAL_HEAD: "SUB_ZONAL_HEAD",
    CHIEF_EXECUTIVE_OFFICER: "CHIEF_EXECUTIVE_OFFICER",
  },
  branch: {
    PRINCIPAL: "PRINCIPAL",
    COMPUTER_OPERATOR: "COMPUTER_OPERATOR",
    ACCOUNTANT: "ACCOUNTANT",
    ATTENDANCE_INCHARGE: "ATTENDANCE_INCHARGE",
    LIBRARIAN: "LIBRARIAN",
    INVENTORY_SALES: "INVENTORY_SALES",
    ADMISSION_OFFICER: "ADMISSION_OFFICER",
    ACCOUNTANT_HEAD: "ACCOUNTANT_HEAD",
    TRANSPORT_INCHARGE: "TRANSPORT_INCHARGE",
    BRANCH_ACADEMIC_INCHARGE: "BRANCH_ACADEMIC_INCHARGE",
    ADMISSION_CELL: "ADMISSION_CELL",
    CENTRAL_OFFICE_BRANCH: "CENTRAL_OFFICE_BRANCH",
    ADMIN: "ADMIN",
    HOD: "HOD",
    SECTION_HEAD: "SECTION_HEAD",
    STORE_INCHARGE: "STORE_INCHARGE",
    BRANCH_DEAN_OF_ACADEMICS: "BRANCH_DEAN_OF_ACADEMICS",
    RECEPTION: "RECEPTION",
    FRONT_OFFICE: "FRONT_OFFICE",
    ACADEMIC: "ACADEMIC",
    VISITOR_MANAGEMENT: "VISITOR_MANAGEMENT",
    COUNSELLOR: "COUNSELLOR",
    CASHIER: "CASHIER",
    INVENTORY: "INVENTORY",
    FLOOR_SUPERVISOR: "FLOOR_SUPERVISOR",
    CHIEF_MARKETING_OFFICER: "CHIEF_MARKETING_OFFICER",
    MEDICAL_INCHARGE: "MEDICAL_INCHARGE",
    CENTER_HEAD: "CENTER_HEAD",
    ADMINISTRATION: "ADMINISTRATION",
    VICE_PRINCIPAL: "VICE_PRINCIPAL",
    ONLINE_EXAMS: "ONLINE_EXAMS",
    PARENT_SUPPORT: "PARENT_SUPPORT",
    IT_OR_ADMIN: "IT_OR_ADMIN",
    FINANCE: "FINANCE",
    COORDINATORS: "COORDINATORS",
    ACCOUNTS_EXECUTIVE: "ACCOUNTS_EXECUTIVE",
    GRADE_BOOK: "GRADE_BOOK",
    AUDITOR: "AUDITOR",
    HOSTEL: "HOSTEL",
    TEACHER: "TEACHER",
    PRO: "PRO",
    TELECALLER: "TELECALLER",
    HOUSE_KEEPING: "HOUSE KEEPING",
    SCAVENGER: "SCAVENGER",
    SECURITY: "SECURITY",
    WATCHMAN: "WATCHMAN",
    OFFICE_BOY: "OFFICE BOY",
    RECRUITER: "RECRUITER",
  },
};
