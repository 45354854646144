import { useSelector } from "react-redux";
import store from "store";
// import store from "store";
import union from "lodash/union";
import mapValues from "lodash/mapValues";
// selectors
import { selectBranchesList } from "redux/organization/branches/branches.selectors";
import { selectClasses } from "redux/static/static.selectors";
import { selectBatchesMap } from "redux/organization/batches/batches.selectors";
import { selectEmployees } from "redux/app/app.selectors";
// utils
import { sortArrayOfObjectsByAProp } from "utils/general.utils/array.utils";
import {
  getObjectValues,
  getObjectKeys,
  objectExists,
} from "utils/general.utils/object.utils";
import { selectCounsellors } from "redux/organization/userManagement/userManagement.selectors";
import selectAllExams from "redux/exams/exams.selectors";

const makeArr = (val) =>
  val instanceof Array ? val : [val].filter((item) => !!item);
const expensesCond = window.location.hostname.includes("expenses");

export const filterBranchesByZone = (zoneOrArrayOfZones) => {
  const branchesList = useSelector(selectBranchesList)?.filter((item) =>
    expensesCond ? !!item : objectExists(item?.batchesAndSections)
  );
  if (!zoneOrArrayOfZones) return branchesList;
  const branchesMap = sortArrayOfObjectsByAProp(branchesList, "zone");
  return makeArr(zoneOrArrayOfZones).reduce((a, c) => {
    const branchesOfZone = branchesMap[c._id] || [];
    return [...a, ...branchesOfZone];
  }, []);
};

export const filterBranchesIfContainsBatch = (batchesOrArrayOfBatches) => {
  // const branchesList = useSelector(selectBranchesList);
  const branchesList = useSelector(selectBranchesList)?.filter((item) =>
    expensesCond ? !!item : objectExists(item?.batchesAndSections)
  );
  const batchesMap = useSelector(selectBatchesMap);
  const list = makeArr(batchesOrArrayOfBatches);

  return branchesList?.reduce((a, c) => {
    const v = list?.every(
      (item) =>
        c?.batchesAndSections?.[batchesMap[item]?.class]?.[item]?.length > 0
    );
    if (v) a?.push(c);
    return a;
  }, []);
};

export const filterClassesByBranch = (branchOrArrayOfBranches) => {
  const classesMap = useSelector(selectClasses);

  const list = makeArr(branchOrArrayOfBranches);

  if (!branchOrArrayOfBranches) return [...getObjectValues(classesMap)];
  return getObjectValues(
    list?.reduce((a, c) => {
      const { batchesAndSections = {} } = c || {};
      if (batchesAndSections)
        getObjectKeys(batchesAndSections)?.forEach((classVal) => {
          if (!a[classVal]) a[classVal] = classesMap[classVal];
        });
      return a;
    }, {})
  );
};

export const filterBatchesByBranchesAndGrades = (
  branchOrArrayOfBranches,
  classesOrArrayOfClasses,
  returnOnlyBatchIds
) => {
  const classesMap = useSelector(selectClasses);
  const batchesMap = useSelector(selectBatchesMap);

  if (!branchOrArrayOfBranches) return getObjectValues(batchesMap);

  const classesList = (() => {
    if (classesOrArrayOfClasses) return makeArr(classesOrArrayOfClasses);
    return [...getObjectValues(classesMap)].filter((item) => !!item);
  })();

  const branchesList = makeArr(branchOrArrayOfBranches);

  const batchIds = getObjectValues(
    classesList.reduce((a, c) => {
      let batchesOfClass = [];
      branchesList.forEach((branch) => {
        batchesOfClass = union(
          batchesOfClass,
          getObjectKeys(branch?.batchesAndSections?.[c.value] || {})
        );
      });
      if (batchesOfClass && batchesOfClass.length > 0)
        a[c.value] = batchesOfClass;
      return a;
    }, {})
  ).flat(1);

  if (returnOnlyBatchIds) return batchIds;

  return batchIds.map((batch) => batchesMap[batch]);
};

export const getBatchesAndSectionMapOfSingleBranch = (branch) =>
  mapValues(
    getObjectValues(branch?.batchesAndSections)?.reduce(
      (a, c) => ({ ...a, ...c }),
      {}
    ),
    (sections) =>
      sections?.map((section) => ({
        ...section,
        sectionName: `${section?.sectionName}`,
        id: `${branch?.branchCode}_${section?.sectionId}`,
        getSectionId: `${branch?._id}_${section?.sectionId}`,
      }))
  );

export const getAllBatchesAndSectionsMapOfGivenBranches = (branchesArray) =>
  branchesArray?.reduce((a, c) => {
    const s = getBatchesAndSectionMapOfSingleBranch(c);

    const allBatches =
      union(getObjectKeys(a || {}), getObjectKeys(s || {})) || [];

    return allBatches?.reduce(
      (acc, crr) => ({
        ...acc,
        [crr]: [...(a[crr] || []), ...(s?.[crr] || [])],
      }),
      {}
    );
  }, {});

export const filterSectionsByBranchClassAndBatch = (
  branchOrArrayOfBranches,
  classesOrArrayOfClasses,
  batchesOrArrayOfBatches
) => {
  const classesMap = useSelector(selectClasses);

  if (!branchOrArrayOfBranches) return [];

  const allMap = getAllBatchesAndSectionsMapOfGivenBranches(
    makeArr(branchOrArrayOfBranches)
  );

  if (batchesOrArrayOfBatches) {
    return makeArr(batchesOrArrayOfBatches)
      .map((item) => item && allMap[item?.batchId])
      .flat(1);
  }

  const getBatchesList = () => {
    const classesList = (() => {
      if (classesOrArrayOfClasses) return makeArr(classesOrArrayOfClasses);
      return [...getObjectValues(classesMap)];
    })();

    const branchesList = makeArr(branchOrArrayOfBranches);

    const batchIds = getObjectValues(
      classesList.reduce((a, c) => {
        let batchesOfClass = [];
        branchesList.forEach((branch) => {
          batchesOfClass = union(
            batchesOfClass,
            getObjectKeys(branch.batchesAndSections?.[c.value] || {})
          );
        });
        if (batchesOfClass && batchesOfClass.length > 0)
          a[c.value] = batchesOfClass;
        return a;
      }, {})
    ).flat(1);

    return batchIds;
  };

  return getBatchesList()
    .map((id) => allMap[id])
    .flat(1);
};

export const filterCounsellorsByBranch = (branchOrArrayOfBranches) => {
  const counsellors = useSelector(selectCounsellors);
  if (!branchOrArrayOfBranches)
    return counsellors.map((counsellor) => counsellor.name);
  const counsellorsMap = sortArrayOfObjectsByAProp(counsellors, "branch");
  return makeArr(branchOrArrayOfBranches).reduce((a, c) => {
    const counsellorsOfBranch = counsellorsMap[c._id];
    if (!counsellorsOfBranch) return a;

    return [...a, ...counsellorsOfBranch.map((counsellor) => counsellor.name)];
  }, []);
};

export const filterTestsByBatch = (
  batchOrArrayOfBatches,
  offlineAnalysis,
  branches
) => {
  const testDetails = useSelector(selectAllExams);
  if (!batchOrArrayOfBatches) return [];
  const batchId = batchOrArrayOfBatches?.batchId;
  let filteredTests = [];
  if (offlineAnalysis && batchId && branches && branches.length !== 0) {
    filteredTests =
      testDetails && testDetails.length > 0
        ? testDetails.filter(
            (test) =>
              test.levelIds.includes(batchId) &&
              test.branches &&
              test.branches?.some((branch) =>
                branches.map((b) => b.branchCode).includes(branch)
              )
          )
        : [];
  } else {
    const dataToDisplay =
      testDetails && testDetails.length > 0
        ? testDetails?.filter((test) =>
            test.branches?.some((branch) =>
              branches?.some(({ branchCode }) => branchCode === branch)
            )
          )
        : [];
    filteredTests = dataToDisplay
      ? dataToDisplay.filter((test) =>
          test.levelIds?.some((levelId) =>
            batchOrArrayOfBatches?.some((batch) => batch.batchId === levelId)
          )
        )
      : [];
  }
  return filteredTests;
};
export const filterSectionsByBatch = () => {
  // console.log(batchOrArrayOfBatches);
};

export const filterHostelByBranch = () => {}; // for hostel;

export const filterEmployeesByDesignation = (
  designationOrArrayOfDesignations,
  branchId
  // phase = ""
) => {
  let employeesList = useSelector(selectEmployees);
  const isSR = store.get("instituteId") === "65772522ee64cfa8dc9851f2";
  if (isSR && branchId) {
    employeesList = employeesList.filter(
      (e) =>
        e.designation === "PRO" &&
        e.assignedBranches &&
        e.assignedBranches.includes(branchId)
    );
  }
  if (!designationOrArrayOfDesignations) return employeesList;
  const employeesMap = sortArrayOfObjectsByAProp(employeesList, "designation");
  return makeArr(designationOrArrayOfDesignations).reduce((a, c) => {
    const employeesOfDesignation = employeesMap[c.value] || [];
    return [...a, ...employeesOfDesignation];
  }, []);
};
